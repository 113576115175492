import React, { useEffect, useState } from 'react'
import { Orders, TrackingOrder } from '../../api/routes';
import CreateCookDialog from '../cooks/CreateCookDialog'
import CreateDippDialog from '../dipps/CreateDippDialog';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import FormWrapper from '../components/FormWrapper/FormWrapper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIconBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReplayIcon from '@mui/icons-material/Replay';


export default function Order(props) {
  // const [order, setOrder] = useState(props.order)
  const order = {...props.order}

  const setOrder = async (_order) => await props.getData()

  const handleUpdate = async _order => {
    await Orders.Update(_order)
    await props.getData()
  }
  const handleToggle = name => handleChange(name, !{ ...order }[name])


  const handleChangeEvent = e => {
    const _order = { ...order }
    _order[e.target.name] = e.target.value
    // setOrder(_order)
    handleUpdate(_order)
  }
  const handleChange = (name, value) => {
    const _order = { ...order }
    _order[name] = value
    // setOrder(_order)
    handleUpdate(_order)
  }

  const handleTrackingChange = async e => {
    const new_trackingValue = e.target.value

    if (order.trackingFrom && order.trackingValue) await TrackingOrder.Create(order.smak, order.trackingFrom, order.trackingValue)

    const _order = { ...order }
    _order.trackingFrom = new Date()
    _order.trackingValue = new_trackingValue
    setOrder(_order)
    handleUpdate(_order)
  }


  var today = new Date()
  const todaysDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' + today.getDate() : today.getDate())

  const OrderExtraClass = () => order.showMark && props.parent.checkMode ? 'Order--checked' : 'Order--unchecked'

  return (
    <div className="Order__Wrapper">
      { !order.showList && <div className="Order__Overlay" />}
      <div className={`Order ${OrderExtraClass()}`} onClick={() => console.log()}>
        <TextField
          id="standard-basic" label="Smak" variant="standard"
          InputProps={{ readOnly: true, }}
          value={order.smak}
          style={{ width: "150px" }}
        />
        <div className="spacer-10" />
        <FormControl variant="standard">
          <InputLabel id="demo-simple-select-label">Antal</InputLabel>
          <NativeSelect
            value={order.antal}
            name="antal"
            onChange={handleChangeEvent}
            inputProps={{
              name: 'antal',
              id: 'uncontrolled-native',
            }}
          >{[...Array(21).keys()].map(i => <option key={i} value={i}>{i}</option>)}
          </NativeSelect>
        </FormControl>
        {props.parent.planningMode && <div className="spacer-10" />}
        {props.parent.planningMode && <FormControl variant="standard">
          <InputLabel id="demo-simple-select-label">Gräns</InputLabel>
          <NativeSelect
            value={order.limit}
            name="limit"
            onChange={handleChangeEvent}
            inputProps={{
              name: 'limit',
              id: 'uncontrolled-native',
            }}
          >{[...Array(21).keys()].map(i => <option key={i} value={i}>{i}</option>)}
          </NativeSelect>
        </FormControl>}

        <div className="spacer-10" />
        <OrderButton antal={order.antal} limit={order.limit} {...props} />

        <div className="spacer-10" />
        <CreateCookDialog {...props} />

        <div className="spacer-10" />
        <CreateDippDialog {...props} />

        {!props.parent.planningMode && props.parent.checkMode && <div className="spacer-10" />}
        {!props.parent.planningMode && props.parent.checkMode && <TextField
          id="date"
          label="Tillverkningsdatum"
          type="date"
          variant="standard"
          value={order.trackingValue}
          sx={{ width: 220 }}
          InputLabelProps={{ shrink: true }}
          onChange={handleTrackingChange}
          style={{ width: "145px" }}
        />}

        {props.parent.planningMode && <OrderMenu handleUpdate={handleUpdate} handleToggle={handleToggle} {...props} />}

        {!props.parent.planningMode && props.parent.checkMode && <div className="spacer-10" />}
        {!props.parent.planningMode && props.parent.checkMode && <IconButton aria-label="delete" onClick={() => handleToggle('showMark')}>
          {order.showMark ? <CheckBoxIcon /> : <CheckBoxIconBlank />}
        </IconButton>}

      </div>
    </div>
  )
}

function OrderButton({ antal, limit }) {
  const colors = {
    green: "#04a466",
    orange: "#faae33",
    red: "red"
  }
  const styles = () => {
    if (antal < 1) return colors.red
    else if (antal < limit) return colors.orange
    else if (antal >= limit) return colors.green
  }

  const texts = () => {
    if (antal < 1) return 'Larm'
    else if (antal < limit) return 'Låg Nivå'
    else if (antal >= limit) return 'Okej'
  }

  const style = {backgroundColor: styles()}

  return (
    <div>
      <FormWrapper label="Order">
        <div className="Order__StatusButton" style={style}>{texts()}</div>
      </FormWrapper>
    </div>
  )
}

function OrderMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShow = async () => {
    await props.handleToggle('showList');
    handleClose();
  };

  const handleDelete = async () => {
    handleClose();
    if (window.confirm('Är du säker att du vill radera denna produkt?')) {
      await Orders.Delete(props.order)
      await props.getData()
    };
  };



  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {

          },
        }}
      >
        <MenuItem onClick={handleShow}>
          Tänd / Släck
        </MenuItem>
        {/* <MenuItem> */}
          <UpdateOrderDialog handleClose={handleClose} {...props} />
        {/* </MenuItem> */}
        <MenuItem onClick={handleDelete}>
          Radera
        </MenuItem>
      </Menu>
    </div>
  );
}

function UpdateOrderDialog(props) {
  const [open, setOpen] = React.useState(false);

  const genOrder = () => {
    const {order} = props
    const _order = {
      id: order.id, 
      smak: order.smak, 
      category: order.category, 
      sort: order.sort, 
      type: order.type, 
      dippType: order.dippType, 
      subSmaker: order.subSmaker
    }
    return _order
  }
  const [order, setOrder] = useState(genOrder())

  useEffect(() => {
    setOrder(genOrder())
  }, [props.order])
  

  const handleSubSmaker = (item, index) => {
    const _subSmaker = [...order.subSmaker]
    _subSmaker[index] = item
    handleUpdate("subSmaker", _subSmaker)
  }
  const handleClickOpen = () => {
    props.handleClose()
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    handleClose()
    await props.handleUpdate(order)
  }

  const handleUpdate = (name, value) => {
    const _order = { ...order }
    _order[name] = value
    setOrder(_order)
  }
  const handleUpdateEvent = e => {
    const { name, value } = e.target
    const _order = { ...order }
    _order[name] = value
    setOrder(_order)
  }

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>
        Ändra
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Ändra smak
          {/* <IconButton aria-label="delete" onClick={() => setProps({ ...props })}>
            <ReplayIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <DialogContentText>
          </DialogContentText>
          <TextField
            id="standard-basic" label="Smak" variant="standard"
            onChange={handleUpdateEvent}
            name="smak"
            value={order.smak}
          />
          <div className="spacer-5" />
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Kategori</InputLabel>
            <NativeSelect
              value={order.category}
              name="category"
              onChange={handleUpdateEvent}
              inputProps={{
                name: 'category',
                id: 'uncontrolled-native',
              }}
            >{props.parent.categories.map(item => <option key={item} value={item}>{item}</option>)}
            </NativeSelect>
          </FormControl>
          <div className="spacer-5" />
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Sort</InputLabel>
            <NativeSelect
              value={order.sort}
              name="sort"
              onChange={handleUpdateEvent}
              inputProps={{
                name: 'sort',
                id: 'uncontrolled-native',
              }}
            >{['Sort', 'Tryffel', 'Nougat', 'Kola', 'Flera'].map(item => <option key={item} value={item}>{item}</option>)}
            </NativeSelect>
          </FormControl>
          <div className="spacer-5" />
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Typ</InputLabel>
            <NativeSelect
              value={order.type}
              name="type"
              onChange={handleUpdateEvent}
              inputProps={{
                name: 'type',
                id: 'uncontrolled-native',
              }}
            >{['Singel', 'Budskap', 'Bland', 'Ask'].map(item => <option key={item} value={item}>{item}</option>)}
            </NativeSelect>
          </FormControl>
        </DialogContent>
        {order.type == 'Bland' && <DialogContent className="CreateOrderDialog__form">
          {
            [...Array(5).keys()].map(i => {
              return <TextField
                id="standard-basic" label={`Undersmak ${i+1}`} variant="standard"
                onChange={e => handleSubSmaker(e.target.value, i)}
                value={order.subSmaker[i]}
                autoComplete="off"
                style={{marginRight: "5px"}}
              />
            })
          }
        </DialogContent>}
        <DialogActions className="CreateOrderDialog__buttons">
          <Button color="secondary" onClick={handleClose}>Stäng</Button>
          <Button color="primary" onClick={handleSave}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}