import React from 'react'
import './styles.css'

export default function FormWrapper({ label, children, className }) {
  return (
    <div className={`FormWrapper ${className}`}>
      <div className="FormWrapperLabel">{label}</div>
      <div className="FormWrapperContent">
        {children}
      </div>
    </div>
  )
}
