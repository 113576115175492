import React from 'react'

import { Routes } from '../../../api/routes'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import InputLabel from '@mui/material/InputLabel'
import FormWrapper from '../../../pages/components/FormWrapper/FormWrapper'


function SelectPersonDialog(props) {
    const [open, setOpen] = React.useState(false)
    const [persons, setPersons] = React.useState([])
    const [person, setPerson] = React.useState('')

    const getPersons = async () => {
        let res = await Routes.Read('persons')

        res = res.filter(f => f.active && f[props.access])

        res.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

        setPersons(res)
    }

    React.useEffect(() => {
        getPersons()
    }, [props])

    const handleOpen = async () => {
        setOpen(true)
    }

    const handleClose = async () => {
        setOpen(false)
        setPerson('')
    }

    const handleChange = (event) => {
        setPerson(event.target.value)
    }

    return (
        <>
            <Button 
                onClick={handleOpen}
                variant="outlined"
                color="primary"
                size='small'
                fullWidth
            >
                {props.buttonText || 'Välj namn'}
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{props.dialogTitle || "Signera"}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="person">Namn</InputLabel>
                        <NativeSelect
                            value={person}
                            onChange={handleChange}
                            inputProps={{
                                name: 'person',
                                id: 'person',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {
                                persons.map((item, index) => {
                                    return (
                                        <option key={index} value={item.name}>{item.name}</option>
                                    )
                                })
                            }
                        </NativeSelect>
                    </FormControl>
                </DialogContent>  
                <DialogActions className="CreateOrderDialog__buttons">
                    <div className="flex">
                        <Button onClick={handleClose}>Stäng</Button>
                    </div>
                    {
                        person != '' && (
                            <Button color="primary" onClick={() => {
                                props.handleSave(person)
                                handleClose()
                            }}>spara</Button>
                        )
                    }
                </DialogActions>  
            </Dialog> 
        </>
    )
}

function CreateTodoDialog(props) {
    const [open, setOpen] = React.useState(false)
    const [object, setObject] = React.useState(false)
    const [persons, setPersons] = React.useState([])

    const loadEmptyObject = () => {
        setObject({
            text: '',
            author: '',
            done: false,
            done_by: ''
        })
    }

    const getPersons = async () => { 
        let res = await Routes.Read('persons')
        res = res.filter(f => f.active && f[props.access])
        res.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        setPersons(res)
    }

    React.useEffect(() => {
        loadEmptyObject()
        getPersons()
    }, [props])
   
    const handleOpen = async () => {
        setOpen(true)
    }

    const handleClose = async () => {
        setOpen(false)
        loadEmptyObject()
    }

    const handleChange = (event) => {
        setObject({
            ...object,
            [event.target.name]: event.target.value
        })
    }

    const handleSave = async () => {
        await Routes.Create(object, 'freezer_todo')
        handleClose()
        props.handleSave()
    }

    return (
        <>
            <Button 
                onClick={handleOpen}
                variant="outlined"
                color="primary"
                size='small'
            >
                {props.buttonText}
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{props.dialogTitle || "Skapa"}</DialogTitle>
                <DialogContent>
                    <FormWrapper>
                        <TextField
                            label="Text"
                            name="text"
                            value={object.text}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </FormWrapper>
                    <br />
                    <br />
                    <FormControl fullWidth>
                        <InputLabel htmlFor="person">Namn</InputLabel>
                        <NativeSelect
                            value={object.author}
                            onChange={handleChange}
                            inputProps={{
                                name: 'author',
                                id: 'author',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {
                                persons.map((item, index) => {
                                    return (
                                        <option key={index} value={item.name}>{item.name}</option>
                                    )
                                })
                            }
                        </NativeSelect>
                    </FormControl>
                    <br />
                </DialogContent>  
                <DialogActions className="CreateOrderDialog__buttons">
                    <div className="flex">
                        <Button onClick={handleClose}>Stäng</Button>
                    </div>
                    {
                        object && object.text != '' && object.author != '' && (
                            <Button color="primary" onClick={handleSave}>spara</Button>
                        )
                    }
                </DialogActions>  
            </Dialog> 
        </>
    )
}

function ViewDoneDialog(props) {
    const [open, setOpen] = React.useState(false)
    const [data, setData] = React.useState(false)

    React.useEffect(() => {
        setData(props.data.filter(d => d.done).reverse().slice(0, 10))
    }, [props])
   
    const handleOpen = async () => {
        setOpen(true)
    }

    const handleClose = async () => {
        setOpen(false)
        props.handleClose()
        setData(false)
    }

    const handleUndo = async (e, item) => {
        if (e.detail < 2) return
        if(window.confirm('Är du säker att du vill återställa denna?')) {
            const _item = { ...item }
            _item.done = false
            _item.done_by = ''
            await Routes.Update(_item, 'freezer_todo')
            handleClose()
        }
    }

    return (
        <>
            <Button 
                onClick={handleOpen}
                variant="outlined"
                color="primary"
                size='small'
            >
                {props.buttonText}
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{props.dialogTitle || "Klara"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Dubbelklicka på en rad för att återställa den.
                        <div className='spacer-10' />
                        
                    </DialogContentText>
                    <div className="freezer_todo_view flex-col-center g-10">
                    {
                        data && data.length > 0 && data.map((item, index) => {
                            return (
                                <div 
                                    key={index} 
                                    className='item' 
                                >
                                    <div className='content' onClick={(e) => handleUndo(e, item)}>
                                        <TextField
                                            label=''
                                            value={item.text}
                                            multiline
                                            fullWidth
                                            disabled
                                        />
                                        <div className='spacer-5' />
                                        <p className="author">
                                            Skapad av: {item.author} - Färdigställd av: {item.done_by}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </DialogContent>  
                <DialogActions className="CreateOrderDialog__buttons">
                    <div className="flex">
                        <Button onClick={handleClose}>Stäng</Button>
                    </div>
                </DialogActions>  
            </Dialog> 
        </>
    )
}

export default function Todo(props) {
    const [data, setData] = React.useState()
    const [activeItem, setActiveIndex] = React.useState()

    const getData = async () => {
        let res = await Routes.Read('freezer_todo')
        setData(res)
    }

    React.useEffect(() => {
        getData()
    }, [props])


    const handleDelete = async (item) => {
        if (window.confirm('Är du säker att du vill radera denna?')) {
            await Routes.Delete(item, 'freezer_todo')
            await getData()
        };
    }

    const handleSave = async (person, item) => {
        const _item = { ...item }
        _item.done = true
        _item.done_by = person
        await Routes.Update(_item, 'freezer_todo')
        await getData()
    }

    return (
        <div className='flex-col-center g-10 freezer_todo'>
            <div className="flex-center g-10 w-100">
                <h3>Att göra</h3>
                
                <CreateTodoDialog handleSave={getData} buttonText="skapa +" access="frysrum" />
                
                {
                    data && data.length > 0 && data.filter(d => d.done).length > 0 && (
                        <ViewDoneDialog buttonText="visa klara" data={data} handleClose={getData} />
                    )
                }
            </div>
            {
                data && data.length > 0 && data.filter(d => !d.done).map((item, index) => {
                    return (
                        <div 
                            key={index} 
                            className='item' 
                        >
                            <div className='content' onClick={() => setActiveIndex(index)}>
                                <TextField
                                    label=''
                                    value={item.text}
                                    multiline
                                    fullWidth
                                    disabled
                                />
                                <div className='spacer-5' />
                                <p className="author">
                                    @ {item.author}
                                </p>
                            </div>
                             
                            <div className={`action ${activeItem === index ? 'active' : ''}`}>
                                <SelectPersonDialog 
                                    buttonText='klar'
                                    access="frysrum" 
                                    handleSave={person => handleSave(person, item)}
                                />

                                <div className="flex-center g-10">
                                    <Button 
                                        onClick={() => handleDelete(item)}
                                        variant="outlined"
                                        color="secondary"
                                        size='small'
                                        fullWidth
                                    >
                                        radera
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
