import React from 'react'

import { Routes, getDate } from '../../../api/routes'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import InputLabel from '@mui/material/InputLabel'
import { Checkbox } from '@material-ui/core'
import FormWrapper from '../../../pages/components/FormWrapper/FormWrapper'

export default function Reduce(props) {
    const [open, setOpen] = React.useState(false)
    const [object, setObject] = React.useState()

    const generateEmptyObject = () => {
        const item = {
            signature: '',
            date: getDate(),
            reduce_amount: 0,
            new_amount: 0,
            l: false
        }
        return item
    }

    React.useEffect(() => {
        const emptyObject = generateEmptyObject()
        setObject(emptyObject)
    }, [props.item])

    const handleOpen = async () => {
        const emptyObject = generateEmptyObject()
        setObject(emptyObject)
        setOpen(true)
    }

    const handleClose = async () => {
        setOpen(false)
    }

    const handleUpdate = async () => {
        const _object = { ...object }

        const _item = { ...props.item }
        _item.current_amount = _item.current_amount - _object.reduce_amount
        _item.history.push(_object)
        _item.done = _item.current_amount < 1

        _object.new_amount = _item.current_amount

        handleClose()
        await Routes.Update(_item, 'freezer')
        await props.getData()
    }

    const handleUpdateEvent = e => {
        const _object = { ...object }
        const { name, value } = e.target
        _object[name] = value
        setObject(_object)
    }

    return (
        <div>
            <Button color="primary" variant="contained" onClick={handleOpen}>Tina Objekt</Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Tina objekt</DialogTitle>
                {
                    object && 
                    <DialogContent style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <DialogContentText>
                            För att minska antalet i frysen fyller du i hur många enheter du vill tina samt datum och namn.
                        </DialogContentText>

                        <div className="spacer-10" />
                        
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '30px',
                        }}>
                            <TextField
                                name="date"
                                label="Tinad"
                                type="date"
                                variant="standard"
                                value={object.date}
                                sx={{ width: 220 }}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleUpdateEvent}
                            />

                            <TextField
                                name="reduce_amount"
                                id="standard-basic" label="Antal" variant="standard" type="number" min="0"
                                onChange={handleUpdateEvent}
                                value={object.reduce_amount}
                                style={{
                                    maxWidth: "80px"
                                }}
                            />

                            <FormControl variant="standard">
                                <InputLabel id="demo-simple-select-label">Tinad Av</InputLabel>
                                <NativeSelect
                                    value={object.signature}
                                    name="signature"
                                    onChange={handleUpdateEvent}
                                    inputProps={{ name: 'signature', id: 'uncontrolled-native', }}
                                >
                                    <option value="Namn">Namn</option>
                                    {[...props.persons].map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
                                </NativeSelect>
                            </FormControl>

                                    
                            <FormWrapper label="Datum fel">
                            <Checkbox   
                                        name="date_invalid"
                                        checked={object.date_invalid}
                                        value={object.date_invalid}
                                        onChange={() => setObject({ ...object, date_invalid: !object.date_invalid })}
                                    />
                            </FormWrapper>
                        </div>
                    </DialogContent>
                }

                <DialogActions className="CreateOrderDialog__buttons">
                    <Button onClick={handleClose}>Stäng</Button>
                    <Button color="primary" onClick={handleUpdate}>Spara</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
