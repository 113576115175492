import React, { useEffect, useState } from 'react'
import { FixedSizeList as List } from 'react-window';
import { Recepts } from '../../api/routes';
import WithUseAuth from '../auth/WithUseAuth';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormWrapper from '../components/FormWrapper/FormWrapper';

function ReceptChooseDialog(props) {
  const { isAdmin } = props.useAuth;
  const [open, setOpen] = useState(false);

  const [recepts, setRecepts] = useState(false);
  const [searchResults, setSearchResults] = useState(recepts)
  const [choosenID, setChoosenID] = useState(false)
  const [choosenName, setChoosenName] = useState("")

  const getData = async () => {
    const _recepts = await Recepts.Read()
    _recepts.sort((a, b) => (a.namn > b.namn) ? 1 : ((b.namn > a.namn) ? -1 : 0))
    setRecepts(_recepts);
    setSearchResults(_recepts)
  }

  useEffect(() => {
    getData()
  }, [])

  const handleClickOpen = async () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
  };
  const handleChoose = _item => {
    setChoosenID(_item.id)
    setChoosenName(_item.namn)
  }
  const handleSave = async () => {
    // await props.handleUpdate("recept_id", choosenID)
    // await props.handleUpdate("recept_name", choosenName)
    props.handleUpdateArray([
      { name: "recept_id", value: choosenID },
      { name: "recept_name", value: choosenName }
    ])
    handleClose()
  }


  const Row = ({ index, style }) => {
    const item = searchResults[index]
    return (
      <div style={style} className={`ReceptList__list__item ${item.id == choosenID ? "ReceptChooseDialog__choosen--active" : ""}`}
        onClick={() => handleChoose(item)}>
        <div className='flex'>
          <FormWrapper label="Namn" >
            {item.namn}
          </FormWrapper>
          <div className="spacer-10" />
          <FormWrapper label="Skapare">
            {item.author}
          </FormWrapper>
          <div className="spacer-10" />
          <FormWrapper label="Senast Ändrad">
            {item.reviderad}
          </FormWrapper>
          <div className="spacer-10" />
        </div>
      </div >
    )
  }

  const Example = () => (
    <List
      height={300}
      itemCount={searchResults.length}
      itemSize={80}
      width={700}
    >
      {Row}
    </List>
  );


  return (
    <div>
      {/* <Button onClick={handleClickOpen} variant="contained" color="primary">välj recept</Button> */}
      <div onClick={handleClickOpen}>
        <TextField
          id="standard-basic" label="Välj Recept" variant="standard"
          InputProps={{ readOnly: true, }}
          value={props.cook.recept_name}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Välj recept</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <div>
            <div className="flex-center ">
              {recepts && <SearchBox arr={recepts} setArray={setSearchResults} />}
            </div>
            <div className="ReceptList__list">
              <Example />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
          </div>
          <Button color="primary" onClick={handleSave}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default WithUseAuth(ReceptChooseDialog);

function SearchBox({ arr, setArray }) {
  const [searchTerm, setSearchTerm] = useState("")

  const handleChange = e => {
    setSearchTerm(e.target.value)
    const results = arr.filter(item => item.namn.toLowerCase().includes(e.target.value.toLowerCase()))
    setArray(results)
  }

  return (
    <div>
      <TextField
        id="standard-basic" label="Sök Recept" variant="outlined" type="search"
        value={searchTerm}
        onChange={handleChange}
        style={{ margin: "10px 0" }}
        autoComplete="off"
      />
    </div>
  )
}