import styled from 'styled-components'
import Button from '@material-ui/core/Button'

// Cook ----------------------------------------------------------------------------
export const CookWrapper = styled.div.attrs({
    className: 'form-group',
  })`
    margin: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #F7F6F2;
    display: flex;
    gap: 10px;
    align-items: center;
  `
  
// Dipp -----------------------------------------------------------------------------
export const DippWrapper = styled.div.attrs({
    className: 'form-group',
  })`
    max-width: fit-content;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
  `

export const DippInputLabel = styled.label`
    margin-bottom: 0px;
    text-align:center;
`

// Create Order ---------------------------------------------------------------------
export const CreateOrderWrapper = styled.div.attrs({
    className: 'form-group',
  })`
    margin: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #F7F6F2;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  `

// Order ----------------------------------------------------------------------------
export const OrderWrapper = styled.div.attrs({
  className: 'form-group',
})`
  margin: 0px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #F7F6F2;
  display: flex;
  align-items: center;
  border: 1px solid #eee;
`
export const InputLabel = styled.label`
    margin-right: 0px;
    margin-bottom: 0px;
`
export const InputText = styled.input.attrs({
    className: 'form-control',
})`
    width: auto;
    max-width: 300px;
`
export const StatusButton = styled.div`
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 7px;
    width: 100px;
    color: white;
    `
export const StatusTitle = styled.div`
    margin: 0;
    margin-right: 10px;
    `

export const WrapperShow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    `

export const Overlay = styled.div`
    position: absolute;
    background-color: rgba(144, 144, 144, 0.2);
    top:0;
    left:0;
    width:100%;
    height:100%;
    border-radius: 8px;
    `

// Ordersedel NAV ----------------------------------------------------------------------------
export const NavWrapper = styled.div`
    margin-left: 10px;
`
export const Nav = styled.div`
    position: sticky;
    top: 15px;
`
export const NavSubItem = styled.div`
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    
    color: maroon;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    
    display: flex;
    cursor: pointer;
    width: 160px;
    padding: 10px;
    margin: 5px 0;
    border-bottom: 1px white solid;
    border-left: 3px white solid;
    transition: border 0.2s ease;

    &:hover { border-bottom: 1px #DDDD solid; }
`
export const NavSubDivider = styled.div`
    width: 150px;
    margin: 10px 0;
`

export const topNav = level => {
  const Url = "http://209.250.250.166:3000"
  return (
<><Button color="primary" style={{ width: "140px" }} onClick={() => { window.location.href = Url }}>
Order
</Button>
<Button color="primary" style={{ width: "140px" }} onClick={() => { window.location.href = `${Url}/cooks` }}>
  Kokning
</Button>
<Button color="primary" style={{ width: "140px" }} onClick={() => { window.location.href = `${Url}/dipps` }}>
  Tillverkning
</Button>
<Button color="primary" style={{ width: "140px" }} onClick={() => { window.location.href = `${Url}/pralinrum` }}>
  Pralinrum
</Button>

{level === "admin" ? <Button color="primary" style={{ width: "140px" }} onClick={() => { window.location.href = `${Url}/personal` }}>
  Personal
</Button> : null}
</>);
};