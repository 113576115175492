import React, { useState, useEffect } from 'react'
import WithUseAuth from '../auth/WithUseAuth';
import { Routes, Tracking_Pack } from '../../api/routes';
import './styles.css'
import { FixedSizeList as List } from 'react-window';
import TrackingPackBland from './TrackingPackBland'
import Button from '@material-ui/core/Button';

import FormWrapper from '../components/FormWrapper/FormWrapper';

function TrackingPack(props) {
    const { isAdmin } = props.useAuth;  

    const [items, setItems] = useState([])
    const [selected, setSelected] = useState([])

    const handle = {
        getData: async () => {
            let res = await Routes.Read("tracking_pack")
            res.forEach(item => item.data.id = item.id)
            setItems(res)
            let newitems = [...res].filter(item => item.type === 'bland').reverse()
            setSelected(newitems)
        }
    }

    const handleSelect = (type = 'bland') => {
        let newitems = [...items].filter(item => item.type === type)
        setSelected(newitems)
    }

    useEffect(() => {
        handle.getData()
      }, [])
      
    const Row = ({ index, style }) => {
        let item = selected[index]
        return <div style={style}>
            <div className="TrackingFilter__row" style={{cursor:"auto"}}>
                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Typ" >
                        {item.type}
                    </FormWrapper>
                </div>

                <div className="TrackingFilter__row__item" style={{width:"130px"}}>
                    <FormWrapper label="Smak">
                        {item.data.smak}
                    </FormWrapper>
                </div>
                <div className="spacer-10" />
                
                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Tillverkad">
                        {item.data.tillverkningsDatum}
                    </FormWrapper>
                </div>
                <div className="spacer-10" />
                
                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Datum">
                        {item.data.dagensDatum}
                    </FormWrapper>
                </div>
                <div className="spacer-10" />

                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Signatur">
                        {item.data.signatur}
                    </FormWrapper>
                </div>
                
                
                <TrackingPackBland getData={handle.getData} item={item} />
            </div>

        </div>
    }
    
    const Example = () => (
        <List
            height={800}
            itemCount={items.length}
            itemSize={70}
            width={800}
        >
            {Row}
        </List>
    );


    



    return (
        <div className="flex">

            <div>
                <div className="flex">
                    <div className="spacer-5"></div>


                    <div className="Planner__h1">Packningsdokument</div>
                    
                    <div className="spacer-20"></div><TrackingPackBland getData={handle.getData} />
                </div>
                <div className="TrackingFilter__header flex">
                    <Button variant="outlined" onClick={() => handleSelect("empty")}>Blanka</Button>
                    <div className='spacer-10' />
                    <Button variant="outlined" onClick={() => handleSelect("bland")}>Blandstrutar</Button>
                </div>

                {
                    selected.length > 0 ? <Example /> : <div className="">Inga dokument</div>
                }
            </div>

        </div>
    )
}

export default WithUseAuth(TrackingPack);