import React, { useEffect, useState } from 'react'
import { Frys } from '../../api/routes';
import FrysTinaDialog from './FrysTinaDialog'
import FrysTinaHistoryDialog from './FrysTinaHistoryDialog'
import FrysHistory from './FrysHistory'

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormWrapper from '../components/FormWrapper/FormWrapper';
import DialogContentText from '@material-ui/core/DialogContentText';


export default function FrysDialog(props) {
  const [open, setOpen] = useState(false);
  const [sign, setSign] = useState(false)
  const [tempAmmount, setTempAmmount] = useState(0)

  const genFrys = () => {
    return props.item
  }
  const [fry, setFrys] = useState(genFrys())

  useEffect(() => {
    setFrys(genFrys())
    let _props = { ...props }
    setTempAmmount(_props.item.ammount)
  }, [props.item])

  const handleClickOpen = async () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
    setSign(false)
    await props.getData()
  };

  const handleDelete = async () => {
    if (window.confirm('Är du säker att du vill radera denna?')) {
      handleClose()
      await Frys.Delete(fry)
      await props.getData()
    };
  }

  return (
    <div className="ReceptList__list__item">
      <div className="FrysDialog__item" onClick={handleClickOpen}>
        <TextField
          id="standard-basic" label="Namn" variant="standard"
          value={fry.name}
          name="name"
          InputProps={{ readOnly: true, }}
          autoComplete="off"
          style={{
            width: "250px"
          }}
        />
        <div className="spacer-10" />

        <div className="FrysDialog__item__FormWrapper">
          <FormWrapper label="Antal">
            {fry.ammount}
          </FormWrapper>
        </div>
        <div className="spacer-15" />
        <div className="FrysDialog__item__FormWrapper">
          <FormWrapper label="Enhet">
            {fry.unit}
          </FormWrapper>
        </div>

        <div className="spacer-15" />
        <FormWrapper label="Tillverkad">
          {fry.doppad}
        </FormWrapper>
        <div className="spacer-10" />
        <FormWrapper label="Fryst">
          {fry.fryst}
        </FormWrapper>
        <div className="spacer-10" />
      </div>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <div className="flex">
            Redigera objekt
            <div className="spacer-10" />
            {!props.showDone && <FrysTinaDialog handleClose={handleClose} {...props} />}
            <div className="spacer-10" />
            <FrysHistory handleClose={handleClose} fry={fry} {...props} />
            <div className="spacer-10" />
            <FrysTinaHistoryDialog handleClose={handleClose} fry={fry} {...props} />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            För att minska antalet i frysen klickar du på TINA OBJEKT.
          </DialogContentText>
        </DialogContent>
        <DialogContent className="FrysDialog__DialogContent">

          <TextField
            id="standard-basic" label="Namn" variant="standard"
            readOnly
            value={fry.name}
            name="name"
            style={{
              width: "250px"
            }}
          />
          <div className="spacer-5" />
          <TextField
            id="standard-basic" label="Antal" variant="standard" type="number" min="0"
            readOnly
            value={fry.ammount}
            name="ammount"
            style={{
              maxWidth: "80px"
            }}
          />
          <div className="spacer-5" />
          <TextField
            id="standard-basic" label="Enhet" variant="standard"
            readOnly
            value={fry.unit}
            name="unit"
            style={{
              maxWidth: "80px"
            }}
          />
          <div className="spacer-5" />
          <TextField
            type="text"
            id="standard-basic" label="Tillverkad" variant="standard"
            readOnly
            value={fry.doppad}
            name="doppad"
            sx={{ width: 220 }}
            InputLabelProps={{ shrink: true }}
          />
          <div className="spacer-5" />
          <TextField
            type="text"
            id="standard-basic" label="Fryst" variant="standard"
            readOnly
            value={fry.fryst}
            name="fryst"
            sx={{ width: 220 }}
            InputLabelProps={{ shrink: true }}
          />

          <div className="spacer-5" />
          <TextField
            type="text"
            id="standard-basic" label="Fryst Av" variant="standard"
            readOnly
            value={fry.frystSign}
            name="frystSign"
          />


        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
            {
              props.isAdmin && 
              <Button color="secondary" onClick={handleDelete}>Radera</Button>
            }
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
