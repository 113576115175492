import React, { useState, useEffect } from 'react'
import { Cooks, currentWeekPrev, weekNumbers } from '../../api/routes';
import './styles.css'
import FormWrapper from '../components/FormWrapper/FormWrapper';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect'
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';


export default function CreateCookDialog(props) {
  const [open, setOpen] = useState(false);
  const [newCook, setNewCook] = useState(true)

  const weeks = weekNumbers()

  const genCook = () => {
    // const { order } = props
    const _cook = {
      smak: "", subSmak: "", sort: "", batch: 0,
      dag: "Okej", kommentar: "", orderID: 0, done: false, toFrys: false,
      type: "", prio: 1, subSmaker: [""], week: currentWeekPrev(), year: new Date().getFullYear(),
    }
    return _cook
  }

  const [cook, setCook] = useState(genCook())

  // useEffect(() => {
  //   checkIfExist()
  // }, [])

  const handleClickOpen = async () => {
    // if (props.isAdmin()) {
    // checkIfExist()
    setOpen(true);
    // }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdate = (name, value) => {
    const _cook = { ...cook }
    _cook[name] = value
    setCook(_cook)
  }
  const handleUpdateEvent = e => {
    const { name, value } = e.target
    const _cook = { ...cook }
    _cook[name] = value
    setCook(_cook)
  }

  const handleDelete = async () => {
    if (window.confirm('Är du säker att du vill radera denna order?')) {
      setNewCook(true)
      handleClose()
      handleUpdate('dag', 'Okej')
      await Cooks.Delete(cook)
      await props.getData()
    };
  }

  const handleCreate = async () => {
    handleClose()
    if (newCook) await Cooks.Create(cook)
    else await Cooks.Update(cook)
    await props.getData()
  }


  const buttonStyle = cook.dag == 'Okej' ? '#04a466' : '#2980b9'

  return (
    <div>
      <div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>Skapa Kokning +</Button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Planera en fri text kokning</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <div className="flex">
            <TextField
              id="standard-basic" label="Titel" variant="standard"
              onChange={handleUpdateEvent}
              value={cook.smak}
              name="smak"
            />

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Typ</InputLabel>
              <NativeSelect
                value={cook.sort}
                name="sort"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'sort', id: 'uncontrolled-native', }}
              >{['Sort', 'Tryffel', 'Nougat', 'Kola', 'Flera'].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Batch</InputLabel>
              <NativeSelect
                value={cook.batch}
                name="batch"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'batch', id: 'uncontrolled-native', }}
              >{[...Array(7).keys()].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Planerad Dag</InputLabel>
              <NativeSelect
                value={cook.dag}
                name="dag"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'dag', id: 'uncontrolled-native', }}
              >{['Välj dag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />

            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Vecka</InputLabel>
              <NativeSelect
                value={cook.week}
                name="week"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'week', id: 'uncontrolled-native', }}
              >{weeks.map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />

            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">År</InputLabel>
              <NativeSelect
                value={cook.year}
                name="year"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'year', id: 'uncontrolled-native', }}
              >{['2023', '2024'].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>
          </div>

          <div className="spacer-5" />

          <div className="flex">
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Prio</InputLabel>
              <NativeSelect
                value={cook.prio}
                name="prio"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'prio', id: 'uncontrolled-native', }}
              >{[[1, 'Låg'], [2, 'Medel'], [3, 'Hög']].map(item => <option key={item[0]} value={item[0]}>{item[1]}</option>)}
              </NativeSelect>
            </FormControl>
            <div className="spacer-5" />

            {/* <FormWrapper label="Till Frysen">
              <Switch
                checked={cook.toFrys}
                name="toFrys"
                onChange={e => handleUpdate(e.target.name, e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                color="primary"
              />
            </FormWrapper> */}

            <div className="spacer-5" />
            <TextField
              id="standard-basic" label="Kommentar" variant="standard"
              onChange={handleUpdateEvent}
              value={cook.kommentar}
              name="kommentar"
              onChange={handleUpdateEvent}
              multiline
              maxrows={4}
            />
          </div>
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
            {!newCook && <Button color="secondary" onClick={handleDelete}>Radera</Button>}
          </div>
          <Button color="primary" onClick={handleCreate}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
