import React from "react";
import SearchableListDialogMUI from "./components/SearchableListDialogMUI";

export default function Labs() {
    let generateItems = (len) => Array.from({ length: len }).map((_, index) => `Item ${index}`);

    const [items, setItems] = React.useState(generateItems(100));

    const item = (index, _items) => <div>{_items[index]}</div>;

    return (
        <div>
            <SearchableListDialogMUI
                items={items}
                callback={(value) => { console.log("You choose: " + value) }}
                item={item}
            />
        </div>
    )
  
}
