import React from 'react';

import WithUseAuth from '../../../pages/auth/WithUseAuth';
import { Routes } from '../../../api/routes'
import { Button, TextArea } from '../../ui/aui';

function Note(props) {
    const { isAdmin } = props.useAuth;
    const [data, setData] = React.useState()

    const getData = async () => {
        const res = await Routes.Read('whiteboard')
        
        // res = [
        //     {
        //         "id": 1,
        //         "created_at": "2021-12-27T13:31:40+00:00",
        //         "text": "text",
        //         "date": "2021-12-27"
        //     }
        // ]

        setData(res[0])
    }

    React.useEffect(() => {
        getData()
    }, [])


    // run get data every 5 minutes
    React.useEffect(() => {
        const interval = setInterval(() => {
            getData()
        }, 1000 * 60 * 5);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const handleChange = e => {
        const { name, value } = e.target
        const _data = { ...data }
        _data[name] = value
        setData(_data)
    }

    const handleSave = async () => {
        await Routes.Update(data, 'whiteboard')
        alert('Sparat')
        await getData()
    }


    return (
        <div className="aui">
            <div className='paper'>
                <h2 className='action'>
                    Meddelande
                    {
                        isAdmin() &&
                        <Button color="primary" variant="text" onClick={handleSave}>Spara</Button>
                    }
                </h2>

                {
                    data && 
                    <TextArea
                        name="text"
                        value={data.text}
                        onChange={handleChange}
                        readonly={!isAdmin()}
                        rows={8}    
                        cols={50}
                        style={{ 
                            fontSize: '1.1rem',
                        }}
                    />
                }
            </div>
         </div>
    )
}

export default WithUseAuth(Note);