import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router'
import { useAuth } from '../../auth/AuthContext'
import './styles.css'
import { Button, IconButton } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';


export default function Nav() {
  const { user, isAdmin, signOut } = useAuth()
  const [menu, setMenu] = useState(false)

  const username = user?.email.substring(0, user?.email.lastIndexOf("@"))
  const history = useHistory()

  async function handleSignOut() {
    await signOut()

    setMenu(false)
    history.push('/login')
  }

  return (
    user && <nav>&nbsp;
      <div>
      <div style={{position:"fixed", top:"0", left:"0", zIndex:"300"}} className="Nav__wrapper">
      {menu && <div className="Nav_Controller" onClick={() => setMenu(!menu)}>
        
      </div>}

      <div className="Nav_Overlay" style={menu ? { transform: 'translateX(0px)' } : { transform: 'translateX(-200px)' }}>
        <IconButton onClick={() => setMenu(false)} >
          <CloseIcon fontSize="medium" color="default" />
        </IconButton>
        <br />
        <div className="Nav_Wrapper">
          <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/dashboard"><Button>Dashboard</Button></NavLink>
          <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/"><Button>Ordersedel</Button></NavLink>
          {/* <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/pralinrum"><Button>Pralinrum</Button></NavLink> */}
          <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/planner"><Button>Att göra</Button></NavLink>
          <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/frys"><Button>Fryssida</Button></NavLink>
          {isAdmin() && <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/personal"><Button>Personal</Button></NavLink>} 
          {isAdmin() && <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/recept"><Button>Recept</Button></NavLink>}    
          {isAdmin() && <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/tracking"><Button>Spårbarhet</Button></NavLink>}      
          {isAdmin() && <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/fortknox"><Button>Ordersammandrag</Button></NavLink>}       
          <NavLink className="Nav_Link" onClick={() => setMenu(false)} to="/butikslager"><Button>Butik</Button></NavLink>   
        <br />
        <br />
        <h5>{username}</h5>
        <Button variant="outlined" color="secondary" onClick={handleSignOut}>LOGGA UT</Button>
        </div>
      </div>
      
      <IconButton onClick={() => setMenu(!menu)} style={{background:"white"}}>
        <ListIcon fontSize="large" color="default" />
      </IconButton>
    </div>
    </div>
    </nav>
  )
}
