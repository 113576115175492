import React from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

/*
    props:
        title: string
        buttonText: string
        callback: (event) function to call when button is clicked
        children: any

    Example:
        <DialogMUI
            title="Titel"
            buttonText="välj"
            callback={() => { console.log() }}
        >
            <div>hje</div>
        </DialogMUI>

*/

export default function DialogMUI(props) {
    const [open, setOpen] = React.useState(false)
    const [_props, setProps] = React.useState(null)

    React.useEffect(() => {
        let defaultProps = {
            title:"Titel",
            buttonText: "välj",
            buttonSettings: {
                variant: "outlined",
                color: "primary",
            },
            showDelete: false,
            showCreate: false,
            showSave: false,
            showOverlay: false,
        }
        setProps({...defaultProps, ...props})
    }, [props])

    React.useEffect(() => {
        if (!open) return
        setOpen(props.open)
        props.callback()
    }, [props.open])


    const handleDialog = {
        open: () => {
            setOpen(true)
            _props.onOpen()
        },
        close: () => {
            setOpen(false)
        },
        update: () => {
            setOpen(false)
            _props.callback()
        }
    }

    return (
        <div>
            {_props && <Button onClick={handleDialog.open} {..._props.buttonSettings}>{_props.buttonText}</Button>}  
            
            {_props &&
            <Dialog open={open} onClose={handleDialog.close}>
                <DialogTitle>{_props.title}</DialogTitle>

                <DialogContent>
                    <div>
                        {_props.children}
                    </div>
                    {_props.showOverlay && <div className="overlay" />}
                </DialogContent>
                <DialogActions className="flex-space-between" style={{zIndex:"200"}}>
                    <div className="flex">
                        <Button onClick={handleDialog.close}>Stäng</Button>
                        <div className='spacer-10' />
                        {_props.showDelete && <Button color="secondary" onClick={handleDialog.delete}>Radera</Button>}
                    </div>
                    {_props.showSave && <Button color="primary" onClick={handleDialog.update}>Spara</Button>}
                    {_props.showCreate && <Button color="primary" onClick={handleDialog.create}>Skapa</Button>}
                </DialogActions>
            </Dialog>
            }
        </div>
    )
}
  

