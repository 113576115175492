import React, { Component, useState, useEffect } from 'react';
import WithUseAuth from '../auth/WithUseAuth';
import './styles.css'
import { Orders, OrdersChecklist, Persons } from '../../api/routes';
import Order from './Order'
import OrdersInactive from './OrdersInactive'
import OrdersAll from './OrdersAll'
import CreateCookDialogFree from '../cooks/CreateCookDialogFree'
import CreateDippDialogFree from '../dipps/CreateDippDialogFree';

import Button from '@material-ui/core/Button';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

const getTimestamp = () => {
	const date = new Date()
    const getZero = item => String(item).length < 2 ? `0${item}` : item
    const year = date.getFullYear() + '-' + getZero(date.getMonth()+1) + '-' + getZero(date.getDate())
    const time = getZero(date.getHours()) + ':' + getZero(date.getMinutes()) + ':' + getZero(date.getSeconds()) + '.000Z'
    
    const full = year + 'T' + time
    return full
}


class OrderSedel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: [],
      persons: [],
      categories: ["DOPPAD I VIT CHOKLAD", "DOPPAD I LJUS CHOKLAD", "DOPPAD I MÖRK 70% CHOKLAD", "BUDSKAPSSTRUTAR", "VEGANSTRUTAR", "SÄSONGSSTRUTAR", "LITEN KOLAASK", "ASKAR"],
      activeTitle: "DOPPAD I VIT CHOKLAD",
      showListTitle: "SLÄCKTA",
      OrdersChecklistWrite: '',

      planningMode: false,
      checkMode: false,

      topRef: React.createRef(),
      renderButtons: false,
    }
  }

  stateProps = () => {
    const { _orders, ..._state } = this.state
    return _state
  }

  componentDidMount = async () => {
    this.getData()
  }

  // inputOrders = async () => {
  //   let _orders = arr.map(async (a, i) => {
  //     const x = {category:a[0],smak:a[1], antal: parseInt(a[2]), limit: parseInt(a[3]), showList: a[4] == 'true' ? true : false}
  //     // await Orders.Create(x)
  //     return x
  //   })
  //   // await Orders.CreateMany(_orders)
  // }

  getData = async () => {
    await this.getOrders()
    await this.getOrdersChecklist()
    await this.getPersons()
    
    await this.setState({renderButtons: !this.state.renderButtons})
  }

  getOrders = async () => {
    const orders = await Orders.Read()
    orders.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    await this.setState({ orders })
  }
  createOrder = async order => {
    await Orders.Create(order)
    await this.getData()
  }

  getOrdersChecklist = async () => {
    let _ordersChecklist = await OrdersChecklist.ReadLast()
    const ordersChecklist = _ordersChecklist.name + ', ' + _ordersChecklist.checkTime.split('T')[1].split(':')[0] + ':' + _ordersChecklist.checkTime.split('T')[1].split(':')[1] + ' ' + _ordersChecklist.checkTime.split('T')[0]
    await this.setState({ ordersChecklist })
  }

  getPersons = async () => {
    const persons = await Persons.Read()
    const filtered = persons.filter(p => p.checklista)
    console.log(filtered)
    await this.setState({ persons: filtered })
  }

  scrollToRef = () => {
    // window.scrollTo(0, this.state.topRef.current.offsetTop)
  }

  setActiveTitle = async title => {
    await this.setState({ activeTitle: title })
    await this.getData()
    this.scrollToRef()
  }

  handleChangeEvent = async e => await this.setState({ [e.target.name]: e.target.value })
  handleChangeEventCheckbox = async e => await this.setState({ [e.target.name]: e.target.checked })

  handleChangeMenu = async name => {
    const today = getTimestamp()
    await OrdersChecklist.Create({ name: name, checkTime: today })
    this.getOrdersChecklist()
  }

  handleDeselectAll = async () => {
    if (window.confirm('Är du säker att du vill avmarkera alla?')) {
      const arr = this.state.orders.filter(item => !item.showMark)

      if(arr.length > 0) {
        if (window.confirm('Du har inte markerat alla! Vill du avmarkera alla ändå??')) {
          await Orders.Deselect()
          await this.getData()
        }
        return
      }
      await Orders.Deselect()
      await this.getData()
    }
  }

  handlePlanningCheckMode = async e => {
    const { name, checked } = e.target
    const checkName = _name => name === _name ? checked : false
    const planningMode = checkName('planningMode')
    const checkMode = checkName('checkMode')

    await this.setState({ planningMode, checkMode })
  }

  handleActiveTitle = async (category) => {
    await this.setActiveTitle(category)
    await this.setState({renderButtons: !this.state.renderButtons})
  }


  render() {
    const { isAdmin } = this.props.useAuth;
    const { orders, topRef, activeTitle, showListTitle, categories, renderButtons } = this.state
    const childProps = this.stateProps()

    const showOrdersList = () => {
      let x
      if (activeTitle === showListTitle) x = <OrdersInactive orders={orders} getData={this.getData} parent={childProps} isAdmin={isAdmin} />
      else if(activeTitle === 'ALLA') x = <OrdersAll orders={orders} getData={this.getData} parent={childProps} isAdmin={isAdmin} />            
      else {
        x = orders.filter(order => order.category.includes(activeTitle) && order.showList).map((filteredOrder, j) => (
          <div key={j}>
            <Order order={filteredOrder} getData={this.getData} parent={childProps} isAdmin={isAdmin} renderButtons={renderButtons} />
          </div>
        ))
      }
      return x
    }

    return (
      <div className="Ordersedel" ref={topRef}>
        <div className="Ordersedel__main">
          <div className="Ordersedel__main__header">
            {isAdmin && <div className="Ordersedel__planning">
              <div className="Ordersedel__planning__header">
                <div className="Ordersedel__h2 flex-center"> 
                  Planering
                  <Switch
                    checked={this.state.planningMode}
                    name="planningMode"
                    onChange={this.handlePlanningCheckMode}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  {this.state.planningMode && <div className="spacer-5" />}
                  {this.state.planningMode && <CreateOrderDialog createOrder={this.createOrder} categories={this.state.categories} activeTitle={this.state.activeTitle} />}
                  {this.state.planningMode && <div className="spacer-5" />}
                  {this.state.planningMode && <CreateCookDialogFree getData={this.getData} />}
                  {this.state.planningMode && <div className="spacer-5" />}
                  {this.state.planningMode && <CreateDippDialogFree getData={this.getData} isAdmin={isAdmin} />}
                  
                </div>
              </div>
              {/* {this.state.planningMode && <div className="spacer-5" />}
              {this.state.planningMode && <div className="Ordersedel__checklist__body">
                <CreateOrderDialog createOrder={this.createOrder} categories={this.state.categories} activeTitle={this.state.activeTitle} />
              </div>} */}
            </div>}
            <div className="spacer-5" />
            <div className="Ordersedel__checklist">
              <div className="Ordersedel__checklist__header">
                <div className="Ordersedel__h2">
                  Checklista
                  <Switch
                    checked={this.state.checkMode}
                    name="checkMode"
                    onChange={this.handlePlanningCheckMode}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
                {/* <div className="spacer-10" /> */}
                {this.state.ordersChecklist && <div className="Ordersedel__checklist__header__signed">
                  <CheckIcon color="success" style={{ marginRight: "5px" }} /> <div>{this.state.ordersChecklist}</div>
                  {/* <CheckIcon color="success" style={{ marginRight: "5px" }} /> <div><div>{this.state.ordersChecklist.split(',')[0]}</div><div>{this.state.ordersChecklist.split(',')[1]}</div></div> */}
                </div>}
              </div>
              {this.state.checkMode && <div className="spacer-5" />}
              {this.state.checkMode && <div className="Ordersedel__checklist__body">
                <Button variant="text" color="primary" onClick={this.handleDeselectAll}>Avmarkera Alla</Button>
                <div className="spacer-10" />
                <PersonMenu handle={this.handleChangeMenu} objects={this.state.persons} text="Signera" />
              </div>}
            </div>
          </div>
          <div className="spacer-10" />
          <div className="Ordersedel__main__body">
            <div className="Ordersedel__main__body__header">
              <div className="Ordersedel__h1"> {activeTitle} </div>
              <div className="spacer-10" />
            </div>
            <div className="Ordersedel__main__body__list">
              {
                showOrdersList()
                // activeTitle === showListTitle ?
                //   <OrdersInactive orders={orders} getData={this.getData} parent={childProps} isAdmin={isAdmin} />
                //   :
                //   orders.filter(order => order.category.includes(activeTitle) && order.showList).map((filteredOrder, j) => (
                //     <div key={j}>
                //       <Order order={filteredOrder} getData={this.getData} parent={childProps} isAdmin={isAdmin} />
                //     </div>
                //   ))
              }
            </div>
          </div>
        </div>
        <div className="Ordersedel__side">
          <div className="Ordersedel__side__inner">
            <div className="Ordersedel__side__header">
              <Button className="Ordersedel__side__header__button" color="secondary" variant="outlined" onClick={() => window.location.reload()}>Uppdatera</Button>
            </div>
            <div className="spacer-10" />
            <div className="Ordersedel__side__body">
              <div className="Ordersedel__side__body__list">
                {categories.map(category => (
                  <div key={category} className="Ordersedel__side__body__list__item" onClick={() => this.handleActiveTitle(category)}>
                    <div className="Ordersedel__h2">
                      {category}
                    </div>
                  </div>
                ))}
                <div className="Ordersedel__side__body__list__item" onClick={() => this.setActiveTitle(showListTitle)}>
                  <div className="Ordersedel__h2">
                    {showListTitle}
                  </div>
                </div>
                <div className="Ordersedel__side__body__list__item" onClick={() => this.handleActiveTitle('ALLA')}>
                  <div className="Ordersedel__h2">
                    ALLA
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default WithUseAuth(OrderSedel);


function PersonMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClicked = name => {
    handleClose()
    props.handle(name)
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {props.text}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          props.objects.map(object => <MenuItem key={object.id} onClick={() => handleClicked(object.name)} value={object.name}>{object.name}</MenuItem>)
        }
      </Menu>
    </div>
  );
}


function CreateOrderDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [smak, setSmak] = React.useState('');
  const [category, setCategory] = React.useState(props.activeTitle);
  const [sort, setSort] = React.useState('Tryffel');
  const [type, setType] = React.useState('Singel');
  const [dippType, setDippType] = React.useState('Vit');
  const [subSmaker, setSubSmaker] = React.useState(["","","","",""]);

  const handleSubSmaker = (item, index) => {
    const _subSmaker = [...subSmaker]
    _subSmaker[index] = item
    setSubSmaker(_subSmaker)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const order = {
      smak, category, sort, type, dippType,
      antal: 0, limit: 0, subSmaker
    }
    await props.createOrder(order)
    handleClose()
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Smak +
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Lägg till smak</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <DialogContentText>
          </DialogContentText>
          <TextField
            id="standard-basic" label="Smak" variant="standard"
            onChange={e => setSmak(e.target.value)}
            value={smak}
          />
          <div className="spacer-5" />
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Kategori</InputLabel>
            <NativeSelect
              defaultValue={category}
              name="antal"
              onChange={e => setCategory(e.target.value)}
              inputProps={{
                name: 'antal',
                id: 'uncontrolled-native',
              }}
            >{props.categories.map(item => <option key={item} value={item}>{item}</option>)}
            </NativeSelect>
          </FormControl>
          <div className="spacer-5" />
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Sort</InputLabel>
            <NativeSelect
              defaultValue={sort}
              name="antal"
              onChange={e => setSort(e.target.value)}
              inputProps={{
                name: 'antal',
                id: 'uncontrolled-native',
              }}
            >{['Tryffel', 'Nougat', 'Kola', 'Flera'].map(item => <option key={item} value={item}>{item}</option>)}
            </NativeSelect>
          </FormControl>
          <div className="spacer-5" />
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Typ</InputLabel>
            <NativeSelect
              defaultValue={type}
              name="antal"
              onChange={e => setType(e.target.value)}
              inputProps={{
                name: 'antal',
                id: 'uncontrolled-native',
              }}
            >{['Singel', 'Budskap', 'Bland', 'Ask'].map(item => <option key={item} value={item}>{item}</option>)}
            </NativeSelect>
          </FormControl>
          {/* <div className="spacer-5" />
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Doppas i</InputLabel>
            <NativeSelect
              defaultValue={type}
              name="antal"
              onChange={e => setDippType(e.target.value)}
              inputProps={{
                name: 'antal',
                id: 'uncontrolled-native',
              }}
            >{['Vit', 'Ljus', 'Mörk', 'Flera'].map(item => <option key={item} value={item}>{item}</option>)}
            </NativeSelect>
          </FormControl> */}
        </DialogContent>
        {type == 'Bland' && <DialogContent className="CreateOrderDialog__form">
          {
            [...Array(5).keys()].map(i => {
              return <TextField
                id="standard-basic" label={`Undersmak ${i+1}`} variant="standard"
                onChange={e => handleSubSmaker(e.target.value, i)}
                value={subSmaker[i]}
                autoComplete="off"
                style={{marginRight: "5px"}}
              />
            })
          }
        </DialogContent>}
        <DialogActions className="CreateOrderDialog__buttons">
          <Button color="secondary" onClick={handleClose}>Stäng</Button>
          <Button color="primary" onClick={handleSave}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

