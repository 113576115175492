import React, {useState, useEffect} from 'react'
import { Dipps, weekNumbers, getTimestamp, Tracking_Recept_Dipp } from '../../api/routes';
import WithUseAuth from '../auth/WithUseAuth';
import './styles.css'
import DippDialogHistory from './DippDialogHistory';


import FormWrapper from '../components/FormWrapper/FormWrapper';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import NativeSelect from '@mui/material/NativeSelect'
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import TrackingReceptDippCreate from '../tracking/TrackingReceptDippCreate';

const weekDays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag', 'Välj Dag']

function DippDialogReturn(props) {
  const isEmpty = props.dipp.type == 'Bland' ? props.dipp.subSmak : props.dipp.smak

  const mapSelect = (label, _name) => {
    const __value = props.dipp[_name] 
    const _value = __value
    return <TextField
      label={label}
      id="outlined-start-adornment"
      sx={{ m: 1, width: '25ch' }}
      value={_value}
      InputProps={{
        readOnly: true,
        endAdornment: <InputAdornment position="start">st</InputAdornment>,
      }}
      style={{ width: "50px", margin: "0 5px" }}
    />
  }

  return (
    <div className="Cook__Wrapper">
      <div className="Cook__Overlay" onClick={props.handle.Open} />
      <div className="CookDialogReturn">
        <div className="flex">
          <TextField
            label="Smak"
            id="standard-basic"
            value={isEmpty}
            InputProps={{ readOnly: true, }}
            style={{ width: "150px" }}
          />
        </div>
        <div className="spacer-3" />
        <div className="flex">
          {
            [
              ["Back", "back"],
              ["Plåt", "plat"],
              ["Strut", "strut"]
            ].map(item => mapSelect(item[0], item[1]))
          }
        </div>
        <div className="spacer-3" />
        <TextField
          label="Kommentar"
          id="standard-basic"
          value={props.dipp.kommentar}
          InputProps={{
            readOnly: true,
          }}
          multiline
          maxrows={4}
        />

      </div>
    </div>
  )
}

function Dipp(props) {
  const { isAdmin } = props.useAuth;
  const [open, setOpen] = useState(false);
  const [dipp, setDipp] = useState(props.dipp)
  const [trackingRecept, setTrackingRecept] = useState(props.trackingRecept)
  const [TRDipp, setTRDipp] = useState(false)

  const weeks = weekNumbers()

  useEffect(() => {
    setDipp(props.dipp)
    setTrackingRecept(props.trackingRecept)
  }, [props.dipp, props.trackingRecept])
  
  const handle = {
    Open: () => {
      setOpen(true);
      handle.getData()
    },
    Close: () => {
      setOpen(false)
    },
    getData: async () => {
      const _TRDipp = await Tracking_Recept_Dipp.ReadByDippId(dipp.id)
      // if (!_TRDipp.hasOwnProperty("recept")) {
      //   setTRDipp([])
      //   return
      // }
      setTRDipp(_TRDipp)
    },
    Update: (name, value) => {
      const _dipp = { ...dipp }
      _dipp[name] = value
      setDipp(_dipp)
    },
    UpdateArray: (arr) => {
      const _dipp = { ...dipp }
      arr.map(item => _dipp[item.name] = item.value)
      setDipp(_dipp)
    },
    UpdateEvent: (e) => {
      const { name, value } = e.target
      const _dipp = { ...dipp }
      _dipp[name] = value
      setDipp(_dipp)
    },
    UpdateDay: (e) => {
      const _dipp = { ...dipp }
      _dipp.day_index = e.target.value
      _dipp.dag = weekDays[e.target.value]
      setDipp(_dipp)
    },
    Delete: async () => {
      if (window.confirm('Är du säker att du vill radera denna?')) {
        handle.Close()
        await Dipps.Delete(dipp)
        await props.getData()
      }
    },
    Create: async () => {
      handle.Close()
      await Dipps.Update(dipp)
      await props.getData()
    },
    CountDown: async (_dipp) => {
      await Dipps.Update(_dipp)
      // await props.getData()
      setDipp(_dipp)
    },
    SetDone: async name => {
      const _dipp = { ...dipp }
      _dipp.sign = name
      _dipp.done = true
      let today = getTimestamp()
      _dipp.doneTime = today
      handle.Close()
      setDipp(_dipp)
      await Dipps.Update(_dipp)
      await props.getData()
    },
    SetNotDone: async () => {
      if (window.confirm('Är du säker på att du vill återställa?')) {
        const _dipp = { ...dipp }
        _dipp.sign = ""
        _dipp.done = false
        setDipp(_dipp)
        handle.Close()
        await Dipps.Update(_dipp)
        await props.getData()
      }
    }
  }

  const isBland = dipp.type == 'Bland'
  const isBudskap = () => dipp.type == 'Budskap'
  const underSmakSelect = () => <FormControl variant="standard">
        <InputLabel id="demo-simple-select-label">Undersmak</InputLabel>
        <NativeSelect
        value={dipp.subSmak}
        name="subSmak"
        onChange={handle.UpdateEvent}
        inputProps={{ name: 'subSmak', id: 'uncontrolled-native', }}
        >{dipp.subSmaker.filter(item => item !== '').map(item => <option key={item} value={item}>{item}</option>)}
        </NativeSelect>
      </FormControl>
  
  const mapSwitches = () => {
    const mapSwitch = (label, name) => (
      <FormWrapper label={label}>
        <Switch
          checked={dipp[name]}
          name={name}
          onChange={e => handle.Update(e.target.name, e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
          color="primary"
        />
      </FormWrapper>
    )
    if(!isBudskap()) return mapSwitch("Till Pralinrum", "toPralinrum")
    return (
      <div className="flex-center">
        {mapSwitch("Backa Resten", "backaResten")}
        <div className="spacer-15" />
        {mapSwitch("Backad Ljus", "backadLjus")}
        <div className="spacer-10" />
        {mapSwitch("Backad Mörk", "backadMork")}
      </div>
    )
  }
  const mapSelect = (label, _name, array) => {
    const _value = dipp[_name]
    const getAllt = item => {
      if(!Number.isInteger(item)) return item
      return item
    }
    return <FormControl variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <NativeSelect
        name="mapSelect"
        value={_value ? _value : array[0]}
        onChange={e => handle.Update(_name, e.target.value)}
        inputProps={{ name: {_name}, id: 'uncontrolled-native', }}
      >{array.map(item => <option key={item} value={item}>{getAllt(item)}</option>)}
      </NativeSelect>
    </FormControl>
  }
  const mapSelectReadOnly = (label, _name, _value) => {
    return <TextField
      label={label}
      id="outlined-start-adornment"
      sx={{ m: 1, width: '25ch' }}
      value={_value}
      InputProps={{
        readOnly: true,
        endAdornment: <InputAdornment position="start">st</InputAdornment>,
      }}
      style={{ width: "50px", margin: "0 5px" }}
    />
  }
  const doneTimeSplit = () => {
    let doneTime = String(dipp.doneTime)
    if (doneTime !== '') return doneTime.split('T')[1].split(':')[0] + ':' + doneTime.split('T')[1].split(':')[1] + ' ' + doneTime.split('T')[0]
    return
  }

  const createTRDipp = async (newTRDipp) => {
    const _TRDipp = [...TRDipp]
    _TRDipp.push(newTRDipp)
    setTRDipp(_TRDipp)
  }

  return (
    <div className="CookDialog">
      <DippDialogReturn handle={handle} {...props} />

      <Dialog open={open} onClose={handle.Close}>
        <DialogTitle>
          <div className="flex">
            <div className="flex">
              Hantera en doppning
              <div className="spacer-10" />
              {!dipp.done && <PersonMenu handle={handle.SetDone} objects={props.persons} text="Färdigställ" />}
              {dipp.done && <div className="Ordersedel__checklist__header__signed">
                <CheckIcon color="success" style={{ marginRight: "5px" }} /> <div>{dipp.sign}, {doneTimeSplit()}</div>
                <div className="spacer-10" />
              </div>}
            </div>
            {dipp.done && <Button variant="text" color="primary" onClick={handle.SetNotDone}>Återställ</Button>}

          </div>
        </DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <div className="Cook__Wrapper">
            {!isAdmin() && <div className="Cook__Overlay" />}

            <div className="CookDialogContent">
              <div className="flex">
                <TextField
                  id="standard-basic" label="Smak" variant="standard"
                  onChange={handle.UpdateEvent}
                  value={dipp.smak}
                  name="smak"
                  InputProps={{ readOnly: true, }}
                />
                {isBland && <div className="spacer-5" /> && underSmakSelect()}

                {
                  [
                    ["Typ", "sort", ['Sort', 'Tryffel', 'Nougat', 'Kola', 'Flera']],
                    ["Singel/Budskap", "type", ["Singel", "Budskap"]],
                    ["Doppas i", "color", ['Vit', 'Ljus', 'Mörk', 'Flera']],
                    ["Back", "back", [...Array(30).keys()]],
                    ["Plåt", "plat", [...Array(30).keys()]],
                    ["Strut", "strut", [...Array(30).keys()]],
                    ["Vecka", "week", weeks]
                  ].map((item, index) => <React.Fragment key={index}>
                      <div className="spacer-5" />
                      {mapSelect(item[0], item[1], item[2])}
                    </React.Fragment>
                  )
                }

                <div className="spacer-5" />
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-label">Planerad Dag</InputLabel>
                  <NativeSelect
                    value={dipp.day_index}
                    name="day_index"
                    onChange={handle.UpdateDay}
                    inputProps={{ name: 'day_index', id: 'uncontrolled-native', }}
                  >{[7, 0, 1, 2, 3, 4, 5, 6].map(item => <option key={item} value={item}>{weekDays[item]}</option>)}
                  </NativeSelect>
                </FormControl>

                <div className="spacer-5" />
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-label">År</InputLabel>
                  <NativeSelect
                    value={dipp.year}
                    name="year"
                    onChange={handle.UpdateEvent}
                    inputProps={{ name: 'year', id: 'uncontrolled-native', }}
                  >{['2023', '2024'].map(item => <option key={item} value={item}>{item}</option>)}
                  </NativeSelect>
                </FormControl>

              </div>
              <div className="spacer-5" />
              <div className="flex">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-label">Prio</InputLabel>
                  <NativeSelect
                    value={dipp.prio}
                    name="prio"
                    onChange={handle.UpdateEvent}
                    inputProps={{ name: 'prio', id: 'uncontrolled-native', }}
                  >{[[1, 'Låg'], [2, 'Medel'], [3, 'Hög']].map(item => <option key={item[0]} value={item[0]}>{item[1]}</option>)}
                  </NativeSelect>
                </FormControl>
                <div className="spacer-5" />

                {mapSwitches()}
                <div className="spacer-10" />

                <TextField
                  id="standard-basic" label="Kommentar" variant="standard"
                  onChange={handle.UpdateEvent}
                  value={dipp.kommentar}
                  name="kommentar"
                  multiline
                  maxrows={4}
                />
              </div>

            </div>

          </div>

          <div className="spacer-10" />
          <div className="CookDialog__tracking">
            <div className="flex-align-center">
              <div className="flex-col">
                <div className="h6">Antal avräknade </div>
                <div className="spacer-5" />
                <div className="flex-center">
                {
                  [
                    ["Back", "back", dipp.countOffBack],
                    ["Plåt", "plat", dipp.countOffPlat],
                    ["Strut", "strut", dipp.countOffStrut]
                  ].map(item => mapSelectReadOnly(item[0], item[1], item[2]))
                }
                </div>
              </div>
              <div className="spacer-10" />
              {!dipp.done && <CountDownDialog handle={handle} {...props} />}
              <div className="spacer-10" />
              <DippDialogHistory handle={handle} dipp={dipp} {...props} />
            </div>
          </div>

          <div className="spacer-15" />

          {<div className="CookDialog__tracking">
            <div className="flex-align-center">
              <div className="h6">Spårbarhet</div>
              <div className="spacer-10" />
                <TrackingReceptDippCreate 
                  handleClose={handle.Close} 
                  createTRDipp={createTRDipp}
                  dipp={dipp} 
                  getDippData={props.getData} 
                  getTrackingReceptData={props.getTrackingReceptData} 
                  {...props}
                />
               
            </div>

            <div className="spacer-10" />
            <div className="CookDialog__tracking__list">
              {TRDipp && TRDipp.map((item, index) => {
                return (
                  <div key={index} className='flex'>
                    <TrackingReceptDippCreate 
                      handleClose={handle.Close} 
                      dipp={dipp} 
                      getDippData={props.getData} 
                      getTrackingReceptData={props.getTrackingReceptData} 
                      TRDipp={item}
                      {...props}
                    />
                    <div className="spacer-10" />
                  </div>
                )
              })}
            </div>
          </div>}

        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handle.Close}>Stäng</Button>
            {isAdmin() && <Button color="secondary" onClick={handle.Delete}>Radera</Button>}
          </div>
          <Button color="primary" onClick={handle.Create}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function CountDownDialog(props) {
  const [open, setOpen] = useState(false);
  const [emptySign, setEmptySign] = useState(false)
  const [count, setCount] = useState({
    back: 0, plat: 0, strut: 0,
    sign: 'Namn', time: getTimestamp()
  })

  const handle = {
    Open: () => {
      setOpen(true);
      
    },
    Close: () => {
      setOpen(false)
      setCount({
        back: 0, plat: 0, strut: 0,
        sign: 'Namn', time: getTimestamp()
      })
    },
    Update: (name, value) => {
      const _count = { ...count }
      _count[name] = value
      setCount(_count)
    },
    UpdateEvent: (e) => {
      const { name, value } = e.target
      const _count = { ...count }
      _count[name] = value
      setCount(_count)
    },
    CountDown: () => {
      const _dipp = {...props.dipp};
      ['back', 'plat', 'strut'].map(name => {
        _dipp[name] = _dipp[name] - count[name];
      })
      _dipp.countOff.push(count)

      _dipp.countOffBack = _dipp.countOffBack + parseInt(count.back)
      _dipp.countOffPlat = _dipp.countOffPlat + parseInt(count.plat)
      _dipp.countOffStrut = _dipp.countOffStrut + parseInt(count.strut)
      return _dipp
    },
    Create: async () => {
      if (count.sign !== '' && count.sign !== 'Namn') {
        setEmptySign(false)
        const _dipp = handle.CountDown()
        handle.Close()
        await props.handle.CountDown(_dipp)
      } else {
        setEmptySign(true)
      }
      return
    },
    GetNames: () => {
      return ['Namn', ...props.persons]
    }
  }

  const mapSelect = (label, _name, array) => {
    const _value = count[_name]
    return <FormControl variant="standard">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <NativeSelect
        name="mapSelect"
        value={_value ? _value : array[0]}
        onChange={e => handle.Update(_name, e.target.value)}
        inputProps={{ name: {_name}, id: 'uncontrolled-native', }}
      >{array.map(item => <option key={item} value={item}>{item}</option>)}
      </NativeSelect>
    </FormControl>
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handle.Open}>Avräkna</Button>


      <Dialog open={open} onClose={handle.close}>
        <DialogTitle>Avräkna</DialogTitle>
        <DialogContent> 
          <DialogContentText>
            Fyll i så många enheter av varje sort som du är klar med samt signera med namn.
          </DialogContentText>

          <div className="spacer-20" />

          <div className="FrysDialog__DialogContent">
          {
            [
              ["Back", "back", [...Array(30).keys()]],
              ["Plåt", "plat", [...Array(30).keys()]],
              ["Strut", "strut", [...Array(30).keys()]],
            ].map((item, index) => <React.Fragment key={index}>
                <div className="spacer-5" />
                {mapSelect(item[0], item[1], item[2])}
              </React.Fragment>
            )
          }

            <div className="spacer-15" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Signatur</InputLabel>
              <NativeSelect
                value={count.sign}
                name="sign"
                onChange={handle.UpdateEvent}
                inputProps={{ name: 'sign', id: 'uncontrolled-native', }}
                style={emptySign ? { borderBottom: "1px solid red" } : {}}
              >
                {handle.GetNames().map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
              </NativeSelect>
            </FormControl>

          </div>
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handle.Close}>Stäng</Button>
          </div>
          <div className="flex-center">
            <Button color="primary" onClick={handle.Create}>Spara</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}


export default WithUseAuth(Dipp)

function PersonMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClicked = name => {
    handleClose()
    props.handle(name)
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {props.text}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          props.objects.map(object => <MenuItem key={object.id} onClick={() => handleClicked(object.name)} value={object.name}>{object.name}</MenuItem>)
        }
      </Menu>
    </div>
  );
}