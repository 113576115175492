import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from './AuthContext'
import { Input, Button } from '../../v2/ui/aui'


export function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [error, setError] = useState(null)

  const { signIn } = useAuth()
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()
    const email = `${username.toLowerCase()}@arechokladfabrik.se`
    const { error } = await signIn({ email, password })

    if (error) {
      setError(error)
      alert(error)
      return
    }
    

    history.push('/')
  }

  return (
    <div className="aui background">
      <form onSubmit={handleSubmit} className='paper' style={{
        height: 'fit-content',
      }}>

        <h2>Åre Chokladfabrik</h2>
        <Input 
            placeholder="Användarnamn"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
            required
            fullWidth
            error={error}
        />
        <Input 
            type="password"
            placeholder="Lösenord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            error={error}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>Logga in</Button>
  
      </form>
        
        {/* <Button color="secondary" variant="contained" onClick={() => window.location.replace("https://arecms.se/anmal_franvaro")}>Anmäl frånvaro</Button> */}
    </div>
  )
}
