import React from 'react'
import SearchableList from './SearchableList'
import DialogMUI from './DialogMUI'

/*
    props:
        items: array of items to render
        item: (index, list) function to render each item
        callback: (index, event) function to call when an item is clicked

    Example:
        <SearchableList
            items={items}
            item={(index, _items) => <div>{_items[index]}</div>}
            callback={(value) => { console.log("You choose item with index: " + value) }}
        />
*/

export default function SearchableListDialogMUI(props) {
    const [_props, setProps] = React.useState(null)
    const [selectedItem, setSelectedItem] = React.useState(null)

    React.useEffect(() => {
        let defaultProps = {}
        setProps({...defaultProps, ...props})
    }, [props])

    const handleDialog = () => {
        _props.callback(selectedItem)
    }
    const handleSearchableList = (item) => {
        setSelectedItem(item)
    }

    return (
        <>
            {_props && 
            <div style={{
                width: "100%",  
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <DialogMUI
                    open={selectedItem ? false : true}
                    onOpen={() => { setSelectedItem(null) }}
                    title="Titel"
                    buttonText="välj"
                    callback={handleDialog} 
                >
                    <SearchableList {..._props} callback={handleSearchableList} />
                </DialogMUI>

            </div>
            }
            { !_props && <div>Loading...</div>}
        </>
    )
}
