import React from 'react';

import WithUseAuth from '../../pages/auth/WithUseAuth';

// material ui
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

function DotMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [object, setObject] = React.useState(null)

    React.useEffect(() => {
        const defaultObject = {
            adminOnly: false,
        }
        
        const newObject = {...defaultObject, ...props}

        setObject(newObject)
    }, [props])

    const { isAdmin } = props.useAuth;

    if(props.adminOnly && !isAdmin) return <></>
  
    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
            {
                props.items && props.items.length > 0 && 
                props.items.map((item, i) => (
                    <MenuItem key={i} onClick={() => {
                        handleClose()
                        item.onClick()
                    }}>
                        {item.name}
                    </MenuItem>
                ))
            }
            </Menu>
        </div>
    );
}

export default WithUseAuth(DotMenu)