import React, {useState, useEffect} from 'react'
import { Dipps, weekNumbers, getTimestamp, Tracking_Recept_Dipp } from '../../api/routes';
import WithUseAuth from '../auth/WithUseAuth';
import './styles.css'


import FormWrapper from '../components/FormWrapper/FormWrapper';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import NativeSelect from '@mui/material/NativeSelect'
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIconBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchableList from '../components/SearchableList';



function TrackingReceptDippCreate(props) {

  const genTRDipp = () => {
      if (props.TRDipp) {
          return props.TRDipp
      }
      return {
          date: ["","",""],
          sign: ["","",""],
          trackingRecept: {},
          dipp_id: {...dipp}.id,
          dipp: {...dipp},
          comment: ["","",""],
      }
  }

  const { isAdmin } = props.useAuth;
  const [open, setOpen] = useState(false);
  const [dipp, setDipp] = useState(props.dipp)
  const [trackingRecept, setTrackingRecept] = useState(props.trackingRecept)
  const [TRDipp, setTRDipp] = useState(genTRDipp())
  const [showList, setShowList] = useState(!props.TRDipp)


  const weeks = weekNumbers()

  useEffect(() => {
    setDipp(props.dipp)
    setTrackingRecept(props.trackingRecept)
    setTRDipp(genTRDipp())
  }, [props.dipp, props.trackingRecept])
  
  const handle = {
    Open: () => {
      setOpen(true);
      handle.getData()
    },
    Close: () => {
      setOpen(false)
      if (!props.TRDipp) setShowList(true)
      else setShowList(false)
    },
    getData: () => {

    },
    Update: (name, value) => {
      const _TRDipp = { ...TRDipp }
      _TRDipp[name] = value
      setTRDipp(_TRDipp)
    },
    UpdateEvent: (e) => {
      const { name, value } = e.target
      const _TRDipp = { ...TRDipp }
      _TRDipp[name] = value
      setTRDipp(_TRDipp)
    },
    UpdateEventArray: (index, e) => {
      const { name, value } = e.target
      const _TRDipp = { ...TRDipp }
      _TRDipp[name][index] = value
      setTRDipp(_TRDipp)
    },
    Delete: async () => {
      if (window.confirm('Är du säker att du vill radera denna?')) {
        handle.Close()
        await Tracking_Recept_Dipp.Delete(TRDipp)
        await props.getData()
      }
    },
    Create: async () => {
      handle.Close()
      if(props.TRDipp) await Tracking_Recept_Dipp.Update(TRDipp)
      else await Tracking_Recept_Dipp.Create(TRDipp)
      props.createTRDipp(TRDipp)
      // await props.getData()
    },
    ChooseTrackingRecept: async (item) => {
        handle.Update("trackingRecept", item)
        setShowList(!showList)
    },
  }

  const listTrackingRecept = () => {
    const row = item => <div onClick={() => handle.ChooseTrackingRecept(item)} className="listTrackingRecept__row">
        <div className="flex">
            <div>{item.cook_id} - </div><div>&nbsp;{item.recept.namn}</div>
        </div>
        <div className="spacer-10" />
        <div>{item.date}</div>
    </div> 

    function reverseArr(input) {
      var ret = new Array;
      for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
      }
      return ret;
    }

    const _arr = reverseArr(trackingRecept)
    const rows2 = _arr.map(item => `${item.date}: ${item.recept.namn} - ${item.cook_id}`)


    return (
        <div>
            <SearchableList
              items={rows2}
              item={(index, _items) => (
                <div style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <div style={{
                    minWidth: "80%",
                  }}>
                    {_items[index]}
                  </div>
                </div>
              )}
              callback={(item) => { 
                const index = rows2.indexOf(item)
                handle.ChooseTrackingRecept(_arr[index])
              }}
              itemHeight={50}
              width={500}
            />
        </div>
    )
  }

  const listTrackingReceptOLD = () => {
    const row = item => <div onClick={() => handle.ChooseTrackingRecept(item)} className="listTrackingRecept__row">
        <div className="flex">
            <div>{item.cook_id} - </div><div>&nbsp;{item.recept.namn}</div>
        </div>
        <div className="spacer-10" />
        <div>{item.date}</div>
    </div> 

    const _arr = trackingRecept.length < 30 ? trackingRecept : trackingRecept.slice(-30)
    const rows = trackingRecept ? _arr.reverse().map(item => row(item)) : ""

    return (
        <div className="flex-col">
            {rows}
        </div>
    )
  }

  const trackingDippForm = () => {
      const rows = TRDipp.date.map((item, index) => (
          <div class="trackingDippForm__row">
              <h6>{index + 1}.</h6>
              <div className="spacer-15" />
              <TextField
                onChange={(e) => handle.UpdateEventArray(index, e)}
                id="date"
                label="TV. Datum"
                type="date"
                name="date"
                variant="standard"
                value={TRDipp.date[index]}
                sx={{ width: 220 }}
                InputLabelProps={{ shrink: true }}
                style={{ width: "145px" }}
            />
            <div className="spacer-5" />

            <TextField
                onChange={(e) => handle.UpdateEventArray(index, e)}
                id="standard-basic" label="Signatur" variant="standard"
                value={TRDipp.sign[index]}
                name="sign"
            />
            <div className="spacer-5" />

            <TextField
                onChange={(e) => handle.UpdateEventArray(index, e)}
                id="standard-basic" label="Notering" variant="standard"
                value={TRDipp.comment[index]}
                name="comment"
                style={{ width: "145px" }}
            />
            <div className="spacer-5" />
          </div>
      ))

      return (
          <div className="flex-col">
              {rows}
              <div className="spacer-10" />
              {ViewTrackingRecept(TRDipp.trackingRecept)}
          </div>
      )
  }
  
  const buttons = () => {
    if(!props.justView) {
      return typeof props.TRDipp == 'undefined' ? 
      <Button color="primary" variant="contained" onClick={handle.Open}>Lägg till +</Button> 
      : 
      <Button onClick={handle.Open} variant="text" color="primary">{`${TRDipp.trackingRecept.recept.namn} - ${TRDipp.trackingRecept.cook_id}`}</Button>
    }
    return <Button onClick={handle.Open} variant="text" color="primary">visa</Button>
  }


  return (
    <div className="CookDialog">
      {buttons()}

      <Dialog open={open} onClose={handle.Close}>
        <DialogTitle>
          <div className="flex">
              {showList ? <div>Välj en kokning</div> : <div>{`Spårbarhet Vid Doppning För: ${TRDipp.dipp.smak}`}</div>}
              <div className="spacer-10" />
              {!showList && !props.justView && <Button color="primary" variant="contained" onClick={() => setShowList(true)}>Byt kokning</Button>}

              {/* {showList && 
              <SearchableListDialogMUI
                items={trackingRecept.map(item => (`${item.cook_id} - ${item.recept.namn} - ${item.date}`))}
                callback={(value) => { console.log("You choose: " + value) }}
                item={(index, _items) => <div>{_items[index]}</div>}
              />} */}
          </div>
        </DialogTitle>

        <DialogContent className="CreateOrderDialog__form">
          <div className="Cook__Wrapper">
            {props.dipp.done && !showList && <div className="Cook__Overlay" />}
            {props.justView && <div className="Cook__Overlay" />}
            <div className="flex">
                {showList ? listTrackingRecept() : trackingDippForm()}
            </div>
          </div>

        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handle.Close}>Stäng</Button>
            {props.TRDipp && !props.justView && <Button color="secondary" onClick={handle.Delete}>Radera</Button>}
          </div>
          {!props.justView && <Button color="primary" onClick={handle.Create}>Spara</Button>}
        </DialogActions>
      </Dialog>
    </div>
  )
}

const ViewTrackingRecept = (item) => {
    const renderPart = (_item, i) => {
        return <div key={i}>
          <div className="flex">
            <TextField
              id="standard-basic" label="Mått" variant="standard"
              value={item.recept.measures[i]}
              name="measures"
              style={{ width: "100px" }}
              InputProps={{ readOnly: true, }}
            />
            <div className="spacer-5" />
    
            <TextField
              id="standard-basic" label="Ingrediens" variant="standard"
              value={_item.name}
              name="ingredienser"
              InputProps={{ readOnly: true, }}
            />
            <div className="spacer-5" />
    
            <TextField
              id="standard-basic" label="Information" variant="standard"
              value={_item.info}
              name="info"
              style={{ width: "220px", }}
              InputProps={{ readOnly: true, }}
            />
            <div className='spacer-5' />
    
            <IconButton aria-label="delete">
              {_item.done ? <CheckBoxIcon /> : <CheckBoxIconBlank />}
            </IconButton>
          </div>
          <div className="spacer-5" />
        </div>
      }

    return (
      <div><DialogTitle style={{marginLeft:"-20px"}}>Tillhörande kokning</DialogTitle>
        <div className="CreateOrderDialog__form CookDialogContent">
          <div className="Cook__Wrapper">
            <div className="Cook__Overlay" />
            <div className="flex-col">

            <div>
                <TextField
                    id="standard-basic" label="Namn" variant="standard"
                    value={item.recept.namn}
                    name="namn"
                    InputProps={{ readOnly: true, }}
                />
                <div className="spacer-5" />
                <TextField
                    id="standard-basic" label="Skapare" variant="standard"
                    value={item.recept.author}
                    name="author"
                    InputProps={{ readOnly: true, }}
                />
                <div className="spacer-5" />
                <TextField
                    id="standard-basic" label="Instruktion" variant="standard"
                    value={item.recept.instruktion}
                    name="instruktion"
                    multiline
                    style={{ minWidth: "100%", }}
                    InputProps={{ readOnly: true, }}
                />

                <div className="spacer-20" />
            </div>

              <div className="flex-col">
                {item.steps.map((item, i) => renderPart(item, i))}
              </div>
              <div className="spacer-10" />

              <div className="flex">
                <TextField
                  id="date"
                  label="Kokningsdatum"
                  type="date"
                  name="date"
                  variant="standard"
                  value={item.date}
                  sx={{ width: 220 }}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "145px" }}
                  InputProps={{ readOnly: true, }}
                />
                <div className="spacer-5" />

                <TextField
                  id="standard-basic" label="Signatur" variant="standard"
                  value={item.sign}
                  name="sign"
                  InputProps={{ readOnly: true, }}
                />
                <div className="spacer-5" />

                <TextField
                  id="standard-basic" label="Antal Plåtar" variant="standard" type="number"
                  value={item.nrPlates}
                  name="nrPlates"
                  style={{ width: "100px" }}
                  InputProps={{ readOnly: true, }}
                />
                <div className="spacer-10" />
              </div>

            </div>
          </div>
        </div>
        </div>
    )
}


export default WithUseAuth(TrackingReceptDippCreate)
