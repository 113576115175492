import React, { useEffect, useState } from 'react'
import './styles.css'
import { WhiteboardContent } from '../../api/routes';
import WithUseAuth from '../auth/WithUseAuth';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const getTimestamp = () => {
  const date = new Date()
  const getZero = item => String(item).length < 2 ? `0${item}` : item
  const year = date.getFullYear() + '-' + getZero(date.getMonth() + 1) + '-' + getZero(date.getDate())
  const time = getZero(date.getHours()) + ':' + getZero(date.getMinutes()) + ':' + getZero(date.getSeconds()) + '.000Z'

  const full = year // + 'T' + time
  return full
}


function Whiteboard(props) {
  const { isAdmin } = props.useAuth;
  const [content, setContent] = useState({ text: "" })

  const getData = async () => {
    const _content = await WhiteboardContent.Read()
    setContent(_content[0]);
  }
  useEffect(() => {
    getData()
  }, [])

  const MINUTE_MS = 250000;

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAdmin()) return
      getData()
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])


  const handleChangeEvent = e => {
    const { name, value } = e.target
    const _content = { ...content }
    _content[name] = value
    setContent(_content)
  }

  const handleUpdate = async () => {
    await WhiteboardContent.Update(content)
  }

  return (
    <div>
      <div className="Whiteboard">
        <div className="Whiteboard__main">
          <div className="Whiteboard__main__header">

          </div>
          <div className="Whiteboard__main__body">
            <div className="Whiteboard__main__body__header">
              <div className="Whiteboard__h1">{getTimestamp()}</div>
            </div>
            <div className="Whiteboard__main__body__list">
              <div className="Whiteboard__week">
                <div className="Whiteboard__weekday">
                  <TextField
                    id="standard-basic" label="" variant="standard"
                    onChange={handleChangeEvent}
                    value={content.text}
                    name="text"
                    multiline
                    rows={30}
                    InputProps={{ readOnly: !isAdmin(), }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              {isAdmin() && <Button variant="contained" color="primary" onClick={handleUpdate}>Spara</Button>}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default WithUseAuth(Whiteboard);