import React, { useEffect, useState } from 'react'
import { Frys } from '../../api/routes';
import { FixedSizeList as List } from 'react-window';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormWrapper from '../components/FormWrapper/FormWrapper';

export default function FrysTinaHistoryDialog(props) {
  const [open, setOpen] = useState(false);

  const genFry = () => {
    const _item = props.fry
    _item.history.sort(function (a, b) {
      var c = new Date(b.date);
      var d = new Date(a.date);
      return c - d;
    });
    return _item
  }

  const [fry, setFry] = useState(genFry())

  const handleClickOpen = async () => {
    setOpen(true);
    setFry(genFry())
  };
  const handleClose = async () => {
    setOpen(false);
  };

  const Row = ({ index, style }) => (
    <div>
      <div className="FrysList__list__item">
        <div className="flex-col">
          <div className="flex">
            <div className="FrysDialog__item__FormWrapper">
              <FormWrapper label="Namn">
                {fry.name}
              </FormWrapper>
            </div>

            <div className="spacer-10" />

            <FormWrapper label="Tillverkad">
              {fry.doppad}
            </FormWrapper>

            <div className="spacer-10" />

            <FormWrapper label="Fryst">
              {fry.fryst}
            </FormWrapper>

            <div className="spacer-10" />

            <FormWrapper label="Fryst Av">
              {fry.frystSign}
            </FormWrapper>
          </div>

          <div className="spacer-10" />

          <div className="flex">
            <div className="FrysDialog__item__FormWrapper">
              <FormWrapper label="Tinat Antal">
                {fry.history[index].ammount}
                
                <div className="spacer-10" />
              </FormWrapper>
              <div className="spacer-10" />
            </div>
            <div className="spacer-10" />
            <div className="spacer-10" />
            <FormWrapper label="Tinad">
              {fry.history[index].date}
            </FormWrapper>
            <div className="spacer-10" />
            <FormWrapper label="Tinad Av">
              {fry.history[index].sign}
            </FormWrapper>
          </div>

        </div>
      </div>

      <div className="spacer-10" />
    </div>
  )

  const Example = () => (
    <List
      height={500}
      itemCount={fry.history.length}
      itemSize={80}
      width={500}
    >
      {Row}
    </List>
  );


  return (
    <div>
      <Button color="secondary" variant="outlined" onClick={handleClickOpen}>Historik Tinade (gammal)</Button>

      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle>Lista över senast tinade objekt</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <Example />
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
