import React, { useState, useEffect } from 'react'
import WithUseAuth from '../auth/WithUseAuth';
import { Persons } from '../../api/routes';
import FormWrapper from '../components/FormWrapper/FormWrapper';
import './styles.css'

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputText } from '../styledComponents';
import Switch from '@material-ui/core/Switch';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function PersonsList(props) {
  const { isAdmin } = props.useAuth;

  const [showInactive, setShowInactive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [persons, setPersons] = useState([])
  const [searchResults, setSearchResults] = useState(false)

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    Persons.Read(true).then(res => {
      const sorted = res.sort((a,b) => a.name - b.name)
      setPersons(sorted);
      setSearchResults(sorted)
      setLoading(false);
    });
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    
  };

  const handleUpdate = async (name, value, id) => {
    const _persons = [ ...persons ]
    const index = _persons.findIndex(p => p.id === id)
    _persons[index][name] = value
    setPersons(_persons)
    let inSearchArray = searchResults.findIndex(p => p.id === id)
    if (inSearchArray.length > -1) {
      let _searchResults = [ ...searchResults ]
      _searchResults[inSearchArray][name] = value
      setSearchResults(searchResults)
    }
    await Persons.Upsert(_persons[index])
  }
  
 
  const Row = ({ index, style }) => {
    const item = searchResults[index];

    if (!showInactive && !item.active) return <></>

    return (
      <div className="Butikslager__row">
        <div className="Butikslager__row__item">
            <FormWrapper label="Namn">
                {item.name}
            </FormWrapper> 
        </div>
        
        <FormWrapper label="Aktiv">
          <Switch
            checked={item.active}
            name="active"
            onChange={e => handleUpdate(e.target.name, e.target.checked, item.id)}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        </FormWrapper>

        <div className='spacer-10' />

        <FormWrapper label="Checklista">
          <Switch
            checked={item.checklista}
            name="checklista"
            onChange={e => handleUpdate(e.target.name, e.target.checked, item.id)}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        </FormWrapper>

        <div className='spacer-10' />

        <FormWrapper label="Kokning">
          <Switch
            checked={item.kokning}
            name="kokning"
            onChange={e => handleUpdate(e.target.name, e.target.checked, item.id)}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        </FormWrapper>

        <div className='spacer-10' />

        <FormWrapper label="Doppning">
          <Switch
            checked={item.doppning}
            name="doppning"
            onChange={e => handleUpdate(e.target.name, e.target.checked, item.id)}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        </FormWrapper>

        <div className='spacer-10' />

        <FormWrapper label="Pralinrum">
          <Switch
            checked={item.pralinrum}
            name="pralinrum"
            onChange={e => handleUpdate(e.target.name, e.target.checked, item.id)}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        </FormWrapper>

        <div className='spacer-10' />

        <FormWrapper label="Butikslager">
          <Switch
            checked={item.butikslager}
            name="butikslager"
            onChange={e => handleUpdate(e.target.name, e.target.checked, item.id)}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        </FormWrapper>

        <div className='spacer-10' />

        <FormWrapper label="Frysrum">
          <Switch
            checked={item.frysrum}
            name="frysrum"
            onChange={e => handleUpdate(e.target.name, e.target.checked, item.id)}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        </FormWrapper>
        

        <div className="spacer-10" />
        <div className="Butikslager__row__item flex">
          {isAdmin() && <ItemDialog getData={getData} handleUpdate={handleUpdate} item={item} /> }
        </div>
      </div>
    )
  }


  const List = () => {
    return (
      <div className="" style={{minWidth:"40vw"}}>
        { searchResults.map((item, index) => <Row key={index} index={index} style={{}} />) }
      </div>
    )
  }


  return (
    <div className="flex-col">
      <div className="flex-center">
        <div className="Whiteboard__h1">Personal</div>
      </div>

      <div className="ReceptList__list">
        <div className="flex-center">
          {isAdmin() && <ItemDialog getData={getData} />}
          <div className="spacer-10" />
          <SearchBox arr={persons} setArray={setSearchResults} />
          <div className='spacer-10' />
          <Button variant="outlined" color="secondary" onClick={() => setShowInactive(!showInactive)}>
            {showInactive ? "Dölj" : "Visa"} inaktiva
          </Button>
        </div>
        <div className="spacer-20" />

        <div className="flex-col-center">
          {!loading && persons && persons.length > 0 && <List />}
          {!loading && persons && persons.length == 0 && <div>Inga personal i lager</div>}
          {loading && <div>Laddar...</div>}
        </div>
      </div>  
    </div>
  )
}



function SearchBox({ arr, setArray }) {
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (arr !== false) {
      const results = arr.filter(item =>
        item.name.toLowerCase().includes(searchTerm)
      );
      setArray(results);
    }
  }, [searchTerm]);

  return (
    <div className="OrdersInactive">
      <TextField
        id="standard-basic" label="Sök Namn" variant="outlined" type="text"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value.toLowerCase())}
        style={{ margin: "10px 0" }}
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
      />
      {searchTerm !== "" && <IconButton onClick={() => setSearchTerm("")} style={{ marginLeft: "-45px" }}>
        <CloseIcon color="primary" />
      </IconButton>}
    </div>
  )
}


function ItemDialog(props) {
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState({
    name:"",
    active: true,
    checklista: false,
    kokning: false,
    doppning: false,
    pralinrum: false,
    butikslager: false,
    frysrum: false,
  })

  useEffect(() => {
    if (props.item) setItem(props.item)
  }, [])

  useEffect(() => {
    if (props.item) setItem(props.item)
  }, [props.item])


  const handleClickOpen = () => {
    setOpen(true);
    if (!props.item) setItem({
      name:"",
      active: true,
      checklista: false,
      kokning: false,
      doppning: false,
      pralinrum: false,
      butikslager: false,
      frysrum: false,
    })
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if(window.confirm("Är du säker på att du vill ta bort den?")) {
      await Persons.Delete(item)
      await props.getData()
      handleClose()
    }
  };

  const handleSave = async () => {
    await Persons.Upsert(item)
    await props.getData()
    handleClose()
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {props.item ? "Ändra" : "Lägg till +"}
      </Button>
      
      <Dialog open={open} onClose={handleClose}>
        <div style={{padding:"24px 0 0 24px"}}>
          <DialogTitle>Uppdatera personal</DialogTitle>
        </div>
        <DialogContent>
          
          <TextField
            variant="standard"
            type="text"
            label="Namn på person"
            name="name"
            value={item.name}
            onChange={e => setItem({...item, [e.target.name]: e.target.value})}
            autoFocus
          />
        </DialogContent>

        <DialogActions>
          <div className="flex-space-between" style={{width:"100%"}}>
            <div className="flex">
              <Button onClick={handleClose}>stäng</Button>
              { props.item && <Button onClick={handleDelete} color="secondary">radera</Button> }
            </div>
            
            <Button onClick={handleSave} color="primary">Spara</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}








export default WithUseAuth(PersonsList);
