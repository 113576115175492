import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Input, Checkbox, FormControlLabel } from '@material-ui/core';

export default function SortDialog(props) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState()
  const [children, setChildren] = useState()

  useEffect(() => {
    handleLoad()
  }, [])

  return (
    <div>
      <Button disableElevation variant="contained" color="primary" onClick={() => handleOpen()}   >
        Ändra
      </Button>
      {open &&
        <Dialog open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Uppdatera sort</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <div className="flex-col flex-center">
                <Input style={{fontSize:"large"}} autoFocus type="text" placeholder="Sort" value={name} onChange={e => setName(e.target.value)} />

                {children[0] && <div style={{marginTop: "10px", display:"flex", alignItems:"center", marginTop: "10px", background:"#f7f6f2", padding: "10px", borderRadius:"5px", border:"1px solid #eee" }}>
                  <Input type="text" placeholder="Undersort" value={children[0].name} onChange={e => handleChild(e, 0)} />  
                  <FormControlLabel style={{marginBottom:"0px"}} value="start" control={<Checkbox color="primary" checked={children[0].stangerShow} onChange={e => handleCheck(e, 0)} />} label="Stänger" labelPlacement="start" />
                </div>}

                {children[1] && <div style={{marginTop: "10px", display:"flex", alignItems:"center", marginTop: "10px", background:"#f7f6f2", padding: "10px", borderRadius:"5px", border:"1px solid #eee" }}>
                  <Input type="text" placeholder="Undersort" value={children[1].name} onChange={e => handleChild(e, 1)} />  
                  <FormControlLabel style={{marginBottom:"0px"}} value="start" control={<Checkbox color="primary" checked={children[1].stangerShow} onChange={e => handleCheck(e, 1)} />} label="Stänger" labelPlacement="start" />
                </div>}

                {children[2] && <div style={{marginTop: "10px", display:"flex", alignItems:"center", marginTop: "10px", background:"#f7f6f2", padding: "10px", borderRadius:"5px", border:"1px solid #eee" }}>
                  <Input type="text" placeholder="Undersort" value={children[2].name} onChange={e => handleChild(e, 2)} />  
                  <FormControlLabel style={{marginBottom:"0px"}} value="start" control={<Checkbox color="primary" checked={children[2].stangerShow} onChange={e => handleCheck(e, 2)} />} label="Stänger" labelPlacement="start" />
                </div>}

                {children[3] && <div style={{marginTop: "10px", display:"flex", alignItems:"center", marginTop: "10px", background:"#f7f6f2", padding: "10px", borderRadius:"5px", border:"1px solid #eee" }}>
                  <Input type="text" placeholder="Undersort" value={children[3].name} onChange={e => handleChild(e, 3)} />  
                  <FormControlLabel style={{marginBottom:"0px"}} value="start" control={<Checkbox color="primary" checked={children[3].stangerShow} onChange={e => handleCheck(e, 3)} />} label="Stänger" labelPlacement="start" />
                </div>}

                {children[4] && <div style={{marginTop: "10px", display:"flex", alignItems:"center", marginTop: "10px", background:"#f7f6f2", padding: "10px", borderRadius:"5px", border:"1px solid #eee" }}>
                  <Input type="text" placeholder="Undersort" value={children[4].name} onChange={e => handleChild(e, 4)} />  
                  <FormControlLabel style={{marginBottom:"0px"}} value="start" control={<Checkbox color="primary" checked={children[4].stangerShow} onChange={e => handleCheck(e, 4)} />} label="Stänger" labelPlacement="start" />
                </div>}
              
              </div>
              <br />
              <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                <Button onClick={() => { handleClose() }} color="secondary">
                  STÄNG
              </Button>
                <Button type="submit" color="primary">
                  SPARA
              </Button>
              </DialogActions>
            </form>

          </DialogContent>
        </Dialog>}
    </div>
  );

  function handleLoad() {
    const sort = { ...props.sort }
    const _children = sort.children.map(child => child)

    setName(sort.name)
    setChildren(_children)
  }

  async function handleOpen() {
    await handleLoad()
    setOpen(true)
  }

  function handleClose() {
    setName("")
    setChildren(["", "", "", "", ""])
    setOpen(false)
  }

  function handleChild(e, index) {
    const _children = [...children]
    _children[index].name = e.target.value
    setChildren(_children)
  }

  function handleCheck(e, index) {
    const _children = [...children]
    _children[index].stangerShow = e.target.checked
    setChildren(_children)
  }

  function handleSubmit(event) {
    event.preventDefault();

    const newSort = { ...props.sort }
    newSort.children = children
    newSort.name = name

    console.log(newSort)
    props.updateSort(newSort, props.index)
    handleClose()
  }

}