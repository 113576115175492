import React, { useState } from 'react'
import { FixedSizeList as List } from 'react-window';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormWrapper from '../components/FormWrapper/FormWrapper';

export default function DippDialogHistory(props) {
  const [open, setOpen] = useState(false);

  const getCounts = () => {
    const _item = props.dipp.countOff
    _item.sort(function (a, b) {
      var c = new Date(b.time);
      var d = new Date(a.time);
      return c - d;
    });
    return _item
  }

  const [counts, setCounts] = useState(getCounts())

  const handleClickOpen = async () => {
    setOpen(true);
    setCounts(getCounts())
  };
  const handleClose = async () => {
    setOpen(false);
  };

  const splitTime = time => `${time.split('T')[0]} ${time.split('T')[1].split('.')[0].split(':')[0]}:${time.split('T')[1].split('.')[0].split(':')[1]}`

  const Row = (item) => {
    if (item.back === 0 && item.plat === 0 && item.strut === 0) return (<div></div>)
    
    return (
      <div>
        <div className="ReceptList__list__item">
          <div className="flex-col">
            <div className="flex">
              {
                  [
                      ["Back", "back"],
                      ["Plåt", "plat"],
                      ["Strut", "strut"]
                  ].map(name => <div className="flex">
                      <FormWrapper label={name[0]}>
                          <div className="flex">{item[name[1]]} st</div>
                      </FormWrapper>
                      <div className="spacer-10" />
                  </div>)
              }
              
              <FormWrapper label="Tid">
                {splitTime(item.time)}
              </FormWrapper>
              <div className="spacer-10" />
              <FormWrapper label="Signatur">
                {item.sign}
              </FormWrapper>
          </div>
        </div>
  
      </div>
      </div>
    )
  }


  return (
    <div>
      <Button color="secondary" variant="contained" onClick={handleClickOpen}>Historik</Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Lista över avräknade enheter</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          {/* <Example /> */}
            {counts.map(item => Row(item))}
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
