import React, { useEffect, useState } from 'react'
import { Frys, getDate } from '../../api/routes';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect'
import InputLabel from '@mui/material/InputLabel';


export default function FrysCreateDialog(props) {
  const [open, setOpen] = useState(false);


  const getExtraName = () => {
    return [...props.persons]
  }

  const genFry = () => {
    const genArray = (len, cont) => Array.from({ length: len }, _ => cont)
    const item = {
      name: "", done: false, doppad: getDate(), fryst: getDate(), frystSign: "", history: [], ammount: 0, unit: "st"
    }
    return item
  }
  const [fry, setFry] = useState(genFry())


  const handleClickOpen = async () => {
    setOpen(true);
    setFry(genFry())
  };
  const handleClose = async () => {
    setOpen(false);
  };
  
  const handleUpdateEvent = e => {
    const { name, value } = e.target
    const _fry = { ...fry }
    _fry[name] = value
    setFry(_fry)
  }


  const handleCreate = async () => {
    handleClose()
    await Frys.Create(fry)
    await props.getData(true)
  }


  return (
    <div>
      <Button color="primary" variant="contained" onClick={handleClickOpen}>Frys in +</Button>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Lägg till i Frys</DialogTitle>
        <DialogContent className="FrysDialog__item">
          <TextField
            autoComplete="off"
            id="standard-basic" label="Namn" variant="standard"
            onChange={handleUpdateEvent}
            value={fry.name}
            name="name"
          />
          <div className="spacer-5" />
          <TextField
            id="standard-basic" label="Antal" variant="standard" type="number" min="0"
            onChange={handleUpdateEvent}
            value={fry.ammount}
            name="ammount"
            style={{
              maxWidth: "80px"
            }}
          />
          <div className="spacer-5" />
          <TextField
            id="standard-basic" label="Enhet" variant="standard"
            onChange={handleUpdateEvent}
            value={fry.unit}
            name="unit"
            style={{
              maxWidth: "80px"
            }}
          />
          <div className="spacer-5" />

          <TextField
            name="doppad"
            label="Tillverkad"
            type="date"
            variant="standard"
            value={fry.doppad}
            sx={{ width: 220 }}
            InputLabelProps={{ shrink: true }}
            onChange={handleUpdateEvent}
          />
          <div className="spacer-5" />
          <TextField
            name="fryst"
            label="Fryst"
            type="date"
            variant="standard"
            value={fry.fryst}
            sx={{ width: 220 }}
            InputLabelProps={{ shrink: true }}
            onChange={handleUpdateEvent}
          />
          <div className="spacer-5" />
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Fryst Av</InputLabel>
            <NativeSelect
              value={fry.frystSign}
              name="frystSign"
              onChange={handleUpdateEvent}
              inputProps={{ name: 'frystSign', id: 'uncontrolled-native', }}
            >
              <option value="Namn">Namn</option>
              {getExtraName().map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
            </NativeSelect>
          </FormControl>

        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
          </div>
          <Button color="primary" onClick={handleCreate}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
