import axios from 'axios';
import Cookies from 'js-cookie'

export const Url = "http://arecms.se:4000";
export const Auth = {token: Cookies.get('auth_token'), level: Cookies.get('level')}
const headers = {'auth_token': Auth.token}

const sortByName = (a, b) => {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}


export const Persons = {
  Create: async data => { 
    const url = `${Url}/api/persons/insert`;
    await axios.post(url, {headers, data}).then(res => {console.log(res)})
  },
  Read: async () => {
    var response;
    const url = `${Url}/api/persons`;
    await axios.get(url, { headers }).then(res => {
      console.log("PersonsRead"); 
      response = res.data.persons
      response.sort(sortByName)
    })
    return response
  },
  Update: async data => {
    const url = `${Url}/api/persons/${data._id}`;
    await axios.put(url, { headers, data }).then(res => { console.log("PersonsUpdate"); })
  },  
  Delete: async _id => {
    const url = `${Url}/api/persons/${_id}`;
    await axios.delete(url, {headers}).then(res => {console.log("PersonsDelete")})
  }
}

export const Pralinrum = {
  Create: async data => { 
    const url = `${Url}/api/pralins/insert`;
    await axios.post(url, {headers, data}).then(res => {console.log(res)})
  },
  Read: async () => {
    console.log("PralinrumRead"); 
    var response;
    const url = `${Url}/api/pralins`;
    await axios.get(url, { headers }).then(res => {
      console.log("PralinrumRead"); 
      response = res.data.pralins
    })
    return response
  },
  Update: async data => {
    const url = `${Url}/api/pralins/${data._id}`;
    await axios.put(url, { headers, data }).then(res => { console.log("PralinrumUpdate"); })
  },  
  Delete: async _id => {
    const url = `${Url}/api/pralins/${_id}`;
    await axios.delete(url, {headers}).then(res => {console.log("PralinrumDelete")})
  },
  Logg:  {
    Create: async data => { 
      const url = `${Url}/api/pralins/logg/insert`;
      await axios.post(url, {headers, data}).then(res => {console.log(res)})
    },
    Read: async max => {
      var response;
      const url = `${Url}/api/pralins/logg`;
      await axios.get(url, { headers }).then(res => {
        console.log("PralinrumReadLogg"); 
        response = res.data.pralins
      })
      if(max) {
        if(max >= response.length) return response
        return response.splice(0, max)
      } 
      return response
    },
  }
}

export const Orders = {
  Create: async data => { 
    const url = `${Url}/api/orders/insert`;
    await axios.post(url, {headers, data}).then(res => {console.log(res)})
  },
  CreateMany: async _data => { 
    const url = `${Url}/api/orders/insert`;

    await _data.map(data => {
      axios.post(url, {headers, data}).then(res => {console.log(res)})
    })
  },
  Read: async () => {
    var response;
    const url = `${Url}/api/orders`;
    await axios.get(url, { headers }).then(res => {
      console.log("OrdersRead"); 
      response = res.data.orders
    })
    return response
  },
  Update: async data => {
    const url = `${Url}/api/orders/${data._id}`;
    await axios.put(url, { headers, data }).then(res => { console.log("OrdersUpdate"); })
  },  
  Delete: async _id => {
    const url = `${Url}/api/orders/${_id}`;
    await axios.delete(url, {headers}).then(res => {console.log("OrdersDelete")})
  }
}