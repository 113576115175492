import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Input, Checkbox, FormControlLabel } from '@material-ui/core';

export default function SortDialog(props) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState("")
  const [children, setChildren] = useState(["", "", "", "", ""])
  const [stangerShow, setStangerShow] = useState([false, false, false, false, false])
  

  return (
    <div>
      <Button variant="contained" color="primary" disableElevation onClick={() => setOpen(!open)}>
        Lägg till +
      </Button>
      <Dialog open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Lägg till sort</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div className="flex-col flex-center">
              <Input style={{fontSize:"large"}} autoFocus type="text" placeholder="Sort" value={name} onChange={e => setName(e.target.value)} />
              {
                [0, 1, 2, 3, 4].map(index => (
                  <div key={index} style={{ display:"flex", alignItems:"center", marginTop: "10px", background:"#f7f6f2", padding: "10px", borderRadius:"5px", border:"1px solid #eee" }}>
                    <Input  type="text" placeholder="Undersort" value={children[index]} onChange={e => handleChild(e, index)} />
                    <FormControlLabel
                      style={{marginBottom:"0px"}}
                      value="start"
                      control={<Checkbox color="primary" checked={stangerShow[index]} onChange={e => handleCheck(e, index)} />}
                      label="Stänger"
                      labelPlacement="start"
                    />
                  </div>
                ))
              }
            </div>


            <br />
            <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
              <Button onClick={() => { handleClose() }} color="secondary">
                STÄNG
              </Button>
              <Button type="submit" color="primary">
                SPARA
              </Button>
            </DialogActions>
          </form>

        </DialogContent>
      </Dialog>
    </div>
  );

  function handleClose() {
    setName("")
    setChildren(["", "", "", "", ""])
    setStangerShow([false, false, false, false, false])
    setOpen(false)
  }

  function handleChild(e, index) {
    const _children = [...children]
    _children[index] = e.target.value
    setChildren(_children)
  }

  function handleCheck(e, index) {
    const _stangerShow = [...stangerShow]
    _stangerShow[index] = e.target.checked
    setStangerShow(_stangerShow)
  }

  function handleSubmit(event) {
    event.preventDefault();

    const childModel = {
      name: "",
      back: {back: 0},
      plat: {
        askar: 0,
        stanger: 0,
      },
      stangerShow: false,
    }

    const _children = [...children].map((_name, index) => {
      const child = { ...childModel }
      const show = [... stangerShow]
      child.name = _name
      child.stangerShow = show[index]
      if (_name !== "") return child
    }).filter(function (e) { return e != null; });

    const newSort = {
      name: name,
      children: _children,
      logg: []
    }

    console.log(newSort)
    props.createSort(newSort)
    handleClose()
  }

}