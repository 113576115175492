import React, { useState, useEffect } from 'react'
import { Routes } from '../../api/routes'
import { handleDates, objectHandle } from '../../utils'
import { textField, dateField, nativeSelect, multiline_textField } from '../components/form'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';



function TrackingPackBland(props) {
    const [open, setOpen] = useState(false)
    const [orders, setOrders] = useState(null)    
    const [object, setObject] = useState(null)
    const [showError, setShowError] = useState(false)
    const [tracking_pack_dates, setTracking_pack_dates] = useState(null)

    let emptyObject = {
        smak: "",
        subSmaker: [0,1,2,3,4,5,6,7,8,9,10,11,12].map(index => ({
            smak:"",
            doppningsDatum: handleDates.today.getDate(),
            infrysningsDatum: handleDates.today.getDate(),
            uptiningsDatum: handleDates.today.getDate(),
            ddm: []
        })),
        tillverkningsDatum: handleDates.today.getDate(),
        dagensDatum: handleDates.today.getDate(),
        signatur: "",
        kommentar: ""
    }

    useEffect(() => {
        setObject(props.item ? props.item.data : emptyObject)
        setShowError(false) 
    }, [props])

    let getData = async () => {
        let res = await Routes.Read("orders")
        let filterRes = res.filter(item => item.showList && item.type == "Bland")
        setOrders(filterRes)   
        
        let tracking_pack_dates = await Routes.Read("tracking_pack_dates")
        setTracking_pack_dates(tracking_pack_dates)
    }
    let loadObject = async e => {
        const { value } = e.target;
        if (value == "") {
            setObject(emptyObject)
            return
        }
        const _object = { ...object }
        _object.smak = value
        _object.subSmaker = orders.find(item => item.smak == value).subSmaker.map(item => ({
            smak: item,
            doppningsDatum: "",
            infrysningsDatum: "",
            uptiningsDatum: "",
        }))

        for (let i = 0; i < _object.subSmaker.length; i++) {
            let found = await tracking_pack_dates.find(obj => obj.name == _object.subSmaker[i].smak.toLowerCase())
            _object.subSmaker[i].doppningsDatum = found ? found.date : ""
            _object.subSmaker[i].infrysningsDatum = found ? found.date : ""
            _object.subSmaker[i].uptiningsDatum = found ? found.date : ""
        }

        setObject(_object)
    }

    const handleDialog = {
        open: () => {
            setObject(props.item ? props.item.data : emptyObject)
            setOpen(true)
            getData()
        },
        close: () => {
            setOpen(false)
        },
        create: async () => {
            if(object.signatur !== "" && object.tillverkningsDatum !== "" && object.dagensDatum !== ""){
                handleDialog.close()
                const newObject = {
                    type:"bland",
                    data: object
                }
                await Routes.Create(newObject, "tracking_pack")

                // update tracking_pack_dates and log it
                let createArray = []
                let upsertArray = []
                let logArray = []

                for (let i = 0; i < object.subSmaker.length; i++) {
                    let item = object.subSmaker[i]

                    if (!item.ddm || item.ddm.length == 0) item.ddm = [item.doppningsDatum]
                    else item.ddm.push(item.doppningsDatum)
                    let _ddm = [...item.ddm]
                    let highest = _ddm.sort((a,b) => new Date(b) - new Date(a))[0]

                    item.ddm = _ddm

                    item.doppningsDatum = highest

                    let found = await tracking_pack_dates.find(obj => obj.name == item.smak.toLowerCase())
                    if(found && found.date !== item.doppningsDatum){
                        logArray.push({
                            name: found.name,
                            date: found.date,
                            tracking_from: found.tracking_from,
                            tracking_to: handleDates.today.getDate(),
                            category: found.category,
                        })

                        found.date = item.doppningsDatum
                        found.tracking_from = handleDates.today.getDate()

                        upsertArray.push(found)
                    } else if (!found) {
                        if (item.smak !== "" && item.doppningsDatum !== "") {
                            createArray.push({
                                name: item.smak.toLowerCase(),
                                date: item.doppningsDatum,
                                tracking_from: handleDates.today.getDate(),
                                category: "blandstrutar"
                            })
                        }
                    }
                }

            
                if(createArray.length > 0) await Routes.Create(createArray, "tracking_pack_dates")
                if(upsertArray.length > 0) {
                    await Routes.Upsert(upsertArray, "tracking_pack_dates")
                    await Routes.Create(logArray, "tracking_pack_dates_history")
                }

                await props.getData()
                return
            }
            setShowError(true) 
        },
        update: async () => {
            if(true){
                handleDialog.close()
                await Routes.Update(object, "tracking_pack")
                await props.getData()
                return
            }
            setShowError(true) 
        },
        delete: async () => {
            if(window.confirm("Är du säker på att du vill radera?")){
                handleDialog.close()
                await Routes.Delete(object, "tracking_pack")
                await props.getData()
                return
            }
        },
        settings: {
            showDelete: props.item,
            showCreate: !props.item,
            showSave: false,
            showOverlay: props.item,
        }
    }
    let handleObject = objectHandle({object, setObject, Routes, table: "tracking_pack"})

   
    return (
        <div>
            {props.item ? 
                <Button color="primary" onClick={handleDialog.open}>visa</Button>  
                : 
                <Button color="primary" variant="contained" onClick={handleDialog.open}>Lägg till +</Button> 
            }
              
            {object && orders && <Dialog open={open} onClose={handleDialog.close}>
                <DialogTitle>SPÅRBARHETSDOKUMENT PACKNING BLANDSTRUTAR</DialogTitle>

                <DialogContent>
                    <div className="p-30" style={{
                        position: "relative",
                    }}>
                        
                        {handleDialog.settings.showOverlay && <div className="overlay" />}

                        <div className="flex">
                            { textField("Smak fri text", "smak", object.smak, handleObject.setEvent) }
                            <div className='spacer-10' />
                            { nativeSelect("Välj smak från lista", "smak", object.smak, orders.map(item => item.smak), loadObject) }
                        </div>
                        
                        <div className="flex mt-20">
                            { dateField("Tillverkningsdatum", "tillverkningsDatum", object.tillverkningsDatum, handleObject.setEvent, showError) }                    
                        </div>

                        <div className="flex mt-40">
                            <div className='w-180'>Tryffel eller kola</div>
                            <div className='spacer-10' />
                            <div className='w-138'>Doppningsdatum</div>
                            <div className='spacer-10' />
                            <div className='w-138'>Infrysningsdatum</div>
                            <div className='spacer-10' />
                            <div className='w-138'>Uppfrysning</div>
                        </div>

                        <div className="flex-col mt-10">
                            {object.subSmaker.map((item, index) => (
                                <div className="flex mt-5" key={index}>
                                    { textField("", "smak", item.smak, e => handleObject.setEventSubkeyIndex(e, "subSmaker", index)) }
                                    
                                    <div className='spacer-10' />
                                    
                                    {item.smak != "" && <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                    }}>
                                    {item.smak != "" && dateField("", "doppningsDatum", item.doppningsDatum, e => handleObject.setEventSubkeyIndex(e, "subSmaker", index)) }
                                    {item.smak == "" && <div className='w-180' />}
                                
                                        {item.smak != "" && item.ddm && item.ddm.length > 0 && item.ddm.map((ddm, index2) => {
                                            let name = "doppningsDatum" + index2
                                            if (item.doppningsDatum !== handleDates.today.getDate() && item.doppningsDatum == ddm) return <> </>
                                            return <>
                                                <div className='spacer-5' />
                                                {dateField("", name, ddm, e => {
                                                    let newObject = {...object}
                                                    newObject.subSmaker[index].ddm[index2] = e.target.value
                                                    setObject(newObject)
                                                })}
                                            </>
                                        })}
                                    
                                    
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        {!handleDialog.settings.showOverlay && item.smak != "" && <Button variant='contained' color='primary' disableElevation size="small" onClick={() => {
                                            let _object = {...object}
                                            console.log(_object)
                                            if (!_object.subSmaker[index].ddm) _object.subSmaker[index].ddm = []
                                            _object.subSmaker[index].ddm.push(handleDates.today.getDate())
                                            setObject(_object)
                                        }}>+</Button>}

                                        <div className='spacer-5' />
                                        {!handleDialog.settings.showOverlay && item.smak != "" && item.ddm  && item.ddm.length > 0 && <Button variant='contained' size="small"  color='secondary' disableElevation onClick={() => {
                                            let _object = {...object}
                                            _object.subSmaker[index].ddm.pop()
                                            setObject(_object)
                                        }}>-</Button>}
                                    </div>
                                    
                                    </div>}

                                    <div className='spacer-10' />
                                    {item.smak != "" && dateField("", "infrysningsDatum", item.infrysningsDatum, e => handleObject.setEventSubkeyIndex(e, "subSmaker", index)) }

                                    <div className='spacer-10' />
                                    {item.smak != "" && dateField("", "uptiningsDatum", item.uptiningsDatum, e => handleObject.setEventSubkeyIndex(e, "subSmaker", index)) }

                                </div>
                            ))}
                        </div>
                            
                        <div className="flex mt-20">
                            { multiline_textField("Kommentar", "kommentar", object['kommentar'], handleObject.setEvent) }
                        </div>
                        
                        <div className="flex mt-20">
                            { dateField("Dagens datum", "dagensDatum", object.dagensDatum, handleObject.setEvent, showError) }
                            <div className='spacer-10' />
                            { textField("Signatur", "signatur", object.signatur, handleObject.setEvent, showError) }
                        </div>


                    </div>
                </DialogContent>
                <DialogActions className="flex-space-between" style={{zIndex:"200"}}>
                    <div className="flex">
                        <Button onClick={handleDialog.close}>Stäng</Button>
                        <div className='spacer-10' />
                        {handleDialog.settings.showDelete && <Button color="secondary" onClick={handleDialog.delete}>Radera</Button>}
                    </div>
                    {handleDialog.settings.showSave && <Button color="primary" onClick={handleDialog.update}>Spara</Button>}
                    {handleDialog.settings.showCreate && <Button color="primary" onClick={handleDialog.create}>Skapa</Button>}
                </DialogActions>
            </Dialog>}
        </div>
    )
}
  

export default TrackingPackBland

