import React, { useState } from 'react'
import { InputText } from '../../styledComponents'

import Button from '@material-ui/core/Button';

export default function Checkout(props) {
  const [name, setName] = useState("")
  const [NoName, setNoName] = useState(false)
  const names = props.persons.map(item => item.name).sort()

  const style = {
    backWrapper: {
      width: "100vw",
      position: "fixed",
      left: "0",
      bottom: "20px",
    },
    wrapper: {
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    menu: {
      border: "1px solid #eee",
      background: "white",
      padding: "20px",
      borderRadius: "8px",
      alignItems: "center",
      justifyContent: "space-evenly",
    }
  }

  const menu = (
    <div style={style.menu} className="flex">
      <InputText
        as="select"
        value={name}
        onChange={e => handleName(e.target.value)}
        size="sm"
        style={{ border: NoName ? "2px solid red" : '2px solid #eee', width: "150px", marginRight: "20px" }}
      >
        <option>Namn</option>
        {names.map((item, i) => { if(props.persons[i].active) return <option key={i}>{item}</option> })}
      </InputText>
      <Button variant="contained" color="primary" onClick={() => checkout()}>signera</Button>

    </div>
  )

  return (
    <div style={style.backWrapper}>
      <div style={style.wrapper}>
        {menu}
      </div>
    </div>
  )


  function handleName(name) {
    setName(name)
    setNoName(false)

    const person = [...props.persons].find(x => x.name === name)
    props.handleCheckIn(person)
  }

  async function checkout() {
    if (name === '') {
      setNoName(true)
      return
    }
    await props.handleCheckout()
    setName("")
  }
}
