import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FormWrapper from '../../../pages/components/FormWrapper/FormWrapper'
import { Checkbox } from '@material-ui/core'

export default function History(props) {
    const [open, setOpen] = React.useState(false)
    const [object, setObject] = React.useState()

    React.useEffect(() => {
        const _object = { ...props.item }
        // sort history by date, newest first
        _object.history.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
        setObject(_object)
    }, [props.item])

    const handleOpen = async () => {
        setOpen(true)
    }

    const handleClose = async () => {
        setOpen(false)
    }

    const Row = ({ item }) => {

        return (
            <div style={{
                backgroundColor: "#f7f6f2",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 20px",
                borderRadius: "5px",
                border: item.date_invalid ? "1px solid red" : "1px solid #eee",
                transition: "all 0.2s ease",
                marginBottom: "5px",
                gap: '25px',
            }}>
                <span  style={{
                    width: '70px'
                }}>
                    <FormWrapper label="Nytt Antal">
                        {item.new_amount}
                    </FormWrapper>  
                </span>

                <span></span>

                <FormWrapper label="Tinat Antal">
                    <span style={{
                      color: 'blue',
                      fontSize: '16px',
                      width: '70px'
                  }}>
                        -{item.reduce_amount}
                    </span>
                </FormWrapper>

                <span></span>

                <span  style={{
                    width: '100px'
                }}>
                    <FormWrapper label="Tinad av">
                        {item.signature}
                    </FormWrapper>
                </span>
                
                <FormWrapper label="Tinad">
                    <span  style={{
                        width: '100px'
                    }}>
                        {item.date}
                    </span>
                </FormWrapper>

                {
                    item.date_invalid &&
                    <FormWrapper label="Datum fel">
                        <Checkbox   
                            name="date_invalid"
                            checked={item.date_invalid}
                            value={item.date_invalid}
                            onChange={() => {
                                const _object = { ...object }
                                _object.history = _object.history.map(h => {
                                    if (h.date === item.date) {
                                        h.date_invalid = !h.date_invalid
                                    }
                                    return h
                                })
                                setObject(_object)
                            }}
                        />
                    </FormWrapper>
                }
            </div>
        )
    }

    const FirstRow = () => {

        return (
            <div style={{
                backgroundColor: "#f7f6f2",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "1px solid #eee",
                transition: "all 0.2s ease",
                marginBottom: "5px",
                gap: '25px',
            }}>
                <span  style={{
                    width: '70px'
                }}>
                    <FormWrapper label="Nytt Antal">
                        {object.original_amount}
                    </FormWrapper>
                </span>

                <span></span>

                <FormWrapper label="Infryst Antal">
                    <span style={{
                      color: 'green',
                      marginLeft: '0px',
                      fontSize: '16px',
                      width: '70px'
                  }}>
                        +{object.original_amount}
                    </span>
                </FormWrapper>

                <div className='spacer-5' />
                  

                <span  style={{
                    width: '100px'
                }}>
                    <FormWrapper label="Infryst av">
                    {object.frozen_by ? object.frozen_by : 'Okänd'}
                    </FormWrapper>
                </span>

                <FormWrapper label="Fryst">
                    <span  style={{
                        width: '100px'
                    }}>
                        {object.frozen_date}
                    </span>
                </FormWrapper>
            </div>
        )
    }

    return (
        <div>
            <Button color="secondary" variant="contained" onClick={handleOpen}>Historik</Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Historik</DialogTitle>

                {
                    object && 
                    <DialogContent>
                        <div style={{
                            // backgroundColor: "#f7f6f2",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            border: "1px solid #eee",
                            marginBottom: "10px",
                            gap: '10px',
                        }}>
                            <div style={{
                                width: "220px",
                            }}>
                                <FormWrapper label="Namn">
                                    {object.name}
                                </FormWrapper>
                            </div>

                            <div style={{
                                width: "80px",
                            }}>
                                <FormWrapper label="Enhet">
                                    {object.unit}
                                </FormWrapper>
                            </div>
                                    
                            <span  style={{
                                width: '100px'
                            }}> 
                                <FormWrapper label="Tillverkad">
                                    {object.dipped_date}
                                </FormWrapper>
                            </span>

                            <span  style={{
                                width: '100px'
                            }}>
                                <FormWrapper label="Fryst">
                                    {object.frozen_date}
                                </FormWrapper>
                            </span> 
                        </div>
                        
                        {object.history.map((item, index) => (
                            <Row item={item} key={index} />
                        ))}
                        <FirstRow />
                    </DialogContent>
                }

                <DialogActions className="CreateOrderDialog__buttons">
                    <Button onClick={handleClose}>Stäng</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
