import React from 'react';

import { Button } from '../../ui/aui';
import DotMenu from '../../ui/DotMenu';
import { Routes } from '../../../api/routes';

export default function List() {
    const [data, setData] = React.useState([])

    const getData = async () => {
        const res = await Routes.Read('absence')

        // filter out dateTo that are in the past
        const filtered = res.filter(item => {
            const dateTo = new Date(item.dateTo)
            const now = new Date()
            return dateTo > now
        })

        // sort by latest
        filtered.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
        })

        setData(filtered)
    }

    React.useEffect(() => {
        getData()
    }, [])

    // run get data every 5 minutes
    React.useEffect(() => {
        const interval = setInterval(() => {
            getData()
        }, 1000 * 60 * 5);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const handleDelete = async (item) => {
        if(!window.confirm(`Är du säker på att du vill radera ${item.name}s frånvaro?`)) return
        const res = await Routes.Delete(item, 'absence')
        if(res.length === 0) alert('Raderingen misslyckades') 

        getData()
    }

    return (
        <div className="aui">
            <div className='paper'>
                <h2 className='action'>
                    Frånvaro
                    <Button color="primary" variant="text" onClick={() => window.location.replace("https://arecms.se/anmal_franvaro")}>Anmäl frånvaro</Button>
                </h2>

                <table>
                    <thead>
                        <tr>
                            <th>Namn</th>
                            <th>Orsak</th>
                            <th>Från</th>
                            <th>Till</th>
                            <th>Extra information</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.name}</td>
                                    <td>{item.cause}</td>
                                    <td>{item.dateFrom}</td>
                                    <td>{item.dateTo}</td>
                                    <td>{item.extra}</td>
                                    <DotMenu 
                                        adminOnly
                                        items={[
                                            {
                                                name: 'Radera',
                                                onClick: () => handleDelete(item)
                                            }
                                        ]}
                                    />
                                </tr>
                            ))
                        } 
                        {
                            data.length === 0 &&
                            <tr>
                                <td colSpan="5">Inga frånvaroanmälningar</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
         </div>
    )
}