import React from 'react'
import { useAuth } from './AuthContext'

export default function WithUseAuth(Component) {
  return function WrappedComponent(props) {
    const myHookValue = useAuth();
    return <Component {...props} useAuth={myHookValue} />;
  }
}


