import React, { useState, useEffect } from 'react'
import { Dipps, weekNumbers, currentWeekPrev } from '../../api/routes';
import FormWrapper from '../components/FormWrapper/FormWrapper';
import './styles.css'

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect'
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

const weekDays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag', 'Välj Dag']


export default function CreateDippDialog(props) {
    const [open, setOpen] = useState(false);
    const [newDipp, setNewDipp] = useState(true)

    const weeks = weekNumbers()

    const genDipp = () => {
        const {order} = props
        const _dipp = {
            smak: order.smak, 
            subSmak: "",
            subSmaker: order.subSmaker,    
            sort: order.sort, 
            type: order.type ? order.type : "Singel", 
            color: "Vit",
            back: 0,
            plat: 0,
            strut: 0,
            countOff: [],
            dag: "Okej", kommentar: "", 
            orderID: order.id, 
            done: false, 
            toPralinrum: false,
            backaResten: false, backadLjus: false, backadMork: false,
            prio: 1, 
            week: currentWeekPrev(),
            year: new Date().getFullYear(),
        }
        return _dipp
    }

    const [dipp, setDipp] = useState(genDipp())

    useEffect(() => {
        checkIfExist()  
    }, [])
    useEffect(() => {
      checkIfExist()  
    }, [props.renderButtons])

    useEffect(() => {
        setDipp(genDipp())
    }, [props.order])

    const handleClickOpen = async () => {
        if (props.isAdmin()) {
          checkIfExist()
          setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleUpdate = (name, value) => {
        const _dipp = { ...dipp }
        _dipp[name] = value
        setDipp(_dipp)
    }
    const handleUpdateDay = e => {
        const _dipp = { ...dipp }
        if(_dipp.day_index !== 7) {
            _dipp.day_index = e.target.value
            _dipp.dag = weekDays[e.target.value]
        } else {
            _dipp.day_index = 0
            _dipp.dag = weekDays[0]
        }
        setDipp(_dipp)
    }
    const handleUpdateArray = (arr) => {
        const _dipp = { ...dipp }
        arr.map(item => _dipp[item.name] = item.value)
        setDipp(_dipp)
    }
    const handleUpdateEvent = e => {
        const { name, value } = e.target
        const _dipp = { ...dipp }
        _dipp[name] = value
        setDipp(_dipp)
    }
    const handleDelete = async () => {
        if (window.confirm('Är du säker att du vill radera denna?')) {
          setNewDipp(true)
          handleClose()
          handleUpdate('dag', 'Okej')
          await Dipps.Delete(dipp)
          await props.getData()
        };
      }
    const handleCreate = async () => {
        const _dipp = { ...dipp }
        _dipp.dag = weekDays[_dipp.day_index]
        
        handleClose()
        if (newDipp) await Dipps.Create(_dipp)
        else await Dipps.Update(_dipp)
    }
    
    const checkIfExist = async () => {
        const arr = await Dipps.ReadByOrderID(props.order.id)
        
        if (arr.length > 0) {
          // filtrerar ut rätt vecka och ej färdigställda samt sorterar på dag
          const _arr = arr.filter(item => item.week == currentWeekPrev() && !item.done)
          _arr.sort(function(a, b) { return a.day_index - b.day_index })
    
          const latest = _arr[0]
          if(latest) {
            const _dipp = {...dipp}
            _dipp.dag = latest.dag
            setDipp(_dipp)
          }
        }
    }

    const isBland = props.order.type == 'Bland'
    const isBudskap = () => dipp.type == 'Budskap'

    const buttonStyle = dipp.dag == 'Okej' ? '#04a466' : '#2980b9'

    const underSmakSelect = <FormControl variant="standard">
        <InputLabel id="demo-simple-select-label">Undersmak</InputLabel>
        <NativeSelect
        value={dipp.subSmak}
        name="subSmak"
        onChange={handleUpdateEvent}
        inputProps={{ name: 'subSmak', id: 'uncontrolled-native', }}
        >{props.order.subSmaker.filter(item => item !== '').map(item => <option key={item} value={item}>{item}</option>)}
        </NativeSelect>
    </FormControl>

    const mapSwitches = () => {
      const mapSwitch = (label, name) => (
        <FormWrapper label={label}>
          <Switch
            checked={dipp[name]}
            name={name}
            onChange={e => handleUpdate(e.target.name, e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        </FormWrapper>
      )
      if(!isBudskap()) return mapSwitch("Till Pralinrum", "toPralinrum")
      return (
        <div className="flex-center">
          {mapSwitch("Backa Resten", "backaResten")}
          <div className="spacer-15" />
          {mapSwitch("Backad Ljus", "backadLjus")}
          <div className="spacer-10" />
          {mapSwitch("Backad Mörk", "backadMork")}
        </div>
      )
    }
    const mapSelect = (label, _name, array) => {
      const _value = dipp[_name]
      const getAllt = item => {
        if(!Number.isInteger(item)) return item
        return item
      }
      return <FormControl variant="standard">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <NativeSelect
          name="mapSelect"
          value={_value ? _value : array[0]}
          onChange={e => handleUpdate(_name, e.target.value)}
          inputProps={{ name: {_name}, id: 'uncontrolled-native', }}
        >{array.map(item => <option key={item} value={item}>{getAllt(item)}</option>)}
        </NativeSelect>
      </FormControl>
    }

  return (
    <div>
    <FormWrapper label="Doppning">
        <div className="CreateCookDialog__button" onClick={handleClickOpen} style={{ backgroundColor: buttonStyle }}>
            {dipp.dag}
        </div>
    </FormWrapper>
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Planera en Doppning</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <div className="flex">
            <TextField
              id="standard-basic" label="Smak" variant="standard"
              onChange={handleUpdateEvent}
              value={dipp.smak}
              name="smak"
              InputProps={{ readOnly: true, }}
            />
            {isBland && <div className="spacer-5" />}
            {isBland && underSmakSelect}

            {
              [
                ["Typ", "sort", ['Sort', 'Tryffel', 'Nougat', 'Kola', 'Flera']],
                ["Singel/Budskap", "type", ["Singel", "Budskap"]],
                ["Doppas i", "color", ['Vit', 'Ljus', 'Mörk', 'Flera']],
                ["Back", "back", [...Array(30).keys()]],
                ["Plåt", "plat", [...Array(30).keys()]],
                ["Strut", "strut", [...Array(30).keys()]],
                ["Vecka", "week", weeks]
              ].map((item, index) => <React.Fragment key={index}>
                  <div className="spacer-5" />
                  {mapSelect(item[0], item[1], item[2])}
                </React.Fragment>
              )
            }

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Planerad Dag</InputLabel>
              <NativeSelect
                value={dipp.day_index}
                name="day_index"
                onChange={handleUpdateDay}
                inputProps={{ name: 'day_index', id: 'uncontrolled-native', }}
              >{[7, 0, 1, 2, 3, 4, 5, 6].map(item => <option key={item} value={item}>{weekDays[item]}</option>)}
              </NativeSelect>
            </FormControl>

            <div className="spacer-5" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">År</InputLabel>
              <NativeSelect
                value={dipp.year}
                name="year"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'year', id: 'uncontrolled-native', }}
              >{['2023', '2024'].map(item => <option key={item} value={item}>{item}</option>)}
              </NativeSelect>
            </FormControl>
          </div>

          <div className="spacer-5" />

          <div className="flex">
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Prio</InputLabel>
              <NativeSelect
                value={dipp.prio}
                name="prio"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'prio', id: 'uncontrolled-native', }}
              >{[[1, 'Låg'], [2, 'Medel'], [3, 'Hög']].map(item => <option key={item[0]} value={item[0]}>{item[1]}</option>)}
              </NativeSelect>
            </FormControl>
            <div className="spacer-5" />

            {mapSwitches()}
            <div className="spacer-10" />

            <TextField
              id="standard-basic" label="Kommentar" variant="standard"
              onChange={handleUpdateEvent}
              value={dipp.kommentar}
              name="kommentar"
              onChange={handleUpdateEvent}
              multiline
              maxrows={4}
            />
            <div className="spacer-5" />

          </div>
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
            {!newDipp && <Button color="secondary" onClick={handleDelete}>Radera</Button>}
          </div>
          <Button color="primary" onClick={handleCreate}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
