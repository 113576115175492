import React from 'react'
import { InputLabel, InputText } from '../../styledComponents'

export default function Select(props) {


  const input = (name, titel) => (
    <InputLabel>{titel}
      <InputText size="sm" as="select" name={name}
        value={props.values[name]} onChange={e => handleChange(e)}>
        {Array.from(Array(21).keys()).map(function (item, i) {
          return (
            <option key={i} value={item}>{item}</option>
          );
        })}
      </InputText>
    </InputLabel>
  )
  const inputDis = (name, titel) => (
    <InputLabel>{titel}
      <InputText size="sm" as="select" name={name} disabled
        value={props.values[name]} onChange={e => handleChange(e)}>
        {Array.from(Array(21).keys()).map(function (item, i) {
          return (
            <option key={i} value={item}>{item}</option>
          );
        })}
      </InputText>
    </InputLabel>
  )
  return (
    <div>
      {props.type === 'back' ? 
        <div className="flex">
          {input("back", "Antal")}
        </div>
        :
        <div className="flex">
          {input("askar", "Askar")}
          
          <div>&nbsp;&nbsp;</div>
          {props.stangerShow ? input("stanger", "Stänger") : inputDis("stanger", "Stänger")}
        </div>
      }
    </div>
  )

  function handleChange(e) {
    const { name, value } = e.target
    const newValues = { ...props.values }
    newValues[name] = parseInt(value)
    props.handleSelect(props.type, newValues)
  }
}









