import React, { useEffect, useState } from 'react'
import { Routes } from '../../api/routes';
import { FixedSizeList as List } from 'react-window';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormWrapper from '../components/FormWrapper/FormWrapper';

export default function FrysTinaHistory(props) {
    const [open, setOpen] = useState(false);

    const [frysHistory, setFrysHistory] = useState([]);

    const handleClickOpen = async () => {
        setOpen(true);
    };
    const handleClose = async () => {
        setOpen(false);
    };

    async function handleX(_history) {
      let newLogs = []
  
      const item = props.fry
  
      const newLogTemplate = {
          id: item.id,
          created_at: item.created_at,
          name: item.name,
          done: item.done,
          doppad: item.doppad,
          fryst: item.fryst,
          frystSign: item.frystSign,
          unit: item.unit,
      }
  
      let temp_amount = parseInt(item.ammount)
      
  
      item.history.map((h, i) => {
        const newLog = {...newLogTemplate}
        newLog.ammount_changed = parseInt(h.ammount) * -1
        newLog.ammount = temp_amount
        temp_amount = newLog.ammount + newLog.ammount_changed
        newLog.temp_sign = h.sign
        newLog.date = h.date
        newLogs.push(newLog)
      })
      
  
      // filter out any items from newLogs that are after the date '2023-04-05'
      newLogs = newLogs.filter((item) => {
          return new Date(item.date) <= new Date('2023-04-05')
      })
      
      // filter out any items from frysHistory that are before the date '2023-04-05'
      let _frysHistory = [..._history]
      _frysHistory = _frysHistory.filter((item) => {
        return new Date(item.date) >= new Date('2023-04-05')
      })

      // _frysHistory = frysHistory.map((item => {
      //   if (item.history.length > 0) item.temp_sign = item.history[0].sign
      //   return item
      // }))

      let newFrysHistory = [...newLogs, ..._frysHistory]
  
      // sort by date
      newFrysHistory.sort((a, b) => {
          return new Date(a.date) - new Date(b.date)
      }).reverse()
  
      console.log(newFrysHistory);
      setFrysHistory(newFrysHistory)
  
    // {date: '2023-02-27', sign: 'André ', ammount: '1'}
  
    // {
    // "id": 274,
    // "created_at": "2022-10-25T14:43:39.818094+00:00",
    // "name": "test",
    // "done": false,
    // "doppad": "2022-10-25",
    // "fryst": "2022-10-25",
    // "frystSign": "Malin R",
    // "ammount": "11",
    // "unit": "st",
    // "history": []
    // }
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await Routes.Read('frys_history');
            let history = []

            let last_amount = 0
            for (let i = 0; i < result.length; i++) {
                let parsed = JSON.parse(result[i].log)
                if (parsed.id === props.fry.id || parsed.name === props.fry.name) {
                    parsed.date = result[i].created_at.split('T')[0]

                    parsed.ammount_changed = parsed.ammount - last_amount

                    last_amount = parsed.ammount
                    history.push(parsed)
                } 
            }

            history = history.reverse()
            await setFrysHistory(history);
            await handleX(history)
        }
        fetchData();
    }, []);

  const Row = ({item, index}) => {
    if (!item.temp_sign && item.history.length > 0) item.temp_sign = item.history[0].sign
    else item.temp_sign = item.frystSign

    return (
        <div>
          <div className="FrysList__list__item"> 
            <div className="flex-col">
              <div className="flex">
                <div className="FrysDialog__item__FormWrapper">
                  <FormWrapper label="Namn">
                    {props.fry.name}
                  </FormWrapper>
                </div>

                <div className="spacer-10" />
    
                <FormWrapper label="Enhet">
                  {props.fry.unit}
                </FormWrapper>
    
                <div className="spacer-10" />
    
                <FormWrapper label="Tillverkad">
                  {props.fry.doppad}
                </FormWrapper>
    
                <div className="spacer-10" />
    
                <FormWrapper label="Fryst">
                  {props.fry.fryst}
                </FormWrapper>
    
                <div className="spacer-10" />
    
                <FormWrapper label="Nytt Antal" className={'fullWidth'}>
                {item.ammount}
                </FormWrapper>

                <div className="spacer-10" />
    
                <FormWrapper label="Förändring" className={'fullWidth'}>
                  {item.ammount_changed > 0 && <span style={{
                      color: 'green',
                      marginLeft: '0px',
                      fontSize: '16px',
                  }}> +{item.ammount_changed}</span>}
                  {item.ammount_changed < 0 && <span style={{
                      color: 'blue',
                      marginLeft: '0px',
                      fontSize: '16px',
                  }}> {item.ammount_changed}</span>}
                </FormWrapper>
  
                <div className="spacer-10" />
    
                <FormWrapper label="Uppdaterad">
                {item.date}
                </FormWrapper>
                
                <div className="spacer-10" />
    
                <FormWrapper label="Uppdaterad Av">
                {item.temp_sign || 'Saknas'}
                </FormWrapper>
              
                <div className="spacer-10" />
    
              </div>
            </div>
          </div>
    
          <div className="spacer-10" />
        </div>
      )
  }



    return (
        <div>
            <Button color="secondary" variant="contained" onClick={handleClickOpen}>Historik Total (ny)</Button>

            <Dialog open={open} onClose={handleClose} maxWidth="xl">
                <DialogTitle>Historik</DialogTitle>
                <DialogContent className="CreateOrderDialog__form">
                    {frysHistory.length > 0 && frysHistory.map((item, index) => (
                        <Row item={item} key={index} index={index} />
                    ))}
                </DialogContent>
                <DialogActions className="CreateOrderDialog__buttons">
                <div className="flex-center">
                    <Button onClick={handleClose}>Stäng</Button>
                </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}

