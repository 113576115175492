import React from 'react';

import { Input, Button, Select, TextArea } from '../../ui/aui';
import { Routes } from '../../../api/routes';

export default function Create() {
    const [name, setName] = React.useState('')
    const [cause, setCause] = React.useState('sjukdom')
    const [extra, setExtra] = React.useState('')
    const [dateTo, setDateTo] = React.useState(() => {
        const date = new Date()
        date.setDate(date.getDate() + 1)
        return date.toISOString().split('T')[0] // tomorrow
    })
    const [error, setError] = React.useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            name,
            cause,
            extra,
            dateTo,
            dateFrom: new Date().toISOString().split('T')[0],
        }

        const res = await Routes.Create(data, 'absence')

        if(res.length == 0) {
            setError(true)
            return
        }

        alert('Anmälan skickad!')
        window.location.replace("https://arecms.se")
    }

    return (
        <div className="aui background">
            <form onSubmit={handleSubmit} className='paper'>
                <h2>Anmäl frånvaro</h2>
                <Input 
                    placeholder="För- och efternamn"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    required
                    error={error}
                    fullWidth
                />

                <Select
                    placeholder="Välj Orsak"
                    value={cause}
                    onChange={(e) => setCause(e.target.value)}
                    required
                    error={error}
                    fullWidth
                >
                    <option value="sjukdom">Sjukdom</option>
                    <option value="semester">Semester</option>
                    <option value="vab">Vård av barn</option>
                    <option value="annat">Annat</option>
                </Select>
                
                {
                    cause === 'annat' && 
                    <TextArea
                        placeholder="Extra information"
                        value={extra}
                        onChange={(e) => setExtra(e.target.value)}
                        required
                        error={error}
                        fullWidth
                    />
                }

                <Input
                    label="Åter datum"
                    type="date"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                    required
                    error={error}
                    fullWidth
                />
                
                <Button type="submit" color="primary" variant="contained" fullWidth>Skicka</Button>
            </form>
        </div>
    )
}