import React, { useState, useEffect } from 'react'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

export default function SelectPersons(props) {
    const [_props, setProps] = useState(null)
    const [name, setName] = useState("Namn")

    useEffect(() => {
        setProps(props)
    }, [props])

    const handleChange = (event) => {
        let { value } = event.target
        setName(value)
        if (value !== "Namn") props.callback(value)
    }

  return (
    <>
    {_props && _props.persons.length > 0 && <div style={{width: _props.width}}>
        <FormControl fullWidth={_props.width}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                {_props.label}
            </InputLabel>
            <NativeSelect
                value={name}
                onChange={handleChange}
                inputProps={{
                    name: _props.label,
                    id: 'uncontrolled-native',
                }}
                style={{border: _props.error ? "2px solid red" : '2px solid #eee'}}
            >   
                <option value="Namn">Namn</option>
                {
                    _props.persons.map(item => (
                        <option key={item.id} value={item.name}>{item.name}</option>
                    ))
                }
            </NativeSelect>
        </FormControl> 
    </div>} 
    </>
  )
}
