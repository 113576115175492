import React, { useState, useEffect } from 'react'
import WithUseAuth from '../auth/WithUseAuth';
import { SearchDocuments } from '../../api/routes';
import './styles.css'
import { FixedSizeList as List } from 'react-window';

import FormWrapper from '../components/FormWrapper/FormWrapper';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIconBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TrackingReceptView from '../tracking/TrackingReceptView';
import TrackingReceptDippCreate from '../tracking/TrackingReceptDippCreate'
import TrackingPackBland from './TrackingPackBland'

function TrackingFilter(props) {
    const { isAdmin } = props.useAuth;

    const [searchProps, setSearchProps] = useState({
        smak: "",
        ingrediens: "",
        information: "",
        datum: "",
        signatur: "",
    })

    const [result, setResult] = useState({})
    const [renderList, setRenderList] = useState(0)

    const handle = {
        search: async () => {
            SearchDocuments(searchProps).then((res) => {
                setResult(res)
                console.log(res)
            })
        },
        update: ({name, value}) => {
            const _searchProps = {...searchProps}
            _searchProps[name] = typeof value === 'string' ? value.toLowerCase() : value
            setSearchProps(_searchProps)
        },
        clear: async () => {
            await setSearchProps({
                smak: "",
                ingrediens: "",
                information: "",
                datum: "",
                signatur: "",
            })
            SearchDocuments({
                smak: "",
                ingrediens: "",
                information: "",
                datum: "",
                signatur: "",
            }).then((res) => {
                setResult(res)
            })
        }
    }

    useEffect(() => {
        handle.clear()
      }, [])

    const render = {
        row: ({ index, style }) => (
            <div style={style}>
                rad
            </div>
        ),
        example: () => (
            <List
            height={500}
            itemCount={result.length}
            itemSize={80}
            width={650}
            >
                {render.row}
            </List>
        ),
        input: {
            text: (label, name, value, style) => (
                <TextField
                    id="standard-basic" variant="standard"
                    label={label}
                    name={name}
                    value={value}
                    onChange={e => handle.update(e.target)}
                    style={style}
                />
            ),
            date: (label, name, value, style) => (
                <TextField
                    id="date"
                    variant="standard"
                    type="date"
                    label={label}
                    name={name}
                    value={value}
                    sx={{ width: 220 }}
                    onChange={e => handle.update(e.target)}
                    style={style}
                    InputLabelProps={{ shrink: true }}
                />
            ),
            switch: (label, name, value) => {
                const notValue = !value
                return <FormWrapper label={label}>
                    <Switch
                        name={name}
                        checked={value}
                        onChange={() => handle.update({name, value: notValue})}
                        color="primary"
                    />
                </FormWrapper>
            },
        }
    }



    const Row0 = ({ index, style }) => {

        if(result.cooks) return <div style={style}>
            <div className="TrackingFilter__row">
                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Typ" >
                        Kokning
                    </FormWrapper>
                </div>
                <div className="spacer-10" />

                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Smak">
                        {result.cooks[index].cook.smak}
                    </FormWrapper>
                </div>
                <div className="spacer-10" />

                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Kokningsdatum" >
                        {result.cooks[index].date}
                    </FormWrapper>
                </div>
                <div className="spacer-10" />
                
                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Signatur" >
                        {result.cooks[index].sign}
                    </FormWrapper>
                </div>    
                
                <TrackingReceptView item={result.cooks[index]} cook={result.cooks[index].cook} getData={() => false} disableDelete={true} />               
            </div>

        </div>
        return <></>
    }
    
    const Example0 = () => (
        <List
            height={800}
            itemCount={result.cooks ? result.cooks.length : 1}
            itemSize={70}
            width={800}
        >
            {Row0}
        </List>
    );


    
    const Row2 = ({ index, style }) => {
        
        if(result.dipps) return <div style={style}>
            <div className="TrackingFilter__row">
                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Typ" >
                        Tillverkning
                    </FormWrapper>
                </div>
                <div className="spacer-10" />

                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Smak">
                        {result.dipps[index].dipp.smak}
                    </FormWrapper>
                </div>
                <div className="spacer-10" />  

                <div className="TrackingFilter__row__item" style={{width:"auto", minWidth:"200px"}}>
                    <FormWrapper label="Tillverkningsdatum">
                        <div className="flex">
                            {result.dipps[index].date.map((d, i) => `${d}${i == 2 ? "":" -"} `)}
                        </div>
                    </FormWrapper>
                </div>
                <div className="spacer-10" />

                <div className="TrackingFilter__row__item" style={{width:"auto", minWidth:"100px"}}>
                    <FormWrapper label="Signatur" >
                        <div className="flex">
                            {result.dipps[index].sign.map((d, i) => `${d}${i == 2 ? "":","} `)}
                        </div>
                    </FormWrapper>
                </div>     

                <div className="spacer-10" />
                <div className='flex'>
                    <TrackingReceptDippCreate 
                      handleClose={() => false} 
                      dipp={result.dipps[index].dipp} 
                      getDippData={() => false} 
                      getTrackingReceptData={() => false} 
                      TRDipp={result.dipps[index]}
                      justView={true}
                    />
                </div>
            </div>

        </div>
        return <></>
    }
    
    const Example1 = () => (
        <List
            height={800}
            itemCount={result.dipps ? result.dipps.length : 1}
            itemSize={70}
            width={900}
        >
            {Row2}
        </List>
    );




    return (
        <div className="flex">


            <div>
                <div className="flex">
                    <div className="spacer-5"></div>
                    <div className="Planner__h1">Sök efter dokument</div>
                    
                </div>
                <div className="TrackingFilter__header">
                    <div className="flex">
                        {render.input.date("Datum", "datum", searchProps.datum)}
                        <div className="spacer-10" />
                        {render.input.text("Smak", "smak", searchProps.smak)}
                    </div>
                    <div className="spacer-10" />
                    <div className="flex">
                        {render.input.text("Ingrediens", "ingrediens", searchProps.ingrediens)}
                        <div className="spacer-10" />
                        {render.input.text("Information", "information", searchProps.information)}
                        <div className="spacer-10" />
                        {render.input.text("Signatur", "signatur", searchProps.signatur)}
                    </div>
                    <div className="spacer-20" />

                    <div className="spacer-20" />
                    <div className="flex">
                        <Button onClick={handle.search} variant="contained" color="primary">Sök dokument</Button>
                        <div className="spacer-10" />
                        <Button onClick={handle.clear} variant="contained" color="secondary">rensa fält</Button>
                    </div>
                </div>
                <div className="TrackingFilter__menu">
                    <Button onClick={() => setRenderList(0)} variant="outlined" color="primary">visa kokning</Button>
                    <div className="spacer-10" />
                    <Button onClick={() => setRenderList(1)} variant="outlined" color="primary">visa tillverkning</Button>
                </div>
                <div className="TrackingFilter__list">
                    {renderList == 0 && <Example0 />}
                    {renderList == 1 && <Example1 />}
                </div>
            </div>

        </div>
    )
}

export default WithUseAuth(TrackingFilter);


function CooksDialog(props) {
    const {cook, recept, steps, date, sign, nrPlates} = props
   

    const renderPart = (item, i) => {
        return <div key={i}>
          <div className="flex">
            <TextField
              id="standard-basic" label="Mått" variant="standard"
              value={recept.measures[i]}
              name="measures"
              style={{ width: "100px" }}
              InputProps={{ readOnly: true, }}
            />
            <div className="spacer-5" />
    
            <TextField
              id="standard-basic" label="Ingrediens" variant="standard"
              value={item.name}
              name="ingredienser"
              InputProps={{ readOnly: true, }}
            />
            <div className="spacer-5" />
    
            <TextField
              id="standard-basic" label="Information" variant="standard"
              value={item.info}
              name="info"
              style={{ width: "220px", }}
            />
            <div className='spacer-5' />
    
            <IconButton aria-label="delete">
              {item.done ? <CheckBoxIcon /> : <CheckBoxIconBlank />}
            </IconButton>
          </div>
          <div className="spacer-5" />
        </div>
      }

    return (
        <div>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>Recept och spårbarhet</DialogTitle>
                <DialogContent className="CreateOrderDialog__form">
                <div className="Cook__Wrapper">
                    <div className="Cook__Overlay" />
                    <div className="flex-col">

                    {recept && <Recept recept={recept} />}

                    <div className="flex-col">
                        {steps && steps.map((item, i) => renderPart(item, i))}
                    </div>
                    <div className="spacer-10" />

                    <div className="flex">
                        <TextField
                        id="date"
                        label="Kokningsdatum"
                        type="date"
                        name="date"
                        variant="standard"
                        value={date}
                        sx={{ width: 220 }}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "145px" }}
                        />
                        <div className="spacer-5" />

                        <TextField
                        id="standard-basic" label="Signatur" variant="standard"
                        value={sign}
                        name="sign"
                        />
                        <div className="spacer-5" />

                        <TextField
                        id="standard-basic" label="Antal Plåtar" variant="standard" type="number"
                        value={nrPlates}
                        name="nrPlates"
                        style={{ width: "100px" }}
                        />
                        <div className="spacer-5" />
                    </div>

                    </div>
                </div>
                </DialogContent>
                <DialogActions className="CreateOrderDialog__buttons">
                <div className="flex-center">
                    <Button onClick={props.handleClose}>Stäng</Button>
                </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}

function Recept({ recept }) {

    return (
      <div>
        <TextField
          id="standard-basic" label="Namn" variant="standard"
          value={recept.namn}
          name="namn"
          InputProps={{ readOnly: true, }}
        />
        <div className="spacer-5" />
        <TextField
          id="standard-basic" label="Skapare" variant="standard"
          value={recept.author}
          name="author"
          InputProps={{ readOnly: true, }}
        />
        <div className="spacer-5" />
        <TextField
          id="standard-basic" label="Instruktion" variant="standard"
          value={recept.instruktion}
          name="instruktion"
          multiline
          style={{ minWidth: "50vw", }}
          InputProps={{ readOnly: true, }}
        />
  
        <div className="spacer-20" />
      </div>
    )
  }