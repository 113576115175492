import React from 'react'
import Select from './Select'
import SelectDis from './SelectDis'

export default function Smak(props) {
  const style = {
    wrapper: {
      alignItems: "center",
      justifyContent: "space-between",
      background: "#F7F6F2", padding: "5px 10px",
      marginBottom: "5px",
      border: "1px solid #eee", borderRadius: "8px",

      title: { marginRight: "30px" },
    },
    selectWrapper: {

      title: { marginRight: "20px" },
    },
    selectInner: {
      background: "white",
      border: "1px solid #eee",
      borderRadius: "5px",
      padding: "5px 10px",
      marginLeft: "5px",
    },
    selectInnerDis: {
      background: "#f8f8f8",
      border: "1px solid #eee",
      borderRadius: "5px",
      padding: "5px 10px",
      marginLeft: "5px",
    }
  }

  const name = <p style={style.wrapper.title}>{props.child.name}</p>

  const selectWrapper = (
    <div className="flex-center" style={style.selectWrapper}>
      <div className="flex-center" style={style.selectInner}>
        <p style={style.selectWrapper.title}>BACK</p>
        <Select type={"back"} values={props.child.back} handleSelect={handleSelect} />
      </div>
      <div className="flex-center" style={style.selectInner}>
        <p style={style.selectWrapper.title}>PLÅT</p>
        <Select type={"plat"} values={props.child.plat} stangerShow={props.child.stangerShow} handleSelect={handleSelect} />
      </div>
    </div>
  )

  const selectDis = (
    <div className="flex-center" style={style.selectWrapper}>
      <div className="flex-center" style={style.selectInnerDis}>
        <p style={style.selectWrapper.title}>BACK</p>
        <SelectDis type={"back"} values={props.child.back}/>
      </div>
      <div className="flex-center" style={style.selectInnerDis}>
        <p style={style.selectWrapper.title}>PLÅT</p>
        <SelectDis type={"plat"} values={props.child.plat}/>
      </div>
    </div>
  )

  return (
    <div style={style.wrapper} className="flex">
      {name} 
      {props.disabled ? selectDis : selectWrapper}
    </div>
  )

  function handleSelect(type, values) {
    const newChild = { ...props.child }
    newChild[type] = values
    props.updateChild(newChild, props.index)

    const update = {name: props.sort.name, subname: newChild.name, type: type, values: values}
    props.handleToUpdate(update)
  }
}
