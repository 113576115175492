import React, { useEffect, useState } from 'react'
import { Recepts, Ingredienser, Tracking_Recept, getDate } from '../../api/routes';
import WithUseAuth from '../auth/WithUseAuth';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIconBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function TrackingReceptCreate(props) {
  const { isAdmin } = props.useAuth;
  const [open, setOpen] = useState(false);

  const [recept, setRecept] = useState(false);
  const [cook, setCook] = useState(false);
  const [tr, setTR] = useState(false)
  const [showError, setShowError] = useState(false)


  const getData = async () => {
    const _cook = { ...props.cook }
    setCook(_cook)

    const __recept = await Recepts.ReadById(_cook.recept_id)
    const _recept = __recept[0]
    setRecept(_recept);

    const recept_parts = await _recept.ingredienser
    const parts = await Ingredienser.Read()

    const findPart = name => parts.find(item => item.name == name);
    const steps = recept_parts.map(name => {
      const _name = name.toLowerCase()
      if (_name !== '') {
        const _part = findPart(_name)
        if (_part) {
          const { id, created_at, ...part } = _part
          part.done = false
          return part
        }
        return { name: _name, info: "", done: false, new: true }
      }
    }).filter(x => x !== undefined);

    const __cook = JSON.stringify(_cook)
    const ___recept = JSON.stringify(_recept)

    let todaysDate = getDate()

    const _tr = {
      cook: __cook,
      recept: ___recept,
      steps,
      sign: "",
      nrPlates: 0,
      date: todaysDate,
      cook_id: _cook.id
    }

    setTR(_tr)
  }

  useEffect(() => {
    getData()
  }, [])

  const handleOpen = async () => {
    setOpen(true);
    await props.getData()
  };
  const handleClose = async () => {
    setOpen(false);
  };
  const handleSave = async () => {
    const rq = tr.sign !== "" && tr.nrPlates > 0
    if (rq) {
      handleClose()
      // props.handleClose()

      const parts = tr.steps.map(step => {
        const { done, ...part } = step
        return part
      })
      const _create = parts.filter(part => part.new == true)
      const _update = parts.filter(part => part.new == false)

      console.log(_create)
      console.log(_update)

      if (_create.length > 0) await Ingredienser.CreateMany(_create)
      if (_update.length > 0) await Ingredienser.Upsert(_update)

      await Tracking_Recept.Create(tr)

      await props.getData()
    } else setShowError(true)

  };

  const handleUpdateEvent = async (e) => {
    const { name, value } = e.target
    const _tr = { ...tr }
    _tr[name] = value
    setTR(_tr)
  }
  const handleUpdateSteps = async (e, index) => {
    const { name, value } = e.target
    const _tr = { ...tr }
    _tr.steps[index][name] = value
    setTR(_tr)
  }
  const handleShowMark = async (index) => {
    const _tr = { ...tr }
    _tr.steps[index].done = !_tr.steps[index].done
    setTR(_tr)
  }


  const renderPart = (item, i) => {
    return <div key={i}>
      <div className="flex">
        <TextField
          id="standard-basic" label="Mått" variant="standard"
          value={recept.measures[i]}
          name="measures"
          style={{ width: "100px" }}
          InputProps={{ readOnly: true, }}
        />
        <div className="spacer-5" />

        <TextField
          id="standard-basic" label="Ingrediens" variant="standard"
          value={item.name}
          name="ingredienser"
          InputProps={{ readOnly: true, }}
        />
        <div className="spacer-5" />

        <TextField
          id="standard-basic" label="Information" variant="standard"
          onChange={(e) => handleUpdateSteps(e, i)}
          value={item.info}
          name="info"
          style={{ width: "220px", }}
        />
        <div className='spacer-5' />

        <IconButton aria-label="delete" onClick={() => handleShowMark(i)}>
          {item.done ? <CheckBoxIcon /> : <CheckBoxIconBlank />}
        </IconButton>
      </div>
      <div className="spacer-5" />
    </div>
  }

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="primary">Lägg Till +</Button>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Recept och spårbarhet</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <Recept recept={recept} />

          <div className="flex-col">
            {tr && tr.steps.map((item, i) => renderPart(item, i))}
          </div>
          <div className="spacer-10" />

          <div className="flex">
            <TextField
              id="date"
              label="Kokningsdatum"
              type="date"
              name="date"
              variant="standard"
              value={tr.date}
              sx={{ width: 220 }}
              InputLabelProps={{ shrink: true }}
              onChange={handleUpdateEvent}
              style={{ width: "145px" }}
              error={showError}
            />
            <div className="spacer-5" />

            <TextField
              id="standard-basic" label="Signatur" variant="standard"
              onChange={handleUpdateEvent}
              value={tr.sign}
              name="sign"
              error={showError}
            />
            <div className="spacer-5" />

            <TextField
              id="standard-basic" label="Antal Plåtar" min={0} variant="standard" type="number"
              onChange={handleUpdateEvent}
              value={tr.nrPlates}
              name="nrPlates"
              style={{ width: "100px" }}
              error={showError}
            />
            <div className="spacer-5" />
          </div>

        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
          </div>
          <Button color="primary" onClick={handleSave}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default WithUseAuth(TrackingReceptCreate);

function Recept({ recept }) {


  return (
    <div>
      <TextField
        id="standard-basic" label="Namn" variant="standard"
        value={recept.namn}
        name="namn"
        InputProps={{ readOnly: true, }}
      />
      <div className="spacer-5" />
      <TextField
        id="standard-basic" label="Skapare" variant="standard"
        value={recept.author}
        name="author"
        InputProps={{ readOnly: true, }}
      />
      <div className="spacer-5" />
      <TextField
        id="standard-basic" label="Instruktion" variant="standard"
        value={recept.instruktion}
        name="instruktion"
        multiline
        style={{ minWidth: "50vw", }}
        InputProps={{ readOnly: true, }}
      />

      <div className="spacer-20" />
    </div>
  )
}