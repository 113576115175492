import React from 'react'
import { FixedSizeList as List } from 'react-window'

import WithUseAuth from '../../../pages/auth/WithUseAuth'
import { Routes } from '../../../api/routes'

import './styles.css'

import TrackingReceptView from '../../../pages/tracking/TrackingReceptView'
import TrackingReceptDippCreate from '../../../pages/tracking/TrackingReceptDippCreate'

import FormWrapper from '../../../pages/components/FormWrapper/FormWrapper'
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';


function Tracking2(props) {
    
    const [data, setData] = React.useState([])
    const [filteredData, setFilteredData] = React.useState([])
    const [filter, setFilter] = React.useState({
        smak: "",
        date: "",
        sign: "",
        ingredient: "",
        information: "",
    })
    
    const getData = async () => {
        // fetch data
        let tracking_cooks = await Routes.Read('tracking_recept')
        let tracking_dipps = await Routes.Read('tracking_recept_dipp')
        
        // fetch dipps to update tracking_dipps with current data
        let dipps = await Routes.Read('dipps')
        
        // update tracking_dipps with current data
        tracking_dipps = tracking_dipps.map(item => {
            const _item = {...item}
            const new_dipp = dipps.find(d => d.id === item.dipp_id)
            if (new_dipp) _item.dipp = new_dipp
            return _item
        })

        // parse json for tracking_cooks
        tracking_cooks = tracking_cooks.map(item => {
            const _item = {...item}
            _item.cook = JSON.parse(_item.cook)
            _item.cook.done = true
            _item.recept = JSON.parse(_item.recept)
            return _item
        })

        // filter out tracking_dipps that are done
        tracking_dipps = tracking_dipps.filter(item => item.dipp.done)

        // add together tracking_cooks and tracking_dipps
        let documents = tracking_cooks.concat(tracking_dipps)

        // sort by date
        documents.sort((a, b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))

        // lift sub key "smak" to top level for easier filtering
        documents = documents.map(item => {
            const _item = {...item}
            _item.smak = ""

            if (_item.cook) _item.smak = _item.cook.smak
            else if (_item.dipp) _item.smak = _item.dipp.smak

            return _item
        })

        console.log("documents", documents)
        
        setData(documents)
        setFilteredData(documents)

        setFilter({smak: "", date: "", sign: "", ingredient: "", information: ""})
    }
    React.useEffect(() => {
        getData()
    }, [])

    const Row = ({ index, style }) => {
        const item = filteredData[index]

        let document_type = ""
        if (item.cook) document_type = "Kokning"
        else if (item.dipp) document_type = "Tillverkning"

        return (
            <div style={style} className="TrackingFilter__row">
                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Dokument" >
                        {document_type}
                    </FormWrapper>
                </div>
                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Smak" >
                        { item.smak }
                    </FormWrapper>
            </div>
                <div className="TrackingFilter__row__item">
                    <FormWrapper label={document_type === "Kokning" ? "Kokdatum" : "Tillverkningsdatum"} >
                        { Array.isArray(item.date) ? item.date.map(d => d + " ") : item.date }
                    </FormWrapper>
                </div>
                <div className="TrackingFilter__row__item">
                    <FormWrapper label="Signatur" >
                        { Array.isArray(item.sign) ? item.sign.map(d => d + " ") : item.sign }
                    </FormWrapper>
                </div>

                { item.cook && <TrackingReceptView item={item} cook={item.cook} getData={() => false} disableDelete={true} /> }   
                { item.dipp &&  
                    <TrackingReceptDippCreate 
                        handleClose={() => false} 
                        dipp={item.dipp} 
                        getDippData={() => false} 
                        getTrackingReceptData={() => false} 
                        TRDipp={item}
                        justView={true}
                    /> 
                } 
            </div>
        )
    }
    const ListItems = () => (
        <List
            height={700}
            itemCount={filteredData.length}
            itemSize={60}
            width={900}
        >
            {Row}
        </List>
    )

    const search = () => {
        const _filter = {...filter}

        // to lower case
        _filter.smak = _filter.smak.toLowerCase()
        _filter.sign = _filter.sign.toLowerCase()
        _filter.ingredient = _filter.ingredient.toLowerCase()
        _filter.information = _filter.information.toLowerCase()
        
        // filter
        let res = data.filter(item => {
            const  _item = {...item} 
            _item.smak = _item.smak.toLowerCase()
            _item.sign = Array.isArray(_item.sign) ? _item.sign.join('').toLowerCase() : _item.sign.toLowerCase()
            _item.date = Array.isArray(_item.date) ? _item.date.join('') : _item.date
            _item.ingredients = []
            _item.information = []

            if (_item.cook) {
                _item.steps.map(step => {
                    _item.ingredients = _item.ingredients.concat(step.name)
                    _item.information = _item.information.concat(step.info)
                })
            } else if (_item.dipp) {
                _item.trackingRecept.steps.map(step => {
                    _item.ingredients = _item.ingredients.concat(step.name)
                    _item.information = _item.information.concat(step.info)
                })
            }

            _item.ingredients = _item.ingredients.join('').toLowerCase()
            _item.information = _item.information.join('').toLowerCase()

            let match = true
            if (_filter.smak !== "") match = match && _item.smak.includes(_filter.smak)
            if (_filter.sign !== "") match = match && _item.sign.includes(_filter.sign)
            if (_filter.date !== "") match = match && _item.date.includes(_filter.date)
            if (_filter.ingredient !== "") match = match && _item.ingredients.includes(_filter.ingredient)
            if (_filter.information !== "") match = match && _item.information.includes(_filter.information)

            return match
        })

        setFilteredData(res)
    }

    return (
        <div className="flex-col-center g-10">
            <div className="flex-col-center g-10">
                <div className="flex-center g-10">
                    <h2 className="Ordersedel__h2">Sök efter dokument</h2>
                    <Button variant="outlined" color="primary" onClick={search}>Sök</Button>
                    <Button variant="outlined" color="secondary" onClick={() => {
                        setFilter({smak: "", date: "", sign: "", ingredient: "", information: ""})
                        search()
                    }}>Rensa Filter</Button>
                </div>
                <div className="flex-center g-10">
                    <TextField
                        label="Datum"
                        type="date"
                        value={filter.date}
                        onChange={e => setFilter({...filter, date: e.target.value})}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Smak"
                        variant="outlined"
                        value={filter.smak}
                        onChange={e => setFilter({...filter, smak: e.target.value})}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Signatur"
                        variant="outlined"
                        value={filter.sign}
                        onChange={e => setFilter({...filter, sign: e.target.value})}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Ingrediens"
                        variant="outlined"
                        value={filter.ingredient}
                        onChange={e => setFilter({...filter, ingredient: e.target.value})}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Information"
                        variant="outlined"
                        value={filter.information}
                        onChange={e => setFilter({...filter, information: e.target.value})}
                    />
                </div>
            </div>
            <ListItems />
        </div>
    )
}

export default WithUseAuth(Tracking2)
