export const handleLocalStorage = {
    get: (key) => {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value
    },
    set: (key, value, hours=24) => {
        const now = new Date()

        const item = {
            value: value,
            expiry: now.getTime() + hours * 36000,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }
}
export const handleDates = {
    today: {
        getDate: () => {
            const date = new Date()
            const getZero = item => String(item).length < 2 ? `0${item}` : item
            const year = date.getFullYear() + '-' + getZero(date.getMonth() + 1) + '-' + getZero(date.getDate())
            return year
        }
    }
}

export const objectHandle = ({object, setObject, Routes, table}) => {
    return {
        create: async () => {
            let res = await Routes.Create(object, table)
            return res
        },
        update: async () => {
            let res = await Routes.Update(object, table)
            return res
        },
        delete: async () => {
            if (window.confirm('Är du säker på att du vill ta bort denna?')) {
                let res = await Routes.Delete(object, table)
                return res
            }
        },
        setKey: (key, value) => {
            setObject({...object, [key]: value})
        },
        setKeyIndex: (key, index, value) => {
            let arr = object[key]
            arr[index] = value
            setObject({...object, [key]: arr})
        },
        setSubkey: (key, subkey, value) => {
            setObject({...object, [key]: {...object[key], [subkey]: value}})
        },
        setSubkeyIndex: (key, subkey, index, value) => {
            let arr = object[key][subkey]
            arr[index] = value
            setObject({...object, [key]: {...object[key], [subkey]: arr}})
        },
        setEvent: (event) => {
            setObject({...object, [event.target.name]: event.target.value})
        },
        setEventIndex: (event, index) => {
            let arr = object[event.target.name]
            arr[index] = event.target.value
            setObject({...object, [event.target.name]: arr})
        },
        setEventSubkey: (event, subkey) => {
            setObject({...object, [subkey]: {...object[subkey], [event.target.name]: event.target.value}})
        },
        setEventSubkeyIndex: (event, subkey, index) => {
            let newObject = {...object }
            newObject[subkey][index][event.target.name] = event.target.value
            setObject(newObject)
        },
    }
}