import React, { useState, useEffect } from 'react'
import { Cooks, Tracking_Recept } from '../../api/routes';
import ReceptChooseDialog from '../recept/ReceptChooseDialog';
import TrackingReceptCreate from '../tracking/TrackingReceptCreate';
import TrackingReceptView from '../tracking/TrackingReceptView';
import './styles.css'
import FormWrapper from '../components/FormWrapper/FormWrapper';
import WithUseAuth from '../auth/WithUseAuth';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect'
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@mui/icons-material/Check';


const weekDays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag']



var weekNumbers = () => {
  var currentdate = new Date();
  var oneJan = new Date(currentdate.getFullYear(), 0, 1);
  var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
  var result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
  var current = 1
  const weeks = []
  const arr = [...Array(51).keys()] // [1,2,3]
  arr.map(item => {
    var _week = current + item
    if (_week > 52) weeks.push(_week - 52)
    else weeks.push(_week)
  })
  return weeks
}

const getTimestamp = () => {
  const date = new Date()
  const getZero = item => String(item).length < 2 ? `0${item}` : item
  const year = date.getFullYear() + '-' + getZero(date.getMonth() + 1) + '-' + getZero(date.getDate())
  const time = getZero(date.getHours()) + ':' + getZero(date.getMinutes()) + ':' + getZero(date.getSeconds()) + '.000Z'

  const full = year + 'T' + time
  return full
}

function CookDialogReturn(props) {
  const isEmpty = props.cook.type == 'Bland' ? props.cook.subSmak : props.cook.smak

  return (
    <div className="Cook__Wrapper">
      <div className="Cook__Overlay" onClick={props.handleClickOpen} />
      <div className="CookDialogReturn">
        <div className="flex">
          <TextField
            label="Smak"
            id="standard-basic"
            value={isEmpty}
            InputProps={{ readOnly: true, }}
            style={{ width: "150px" }}
          />
          <div className="spacer-3" />
          <TextField
            label="Batch"
            id="outlined-start-adornment"
            sx={{ m: 1, width: '25ch' }}
            value={props.cook.batch}
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position="start">st</InputAdornment>,
            }}
            style={{ width: "50px" }}
          />
        </div>
        <div className="spacer-3" />
        <TextField
          label="Kommentar"
          id="standard-basic"
          value={props.cook.kommentar}
          InputProps={{
            readOnly: true,
          }}
          multiline
          maxrows={4}
        />

      </div>
    </div>
  )
}

function CookDialog(props) {
  const { isAdmin } = props.useAuth;
  const [open, setOpen] = useState(false);
  const [cook, setCook] = useState(props.cook)
  const [trs, setTRS] = useState(false)

  const weeks = weekNumbers()

  const getData = async () => {
    const _trs = await Tracking_Recept.ReadByCookId(cook.id)
    setTRS(_trs)
  };
  const handleClickOpen = () => {
    setOpen(true);
    getData()
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdate = (name, value) => {
    const _cook = { ...cook }
    _cook[name] = value
    setCook(_cook)
  }
  const handleUpdateArray = (arr) => {
    const _cook = { ...cook }
    arr.map(item => _cook[item.name] = item.value)
    setCook(_cook)
    console.log(_cook)
  }
  const handleUpdateEvent = e => {
    const { name, value } = e.target
    const _cook = { ...cook }
    _cook[name] = value
    setCook(_cook)
  }
  const handleUpdateDay = e => {
    const _cook = { ...cook }
    _cook.day_index = e.target.value
    _cook.dag = weekDays[e.target.value]
    setCook(_cook)
  }

  const handleDelete = async () => {
    if (window.confirm('Är du säker att du vill radera denna?')) {
      handleClose()
      await Cooks.Delete(cook)
      await props.getData()
    };
  }

  const handleCreate = async () => {
    handleClose()
    await Cooks.Update(cook)
    await props.getData()
  }

  const handleSetDone = async name => {
    const _cook = { ...cook }
    _cook.sign = name
    _cook.done = true
    let today = getTimestamp()
    _cook.doneTime = today
    handleClose()
    setCook(_cook)
    await Cooks.Update(_cook)
    await props.getData()
  }

  const handleSetNotDone = async () => {
    if (window.confirm('Är du säker på att du vill återställa?')) {
      const _cook = { ...cook }
      _cook.sign = ""
      _cook.done = false
      setCook(_cook)
      handleClose()
      await Cooks.Update(_cook)
      await props.getData()
    }
  }

  const isBland = cook.type == 'Bland'

  const underSmakSelect = <FormControl variant="standard">
    <InputLabel id="demo-simple-select-label">Undersmak</InputLabel>
    <NativeSelect
      value={cook.subSmak}
      name="subSmak"
      onChange={handleUpdateEvent}
      inputProps={{ name: 'subSmak', id: 'uncontrolled-native', }}
    >{cook.subSmaker.filter(item => item !== '').map(item => <option key={item} value={item}>{item}</option>)}
    </NativeSelect>
  </FormControl>

  const doneTimeSplit = () => {
    let doneTime = String(cook.doneTime)
    if (doneTime !== '') return doneTime.split('T')[1].split(':')[0] + ':' + doneTime.split('T')[1].split(':')[1] + ' ' + doneTime.split('T')[0]
    return
  }

  return (
    <div className="CookDialog">
      <CookDialogReturn handleClickOpen={handleClickOpen} {...props} />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <div className="flex">
            <div className="flex">
              Hantera en kokning
              <div className="spacer-10" />
              {!cook.done && <PersonMenu handle={handleSetDone} objects={props.persons} text="Färdigställ" />}
              {cook.done && <div className="Ordersedel__checklist__header__signed">
                <CheckIcon color="success" style={{ marginRight: "5px" }} /> <div>{cook.sign}, {doneTimeSplit()}</div>
                <div className="spacer-10" />
              </div>}
            </div>
            {cook.done && <Button variant="text" color="primary" onClick={handleSetNotDone}>Återställ</Button>}

          </div>
        </DialogTitle>
        <DialogContent className="CreateOrderDialog__form">

          <div className="Cook__Wrapper">
            {!isAdmin() && <div className="Cook__Overlay" />}
            <div className="CookDialogContent">

              <div className="flex">
                <TextField
                  id="standard-basic" label="Smak" variant="standard"
                  onChange={handleUpdateEvent}
                  value={cook.smak}
                  name="smak"
                  InputProps={{ readOnly: true, }}
                />

                {isBland && <div className="spacer-5" />}
                {isBland && underSmakSelect}

                <div className="spacer-5" />
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-label">Typ</InputLabel>
                  <NativeSelect
                    value={cook.sort}
                    name="sort"
                    onChange={handleUpdateEvent}
                    inputProps={{ name: 'sort', id: 'uncontrolled-native', }}
                  >{['Sort', 'Tryffel', 'Nougat', 'Kola', 'Flera'].map(item => <option key={item} value={item}>{item}</option>)}
                  </NativeSelect>
                </FormControl>

                <div className="spacer-5" />
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-label">Batch</InputLabel>
                  <NativeSelect
                    value={cook.batch}
                    name="batch"
                    onChange={handleUpdateEvent}
                    inputProps={{ name: 'batch', id: 'uncontrolled-native', }}
                  >{[...Array(7).keys()].map(item => <option key={item} value={item}>{item}</option>)}
                  </NativeSelect>
                </FormControl>

                <div className="spacer-5" />
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-label">Planerad Dag</InputLabel>
                  <NativeSelect
                    value={cook.day_index}
                    name="day_index"
                    onChange={handleUpdateDay}
                    inputProps={{ name: 'day_index', id: 'uncontrolled-native', }}
                  >{[0, 1, 2, 3, 4, 5, 6].map(item => <option key={item} value={item}>{weekDays[item]}</option>)}
                  </NativeSelect>
                </FormControl>

                <div className="spacer-5" />

                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-label">Vecka</InputLabel>
                  <NativeSelect
                    value={cook.week}
                    name="week"
                    onChange={handleUpdateEvent}
                    inputProps={{ name: 'week', id: 'uncontrolled-native', }}
                  >{weeks.map(item => <option key={item} value={item}>{item}</option>)}
                  </NativeSelect>
                </FormControl>

                <div className="spacer-5" />

                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-label">År</InputLabel>
                  <NativeSelect
                    value={cook.year}
                    name="year"
                    onChange={handleUpdateEvent}
                    inputProps={{ name: 'year', id: 'uncontrolled-native', }}
                  >{['2023', '2024'].map(item => <option key={item} value={item}>{item}</option>)}
                  </NativeSelect>
                </FormControl>
              </div>

              <div className="spacer-5" />

              <div className="flex">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-label">Prio</InputLabel>
                  <NativeSelect
                    value={cook.prio}
                    name="prio"
                    onChange={handleUpdateEvent}
                    inputProps={{ name: 'prio', id: 'uncontrolled-native', }}
                  >{[[1, 'Låg'], [2, 'Medel'], [3, 'Hög']].map(item => <option key={item[0]} value={item[0]}>{item[1]}</option>)}
                  </NativeSelect>
                </FormControl>
                <div className="spacer-5" />

                <FormWrapper label="Till Frysen">
                  <Switch
                    checked={cook.toFrys}
                    name="toFrys"
                    onChange={e => handleUpdate(e.target.name, e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                  />
                </FormWrapper>
                <div className="spacer-5" />

                <ReceptChooseDialog handleUpdate={handleUpdate} handleUpdateArray={handleUpdateArray} cook={cook} />
                <div className="spacer-5" />

                <TextField
                  id="standard-basic" label="Kommentar" variant="standard"
                  onChange={handleUpdateEvent}
                  value={cook.kommentar}
                  name="kommentar"
                  onChange={handleUpdateEvent}
                  multiline
                  maxrows={4}
                />
              </div>

            </div>

          </div>

          <div className="spacer-10" />
          {cook.recept_id && <div className="CookDialog__tracking">
            <div className="flex-align-center">
              <div className="h6">Recept & Spårbarhet</div>
              <div className="spacer-10" />
              <TrackingReceptCreate handleClose={handleClose} cook={cook} getData={getData} />
            </div>

            <div className="spacer-10" />
            <div className="CookDialog__tracking__list">
              {trs && trs.map((item, index) => {
                return (
                  <div key={index} className='flex'>
                    <TrackingReceptView item={item} cook={cook} getData={getData} />
                    <div className="spacer-10" />
                  </div>
                )
              })}
            </div>
          </div>}

        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
            {isAdmin() && <Button color="secondary" onClick={handleDelete}>Radera</Button>}
          </div>
          <Button color="primary" onClick={handleCreate}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


export default WithUseAuth(CookDialog);

function PersonMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClicked = name => {
    handleClose()
    props.handle(name)
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {props.text}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          props.objects.map(object => <MenuItem key={object.id} onClick={() => handleClicked(object.name)} value={object.name}>{object.name}</MenuItem>)
        }
      </Menu>
    </div>
  );
}