import React, { useState, useEffect } from 'react'
import { FixedSizeList as List } from 'react-window';
import Order from './Order'
import TextField from '@mui/material/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function OrdersInactive(props) {
  const filtered = () => props.orders.filter(order => !order.showList)
  const [searchResults, setSearchResults] = useState(filtered())

  const Row = ({ index, style }) => (
    <div style={style}>
      <Order order={searchResults[index]} {...props} />
    </div>
  )

  const Example = () => (
    <List
      height={500}
      itemCount={searchResults.length}
      itemSize={80}
      width={650}
    >
      {Row}
    </List>
  );


  return (
    <div>
      <SearchBox arr={filtered()} setArray={setSearchResults} />
      <Example />
    </div>
  )
}

function SearchBox({ arr, setArray }) {
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const results = arr.filter(item =>
      item.smak.toLowerCase().includes(searchTerm)
    );
    setArray(results);
  }, [searchTerm]);

  return (
    <div className="OrdersInactive">
      <TextField
        id="standard-basic" label="Sök Smak" variant="outlined" type="search"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value.toLowerCase())}
        style={{ margin: "10px 0" }}
        autoComplete="off"
      />
      {searchTerm !== "" && <IconButton onClick={() => setSearchTerm("")} style={{ marginLeft: "-45px" }}>
        <CloseIcon color="primary" />
      </IconButton>}
    </div>
  )
}
