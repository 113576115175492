import React, { useEffect, useState } from 'react'
import { aqueduct } from '../api/routes';
import Button from '@material-ui/core/Button';
import './styles.css'
/*
TODO:
filter by category (budskap mm ta från ordersedel)

DONE:
sort by header on click
search field
summed amount
summed total
date filters
combine products with same articleNumber and delivery date and sum the total and amount 
select with products
*/

/*
row example:
{
    "id": 178140,
    "created_at": "2023-04-03T14:45:56.470819+00:00",
    "order": {
        "@url": "https://api.fortnox.se/3/orders/4606",
        "@urlTaxReductionList": "https://api.fortnox.se/3/taxreductions?filter=orders&referencenumber=4606",
        "AdministrationFee": 0,
        "AdministrationFeeVAT": 0,
        "Address1": "Box 130",
        "Address2": "",
        "BasisTaxReduction": 0,
        "Cancelled": false,
        "City": "ÅRE",
        "Comments": "",
        "ContributionPercent": 100,
        "ContributionValue": 3900,
        "CopyRemarks": false,
        "Country": "Sverige",
        "CostCenter": "",
        "Currency": "SEK",
        "CurrencyRate": 1,
        "CurrencyUnit": 1,
        "CustomerName": "SKIERS ACCREDITED ALPINE CITIZEN SWEDEN AB",
        "CustomerNumber": "11429",
        "DeliveryState": "reservation",
        "DeliveryAddress1": "Box 130",
        "DeliveryAddress2": "",
        "DeliveryCity": "Åre",
        "DeliveryCountry": "",
        "DeliveryDate": "2023-04-07",
        "DeliveryName": "SKIERS ACCREDITED ALPINE CITIZEN SWEDEN AB",
        "DeliveryZipCode": "837 23",
        "DocumentNumber": "4606",
        "EmailInformation": {
            "EmailAddressFrom": null,
            "EmailAddressTo": "hejschweiz@skiershutte.com",
            "EmailAddressCC": "",
            "EmailAddressBCC": "",
            "EmailSubject": "Ordererkännande {no} från Åre Chokladfabrik",
            "EmailBody": "Hej!\n\nTack för din order. Översänder härmed ordererkännande.\n\nMed vänlig hälsning,\nÅre chokladfabrik"
        },
        "ExternalInvoiceReference1": "",
        "ExternalInvoiceReference2": "",
        "Freight": 0,
        "FreightVAT": 0,
        "Gross": 3900,
        "HouseWork": false,
        "InvoiceReference": "0",
        "Labels": [],
        "Language": "SV",
        "Net": 3900,
        "NotCompleted": false,
        "OfferReference": "0",
        "OrderDate": "2023-03-22",
        "OrderRows": [
            {
                "AccountNumber": 3020,
                "ArticleNumber": "904",
                "ContributionPercent": "100",
                "ContributionValue": "3900",
                "Cost": 0,
                "CostCenter": null,
                "DeliveredQuantity": "60",
                "Description": "Dessert ",
                "Discount": 0,
                "DiscountType": "PERCENT",
                "HouseWork": false,
                "HouseWorkHoursToReport": null,
                "HouseWorkType": null,
                "OrderedQuantity": "60",
                "Price": 65,
                "Project": "",
                "ReservedQuantity": "0",
                "RowId": 220049,
                "StockPointCode": null,
                "StockPointId": null,
                "Total": 3900,
                "Unit": "st",
                "VAT": 12
            },
            {
                "AccountNumber": 0,
                "ArticleNumber": "",
                "ContributionPercent": "0",
                "ContributionValue": "0",
                "Cost": 0,
                "CostCenter": null,
                "DeliveredQuantity": "0",
                "Description": "( finns 19 i frysen )",
                "Discount": 0,
                "DiscountType": "PERCENT",
                "HouseWork": false,
                "HouseWorkHoursToReport": null,
                "HouseWorkType": null,
                "OrderedQuantity": "0",
                "Price": 0,
                "Project": "",
                "ReservedQuantity": "0",
                "RowId": 220050,
                "StockPointCode": null,
                "StockPointId": null,
                "Total": 0,
                "Unit": "",
                "VAT": 0
            },
            {
                "AccountNumber": 0,
                "ArticleNumber": "",
                "ContributionPercent": "0",
                "ContributionValue": "0",
                "Cost": 0,
                "CostCenter": null,
                "DeliveredQuantity": "0",
                "Description": "Vill ha påskveckan ",
                "Discount": 0,
                "DiscountType": "PERCENT",
                "HouseWork": false,
                "HouseWorkHoursToReport": null,
                "HouseWorkType": null,
                "OrderedQuantity": "0",
                "Price": 0,
                "Project": "",
                "ReservedQuantity": "0",
                "RowId": 220051,
                "StockPointCode": null,
                "StockPointId": null,
                "Total": 0,
                "Unit": "",
                "VAT": 0
            }
        ],
        "OrderType": "Order",
        "OrganisationNumber": "5568034911",
        "OurReference": "Malin",
        "Phone1": "0647-779998",
        "Phone2": "",
        "PriceList": "A",
        "PrintTemplate": "oc",
        "Project": "",
        "WarehouseReady": false,
        "OutboundDate": "2023-03-22",
        "Remarks": "",
        "RoundOff": 0,
        "Sent": true,
        "TaxReduction": null,
        "TermsOfDelivery": "FVL",
        "TermsOfPayment": "30",
        "TimeBasisReference": 0,
        "Total": 4368,
        "TotalToPay": 4368,
        "TotalVAT": 468,
        "VATIncluded": false,
        "WayOfDelivery": "HÄM",
        "YourReference": "",
        "YourOrderNumber": "",
        "ZipCode": "83723",
        "StockPointCode": null,
        "StockPointId": null,
        "TaxReductionType": "none"
    }
}
*/

export default function FortnoxList() {
	const [originalRows, setOriginalRows] = useState(false)
	const [rows, setRows] = useState(false)
	const [lastUpdated, setLastUpdated] = useState(false)
	const [access_expired, setAccess_expired] = useState(false)

	const [sortedHeader, setSortedHeader] = useState(false)
	const [sortDirection, setSortDirection] = useState(false)

	const [search, setSearch] = useState('')
    const [filteredRows, setFilteredRows] = useState(false)

	const [totalQuantity, setTotalQuantity] = useState(0)
	const [totalPrice, setTotalPrice] = useState(0)

	const [dateFrom, setDateFrom] = useState('2023-01-01')
	const [dateTo, setDateTo] = useState('2023-12-31')

	const combineRows = (rows) => {
		
		let newRows = []

		for (let i = 0; i < rows.length; i++) {
			const row = rows[i]

			let found = false
			newRows.map((newRow, index) => {
				if (newRow.ArticleNumber == row.ArticleNumber && newRow.DeliveryDate == row.DeliveryDate) {
					newRows[index].Total = newRows[index].Total + row.Total
					newRows[index].OrderedQuantity = newRows[index].OrderedQuantity + row.OrderedQuantity
					
					if (newRows[index].numberOfOrders) {
						newRows[index].numberOfOrders = newRows[index].numberOfOrders + 1
					} else {
						newRows[index].numberOfOrders = 2
					}

					found = !found
				}
			})

			if (!found) {    
				let newRow = {...row}
				newRow.numberOfOrders = 1
				newRows.push(newRow)
			}
		}	

		return newRows
	}

	useEffect(() => {
		const getRows = async () => {
			let { data, orders } = await aqueduct()

			// flat the data for every order and its orderrows
			let flat_orders = []
			for (let i = 0; i < orders.length; i++) {
				orders[i] = await orders[i];
				for (let j = 0; j < orders[i].order.OrderRows.length; j++) {
					flat_orders.push({
						"Description": orders[i].order.OrderRows[j].Description,
						"DocumentNumber": orders[i].order.DocumentNumber,
						"CustomerName": orders[i].order.CustomerName,
						"OrderDate": orders[i].order.OrderDate,
						"DeliveryDate": orders[i].order.DeliveryDate,
						"ArticleNumber": orders[i].order.OrderRows[j].ArticleNumber,
						"OrderedQuantity": parseInt(orders[i].order.OrderRows[j].OrderedQuantity),
						"Description": orders[i].order.OrderRows[j].Description,
						"Unit": orders[i].order.OrderRows[j].Unit,
						"Total": parseInt(orders[i].order.OrderRows[j].Total),
					})
				}
			}

			// filter out orders with no articleNumber
			flat_orders = flat_orders.filter(row => row.ArticleNumber !== '')

			let combined_rows = combineRows(flat_orders)
			
			// set rows
			setOriginalRows(flat_orders)
			setRows(combined_rows)
			setFilteredRows(combined_rows)

			// set last updated
			const event = new Date(parseInt(data.updated_at));
			const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
			setLastUpdated(event.toLocaleDateString('se-SE', options))

			// set access expired
			setAccess_expired(data.access_expired)
			if (data.access_expired) {
				alert('Tillgången har gått ut. Vänligen logga in igen genom att trycka på knappen "Logga in i Fortnox"')
			}
		}	
		getRows()
	}, [])

	useEffect(() => {
		// calculate total quantity and total price
		let newTotalQuantity = 0
		let newTotalPrice = 0

		for (let i = 0; i < filteredRows.length; i++) {
			newTotalQuantity += parseInt(filteredRows[i].OrderedQuantity)
			newTotalPrice += parseInt(filteredRows[i].Total)
		}

		setTotalQuantity(newTotalQuantity)
		setTotalPrice(newTotalPrice)
	}, [filteredRows])

	const handleFilter = (e) => {
		const { name, value } = e.target

		let _search = search
		let _dateFrom = dateFrom
		let _dateTo = dateTo

		if (name === 'search') {
			_search = value
			setSearch(value)
		} else if (name === 'dateFrom') {
			_dateFrom = value
			setDateFrom(value)
		}
		else if (name === 'dateTo') {
			_dateTo = value
			setDateTo(value)
		}

		let newFilteredRows = [...rows]

		if (_search !== '') {
			newFilteredRows = newFilteredRows.filter(row => {
				let found = false
				let keys = ['ArticleNumber', 'Description', 'OrderDate', 'DeliveryDate']

				for (let key in keys) {
					if (row[keys[key]].toString().toLowerCase().startsWith(_search.toLowerCase())) {
						found = true
					}
				}

				if (!found) {
					for (let key in keys) {
						if (row[keys[key]].toString().toLowerCase().includes(_search.toLowerCase())) {
							found = true
						}
					}
				}

				return found
			})
		}

		if (_dateFrom !== '') {
			newFilteredRows = newFilteredRows.filter(row => {
				return row.DeliveryDate >= _dateFrom
			})
		}

		if (_dateTo !== '') {
			newFilteredRows = newFilteredRows.filter(row => {
				return row.DeliveryDate <= _dateTo
			})
		}

		setFilteredRows(newFilteredRows)
	}

	const handleSortByHeader = (header) => {
		let newRows = [...filteredRows]

		let newSortDirection = false

		if (sortedHeader === header) {
			newSortDirection = !sortDirection
		}

		// detect if the header is a number or string
		const isNumber = !isNaN(newRows[0][header])

		// parse the number if it is a number
		if (isNumber) {
			newRows = newRows.map(row => {
				row[header] = parseInt(row[header])
				return row
			})
		}
		
		newRows = newSortDirection ? newRows.reverse() : newRows.sort((a, b) => {
			if (a[header] < b[header]) return -1
			if (a[header] > b[header]) return 1
			return 0
		})
		
		setFilteredRows(newRows)
		setSortedHeader(header)
		setSortDirection(newSortDirection)
	}



	// render 
	const th = (header, text, total) => {
		let sortIcon = false
		if (sortedHeader === header) {
			sortIcon = sortDirection ? '↓' : '↑'
		}

		return (
			<th key={header} onClick={() => handleSortByHeader(header)}>
				{text} {sortIcon} {total && '(' + total + ')'}
			</th>
		)
	}

	const Thead = () => {
		let ths = [
			{header: 'ArticleNumber', text: 'Artikelnummer'},
			{header: 'Description', text: 'Artikel'},
			{header: 'OrderedQuantity', text: 'Antal', total: `${totalQuantity} st`},
			{header: 'Total', text: 'Summa', total: `${totalPrice} kr`},
			{header: 'OrderDate', text: 'Orderdatum'},
			{header: 'DeliveryDate', text: 'Leveransdatum'},
		]

		return ( 
			<thead>
				<tr>
					{ths.map(_th => th(_th.header, _th.text, _th.total))}
				</tr>
			</thead>
		)
	}

	const Tbody = () => { 
		return (
			<tbody>
				{filteredRows && filteredRows.map((row, i) => {
					return (
						<tr key={i}>
							<td>{row.ArticleNumber}</td>
							<td>{row.Description}</td>
							<td>{row.OrderedQuantity}</td>
							<td>{row.Total}</td>
							<td>{row.OrderDate}</td>
							<td>{row.DeliveryDate}</td>
						</tr>
					)
				})}
			</tbody>
		)
	}



	return (
		<div className='fx'>
			<div className="status">
				<p>{lastUpdated && `Senast uppdaterad: ${lastUpdated}`}</p>
				
				<div className='spacer-10' />
				
				{access_expired && <Button color="secondary" variant='contained'
				onClick={() => {
					window.location.href = 'https://arecms.se/aqueduct/activate'
				}}>Logga in i Fortnox</Button>}
			</div>

			<div className='filter-grid'>
				<p></p>
				<p>Leveransdatum från</p>
				<p>Levereansdatum till</p>
				<p></p>

				<input type="text" name="search" id="search" autoComplete='off' placeholder='Sök...' value={search} onChange={handleFilter} />
				{/* <select name="selectArticle" id="select" value={selectedArticle} onChange={handleFilter} >
					<option value={''}>Alla</option>
					{articles && articles.map((article, i) => {
						return (
							<option key={i} value={article}>{article}</option>
						)
					})}
				</select> */}
				<input type="date" name="dateFrom" id="date" value={dateFrom} onChange={handleFilter} />
				<input type="date" name="dateTo" id="date" value={dateTo} onChange={handleFilter} />
				<Button color="primary" onClick={() => {
					setSearch('')
					setDateFrom('2023-01-01')
					setDateTo('2023-12-31')
					setFilteredRows(rows)
					setSortDirection(false)
					setSortedHeader(false)
				}}>Återställ filter</Button>

			</div>
            <table>
				<Thead />
				<Tbody />
			</table>
		</div>
	)
}


