import React from 'react'
import { FixedSizeList as List } from 'react-window'

import WithUseAuth from '../../../pages/auth/WithUseAuth'
import { Routes } from '../../../api/routes'

import Create from './Create'
import View from './View'
import Todo from './Todo'
import './styles.css'

import TextField from '@mui/material/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

// data = [
// {
//     "id": 1,
//     "name": "test",
//     "done": false,
//     "dipped_date": "2023-02-02",
//     "frozen_date": "2023-03-03",
//     "frozen_by": "Martin J",
//     "original_amount": 100,
//     "unit": "st",
//     "history": [
//          {
//              "date": "2023-09-05",
//              "signature": "Andy",
//              "new_amount": 246,
//              "reduce_amount": "4"
//          },
//      ],
//     "current_amount": 100
// }
// ]

function Freezer(props) {
    const { isAdmin } = props.useAuth
    
    const [data, setData] = React.useState([])
    const [filteredData, setFilteredData] = React.useState([])
    const [persons, setPersons] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState("")
    const [showDone, setShowDone] = React.useState(false)
    const [orderBy, setOrderBy] = React.useState('frozen_date')
    const [orderByDirection, setOrderByDirection] = React.useState('asc') // asc or desc

    const getData = async () => {
        // fetch freezer data
        let res = await Routes.Read('freezer')
        // sort by frozen_date, oldest first
        res.sort((a, b) => (a.frozen_date > b.frozen_date) ? 1 : ((b.frozen_date > a.frozen_date) ? -1 : 0))

        if (!showDone) {
            res = res.filter(f => !f.done)
        } else {
            res = res.filter(f => f.done)
        }

        setData(res)

        if (filteredData.length === 0) setFilteredData(res)
        else {
            const results = res.filter(item => filteredData.find(f => f.id === item.id))
            setFilteredData(results);
        }

        // set search term to trigger useEffect to keep the search results from before
        const _searchTerm = searchTerm + ""
        setSearchTerm(' ')
        setSearchTerm(_searchTerm)

        // fetch persons data
        res = await Routes.Read('persons')
        res.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        let filtered = res.filter(p => p.frysrum)
        setPersons(filtered)
    }
    React.useEffect(() => {
        getData()
    }, [showDone])

    React.useEffect(() => {
        // sort by orderBy
        const _filteredData = [...filteredData]

        if (orderBy === 'name') {
            _filteredData.sort((a, b) => {
                if (orderByDirection === 'asc') {
                    return (a[orderBy].trim().toLowerCase() > b[orderBy].trim().toLowerCase()) ? 1 : ((b[orderBy].trim().toLowerCase() > a[orderBy].trim().toLowerCase()) ? -1 : 0)
                } else {
                    return (a[orderBy].trim().toLowerCase() < b[orderBy].trim().toLowerCase()) ? 1 : ((b[orderBy].trim().toLowerCase() < a[orderBy].trim().toLowerCase()) ? -1 : 0)
                }
            })
        } else if (orderBy === 'frozen_date' || orderBy === 'dipped_date') {
            _filteredData.sort((a, b) => {
                if (orderByDirection === 'asc') {
                    return (a[orderBy] > b[orderBy]) ? 1 : ((b[orderBy] > a[orderBy]) ? -1 : 0)
                } else {
                    return (a[orderBy] < b[orderBy]) ? 1 : ((b[orderBy] < a[orderBy]) ? -1 : 0)
                }
            })
        }


        setFilteredData(_filteredData)
    }, [orderBy, orderByDirection])

    const Row = ({ index, style }) => {
        const item = filteredData[index]

        return (
            <div style={style}>
                <View item={item} persons={persons} getData={getData} isAdmin={isAdmin} />
            </div>
        )
    }
    const ListItems = () => (
        <List
            height={700}
            itemCount={filteredData.length}
            itemSize={67}
            width={720}
        >
            {Row}
        </List>
    );

    const handleKOR = async () => {
        const frys = await Routes.Read('frys')

        // {
        //     "id": 422,
        //     "created_at": "2023-05-22T07:21:19.95432+00:00",
        //     "name": "Vegankola mörk frysback ",
        //     "done": true,
        //     "doppad": "2023-05-19",
        //     "fryst": "2023-05-22",
        //     "frystSign": "Hanna",
        //     "ammount": 0,
        //     "unit": "st",
        //     "history": [
        //         {
        //             "date": "2023-08-02",
        //             "sign": "Petra",
        //             "ammount": "2" // reduce amount
        //         },
        //         {
        //             "date": "2023-07-10",
        //             "sign": "André ",
        //             "ammount": "1"
        //         }
        //     ],
        //     "temp_sign": null
        // }

        // convert frys to freezer
        let freezer = frys.map(f => {
            // sort history by date
            f.history = f.history.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))

            let original_amount = f.history.map(h => h.ammount).reduce((a, b) => parseInt(a) + parseInt(b), 0) // sum of all reduce_amounts (reduce_amount is the amount that was reduced from the previous amount
            // add current amount
            original_amount = original_amount + parseInt(f.ammount)
            
            let temp_amount = original_amount

            const freezerItem = {
                name: f.name,
                done: f.done,
                dipped_date: f.doppad,
                frozen_date: f.fryst,
                frozen_by: f.frystSign,
                original_amount,
                current_amount: f.ammount,
                unit: f.unit,
                history: f.history.map(h => {
                    temp_amount = temp_amount - parseInt(h.ammount)
                    return {
                        date: h.date,
                        signature: h.sign,
                        new_amount: temp_amount,
                        reduce_amount: h.ammount
                    }
                })
            }

            // reverse history back
            freezerItem.history = freezerItem.history.reverse()

            return freezerItem
        })

        await Routes.Create(freezer, 'freezer')
        console.log('done')
    }


    return (
        <div className="flex-col-center g-10">
            <div className="flex-col-center g-10">
                <div className='flex-center g-10'>
                    {/* <button onClick={handleKOR}>KÖR</button> */}
                    <h1 className='Whiteboard__h1'>Frys</h1>
                    <Button onClick={() => setShowDone(!showDone)} variant="outlined" color="secondary">{`Visa ${!showDone ? 'Tinade' : 'Frysta'}`}</Button>
                </div>
                    
                <div className="flex-col-center g-10" style={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #eee",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                }}>
                    <div className="flex-center g-10">
                        <Create getData={getData} persons={persons} />
                        <SearchBox arr={data} setArray={setFilteredData} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                        
                        <div className="flex" style={{
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #eee",
                            padding: "10px",
                        }}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Sortering</InputLabel>
                                <Select
                                    native
                                    value={orderBy}
                                    onChange={e => setOrderBy(e.target.value)}
                                    inputProps={{
                                        name: 'orderBy',
                                        id: 'orderBy',
                                    }}
                                    // style={{ width: "200px" }}

                                >
                                    <option value="frozen_date">Fryst datum</option>
                                    <option value="dipped_date">Tillverkad datum</option>
                                    <option value="name">Namn</option>
                                </Select>
                            </FormControl>
                            <Button 
                                onClick={() => setOrderByDirection(orderByDirection === 'asc' ? 'desc' : 'asc')}
                                variant="text"
                                // color="primary"
                                style={{
                                    fontSize: "16px",
                                }}
                            >
                                {orderByDirection === 'asc' ? '↑' : '↓'}
                            </Button>
                        </div>
                    </div>
                    
                    <div className="flex-col-center">
                        <ListItems />
                    </div>
                </div>
            </div>
            <div className="spacer-20" />
            <Todo />
            <div className="spacer-20" />
            <div className="spacer-20" />
            <div className="spacer-20" />
        </div>
    )
}

function SearchBox({ arr, setArray, searchTerm, setSearchTerm }) {
    // const [searchTerm, setSearchTerm] = React.useState("")

    React.useEffect(() => {
        if (arr !== false) {
            // arr filter for any key, unless item[key] is an object or array
            const results = arr.filter(item => {
                if (searchTerm === "") {
                    return true
                }

                for (const key in item) {
                    if (typeof item[key] !== 'object' && typeof item[key] !== 'array' && key !== 'created_at' && key !== 'updated_at') {
                        if (item[key].toString().toLowerCase().includes(searchTerm)) {
                            return true
                        }
                    }
                }
                return false
            })
            setArray(results);
        }
    }, [searchTerm]);

    return (
        <div className="OrdersInactive">
            <TextField
                id="standard-basic" label="Sök Namn" variant="outlined" type="text"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value.toLowerCase())}
                style={{ margin: "10px 0" }}
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
            />
                {
                    searchTerm !== "" && 
                    <IconButton onClick={() => setSearchTerm("")} style={{ marginLeft: "-45px" }}>
                        <CloseIcon color="primary" />
                    </IconButton>
                }
        </div>
    )
}

export default WithUseAuth(Freezer)
