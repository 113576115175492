import React, { useState, useEffect } from 'react'
import WithUseAuth from '../auth/WithUseAuth';
import { Butik, Persons, getDate } from '../../api/routes';
import FormWrapper from '../components/FormWrapper/FormWrapper';
import { FixedSizeList as List } from 'react-window';
import SelectPersons from '../components/SelectPersons';
import './styles.css'


import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputText } from '../styledComponents';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ButiksLager(props) {
  const { isAdmin } = props.useAuth;

  const [lager, setLager] = useState([]);
  const [lagerHistorik, setLagerHistorik] = useState([]);
  const [historik, setHistorik] = useState([]);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);
  const [signatur, setSignatur] = useState("Signatur")
  const [NoSignatur, setNoSignatur] = useState(false)
  const [persons, setPersons] = useState([])
  const [searchResults, setSearchResults] = useState(false)

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    Butik.Lager.Read().then(res => {
      const sorted = res.sort((a,b) => a.id - b.id)
      setLager(sorted);
      setLoading(false);
      setSearchResults(sorted)
    });
    Butik.Lager.ReadHistory().then(res => {
      const sorted = res.sort((a,b) => a.id - b.id)
      setLagerHistorik(sorted);
    });
    Persons.Read().then(res => {
      const sorted = res.sort((a,b) => a.name - b.name)
      const filtered = sorted.filter(item => item.butikslager);
      setPersons(filtered);
    });
  }

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    const newLager = [...lager];

    const index = newLager.findIndex(p => p.id === id)

    if (name == "antal") {
      newLager[index].diff = value - newLager[index].antal;
    }

    newLager[index][name] = value;
    newLager[index].signatur = signatur;
    newLager[index].signerad = getDate();
    
    setLager(newLager);

    
    const newHistorik = [...historik];
    const exists = newHistorik.findIndex(item => item.id == newLager[index].id)
    if (exists > -1) {
      newHistorik[exists] = newLager[index];
    } else newHistorik.push(newLager[index]);

    setHistorik(newHistorik); 
  };
  const handleCheckout = async () => {
    const newLager = [...lager];
    await Butik.Lager.Upsert(newLager);
    await Butik.Lager.CreateManyHistory(historik);
    setHistorik([]);
    setActive(false)
    setSearchResults(false)
    setSignatur("Signatur")
    setNoSignatur(false)
    getData()
  };

  const handleName = (name) => {
    if (name == "Signatur") {
      setNoSignatur(true)
    } else {
      setSignatur(name)
      setNoSignatur(false)
      setActive(true)
    }    
  }

 
  const Row = ({ index, style }) => {
    const item = searchResults[index];
    return (
      <div className="Butikslager__row">
        <div className="Butikslager__row__item">
            <FormWrapper label="Namn">
                {item.namn}
            </FormWrapper> 
        </div>
        
        <div className="Butikslager__row__item">
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Antal</InputLabel>
              <NativeSelect
                name="antal"
                value={item.antal}
                onChange={e => handleChange(e, item.id)}
                disabled={!active}
              >{[...Array(21).keys()].map(i => <option key={i} value={i}>{i}</option>)}
              </NativeSelect>
            </FormControl>
        </div>

        <div className="Butikslager__row__item">
          <TextField
            id="standard-basic" variant="standard"
            label="Kommentar" 
            name="kommentar"
            value={item.kommentar}
            onChange={e => handleChange(e, item.id)}
            InputProps={{ readOnly: !active, }}
            style={{ width: "250px" }}
          />
        </div>

        <div className="spacer-10" />
        <div className="Butikslager__row__item flex">
          {isAdmin() && <ItemDialog getData={getData} item={item} /> }
          <History historik={lagerHistorik} item={item} />
        </div>
      </div>
    )
  }

  const Example = () => (
    <List
      height={600}
      itemCount={searchResults.length}
      itemSize={80}
      width={700}
    >
      {Row}
    </List>
  );


  return (
    <div className="flex-col">
      <div className="flex-center">
        <div className="Whiteboard__h1">Butik</div>
      </div>

      <div className="ReceptList__list">
        <div className="flex-center">
          {isAdmin() && <ItemDialog getData={getData} />}
          <div className="spacer-10" />
          <SearchBox arr={lager} setArray={setSearchResults} />
        </div>
        <div className="spacer-20" />

        <div className="flex-col-center">
          {!loading && lager && lager.length > 0 && <Example />}
          {!loading && lager && lager.length == 0 && <div>Inga produkter i lager</div>}
          {loading && <div>Laddar...</div>}
        </div>
      </div>  

      <div className="butikslager__checkout__backwrapper">
        <div className="butikslager__checkout__wrapper">
          <div className="butikslager__checkout">
            {!loading && persons ? (
              <SelectPersons 
              label="Signatur" 
              persons={persons} 
              callback={handleName}
              error={NoSignatur} 
              />
            ) : null}
            
            <div className="spacer-10" />

            <Button variant="contained" color="primary" onClick={() => handleCheckout()}>signera</Button>
          </div>

        </div>
      </div>
    </div>
  )
}



function SearchBox({ arr, setArray }) {
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    setSearchTerm("")
  }, [arr])

  useEffect(() => {
    if (arr !== false) {
      const results = arr.filter(item =>
        item.namn.toLowerCase().includes(searchTerm)
      );
      setArray(results);
    }
  }, [searchTerm]);

  return (
    <div className="OrdersInactive">
      <TextField
        id="standard-basic" label="Sök Namn" variant="outlined" type="text"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value.toLowerCase())}
        style={{ margin: "10px 0" }}
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
      />
      {searchTerm !== "" && <IconButton onClick={() => setSearchTerm("")} style={{ marginLeft: "-45px" }}>
        <CloseIcon color="primary" />
      </IconButton>}
    </div>
  )
}


function ItemDialog(props) {
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState({
    namn:"",
    antal: 0,
    kommentar: "",
    diff:0,
    signatur:"",
    signerad:"",
  })

  useEffect(() => {
    if (props.item) setItem(props.item)
  }, [])

  useEffect(() => {
    if (props.item) setItem(props.item)
  }, [props.item])


  const handleClickOpen = () => {
    setOpen(true);
    if (!props.item) setItem({
      namn:"",
      antal: 0,
      kommentar: "",
      diff:0,
      signatur:"",
      signerad:"",
    })
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if(window.confirm("Är du säker på att du vill ta bort den?")) {
      await Butik.Lager.Delete(item)
      await props.getData()
      handleClose()
    }
  };

  const handleSave = async () => {
    await Butik.Lager.Upsert(item)
    await props.getData()
    handleClose()
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {props.item ? "Ändra" : "Lägg till +"}
      </Button>
      
      <Dialog open={open} onClose={handleClose}>
        <div style={{padding:"24px 0 0 24px"}}>
          <DialogTitle>Uppdatera Lager</DialogTitle>
        </div>
        <DialogContent>
          
          <TextField
            variant="standard"
            type="text"
            label="Namn på produkt"
            name="namn"
            value={item.namn}
            onChange={e => setItem({...item, [e.target.name]: e.target.value})}
            autoFocus
          />
        </DialogContent>

        <DialogActions>
          <div className="flex-space-between" style={{width:"100%"}}>
            <div className="flex">
              <Button onClick={handleClose}>stäng</Button>
              { props.item && <Button onClick={handleDelete} color="secondary">radera</Button> }
            </div>
            
            <Button onClick={handleSave} color="primary">Spara</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function History(props) {
  const [open, setOpen] = useState(false)
  const [historik, setHistorik] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.historik) {
      const filtered = [...props.historik].filter(item => item.namn == props.item.namn)
      setHistorik(filtered)
      setLoading(false)
    }
  }, [props.historik])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Row = ({ index, style }) => {
    const item = historik[index];
    return (
      <div className="Butikslager__row">
        <div className="Butikslager__row__item">
            <FormWrapper label="Namn">
                {item.namn}
            </FormWrapper> 
        </div>
        
        <div className="Butikslager__row__item">
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Antal</InputLabel>
              <NativeSelect
                name="antal"
                defaultValue={item.antal}
                disabled={true}
              >{[...Array(21).keys()].map(i => <option key={i} value={i}>{i}</option>)}
              </NativeSelect>
            </FormControl>
        </div>

        <div className="Butikslager__row__item">
            <FormWrapper label="Ändring">
                {item.diff}
            </FormWrapper> 
        </div>

        <div className="Butikslager__row__item">
            <FormWrapper label="Signatur">
                {item.signatur}
            </FormWrapper> 
        </div>

        <div className="Butikslager__row__item">
            <FormWrapper label="Datum">
                {item.signerad.split('T')[0]}
            </FormWrapper> 
        </div>
        
        <div className="Butikslager__row__item">
          <TextField
            id="standard-basic" variant="standard"
            label="Kommentar" 
            name="kommentar"
            defaultValue={item.kommentar}
            InputProps={{ readOnly: true, }}
            style={{ width: "250px" }}
          />
        </div>
      </div>
    )
  }

  const Example = () => (
    <List
      height={600}
      itemCount={historik.length}
      itemSize={80}
      width={850}
    >
      {Row}
    </List>
  );


  return (
    <div>
      <Button variant="default" color="primary" onClick={handleClickOpen}>
        historik
      </Button>
      
      <Dialog open={open} onClose={handleClose}>
        <div style={{padding:"24px 0 0 24px"}}>
          <DialogTitle>Historik</DialogTitle>
        </div>

        <DialogContent>
          <div className="flex-col-center">
            {!loading && historik && historik.length > 0 && <Example />}
            {!loading && historik && historik.length == 0 && <div>Inga historik att visa</div>}
            {loading && <div>Laddar...</div>}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>stäng</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}















export default WithUseAuth(ButiksLager);
