import React, { useEffect, useState } from 'react'
import { Recepts, Ingredienser, Tracking_Recept, getDate } from '../../api/routes';
import WithUseAuth from '../auth/WithUseAuth';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIconBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function TrackingReceptView(props) {
  const { isAdmin } = props.useAuth;
  const [open, setOpen] = useState(false);

  const [recept, setRecept] = useState(false);
  const [tr, setTR] = useState(false)
  const [showError, setShowError] = useState(false)

  const getData = async () => {
    const _tr = { ...props.item }
    setTR(_tr)
    const _recept = () => {
      try {
        return JSON.parse(_tr.recept)
      } catch (error) {
        return _tr.recept
      }
    }
    setRecept(_recept);
  }

  useEffect(() => {
    getData()
  }, [])

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
  };
  const handleSave = async () => {
    const rq = tr.sign !== "" && tr.nrPlates > 0
    if (rq) {
      handleClose()

      const parts = tr.steps.map(step => {
        const { done, ...part } = step
        return part
      })
      await Ingredienser.Upsert(parts)

      await Tracking_Recept.Update(tr)

      await props.getData()
    } else setShowError(true)

  };
  const handleDelete = async () => {
    if (window.confirm("Är du säker på att du vill ta bort denna?")) {
      handleClose()
      await Tracking_Recept.Delete(tr)
      await props.getData()
    }
  }

  const handleUpdateEvent = async (e) => {
    const { name, value } = e.target
    const _tr = { ...tr }
    _tr[name] = value
    setTR(_tr)
  }
  const handleUpdateSteps = async (e, index) => {
    const { name, value } = e.target
    const _tr = { ...tr }
    _tr.steps[index][name] = value
    setTR(_tr)
  }
  const handleShowMark = async (index) => {
    const _tr = { ...tr }
    _tr.steps[index].done = !_tr.steps[index].done
    setTR(_tr)
  }


  const renderPart = (item, i) => {
    return <div key={i}>
      {recept && <div className="flex">
        <TextField
          id="standard-basic" label="Mått" variant="standard"
          value={recept.measures[i]}
          name="measures"
          style={{ width: "100px" }}
          InputProps={{ readOnly: true, }}
        />
        <div className="spacer-5" />

        <TextField
          id="standard-basic" label="Ingrediens" variant="standard"
          value={item.name}
          name="ingredienser"
          InputProps={{ readOnly: true, }}
        />
        <div className="spacer-5" />

        <TextField
          id="standard-basic" label="Information" variant="standard"
          onChange={(e) => handleUpdateSteps(e, i)}
          value={item.info}
          name="info"
          style={{ width: "220px", }}
        />
        <div className='spacer-5' />

        <IconButton aria-label="delete" onClick={() => handleShowMark(i)}>
          {item.done ? <CheckBoxIcon /> : <CheckBoxIconBlank />}
        </IconButton>
      </div>}
      <div className="spacer-5" />
    </div>
  }

  return (
    <div>
      {!props.disableDelete && <Button onClick={handleOpen} variant="text" color="primary">{`${recept.namn} - ${tr.id}`}</Button>}
      {props.disableDelete && <Button onClick={handleOpen} variant="text" color="primary">visa</Button>}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Recept och spårbarhet</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <div className="Cook__Wrapper">
            {props.cook.done && <div className="Cook__Overlay" />}
            <div className="flex-col">

              {recept && <Recept recept={recept} />}

              <div className="flex-col">
                {tr && tr.steps.map((item, i) => renderPart(item, i))}
              </div>
              <div className="spacer-10" />

              <div className="flex">
                <TextField
                  id="date"
                  label="Kokningsdatum"
                  type="date"
                  name="date"
                  variant="standard"
                  value={tr.date}
                  sx={{ width: 220 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleUpdateEvent}
                  style={{ width: "145px" }}
                  error={showError}
                />
                <div className="spacer-5" />

                <TextField
                  id="standard-basic" label="Signatur" variant="standard"
                  onChange={handleUpdateEvent}
                  value={tr.sign}
                  name="sign"
                  error={showError}
                />
                <div className="spacer-5" />

                <TextField
                  id="standard-basic" label="Antal Plåtar" variant="standard" type="number"
                  onChange={handleUpdateEvent}
                  value={tr.nrPlates}
                  name="nrPlates"
                  style={{ width: "100px" }}
                  error={showError}
                />
                <div className="spacer-5" />
              </div>

            </div>
          </div>
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
            {!props.disableDelete && <Button color="secondary" onClick={handleDelete}>Radera</Button>}
          </div>
          {!props.cook.done && !props.disableDelete && <Button color="primary" onClick={handleSave}>Spara</Button>}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default WithUseAuth(TrackingReceptView);

function Recept({ recept }) {


  return (
    <div>
      <TextField
        id="standard-basic" label="Namn" variant="standard"
        value={recept.namn}
        name="namn"
        InputProps={{ readOnly: true, }}
      />
      <div className="spacer-5" />
      <TextField
        id="standard-basic" label="Skapare" variant="standard"
        value={recept.author}
        name="author"
        InputProps={{ readOnly: true, }}
      />
      <div className="spacer-5" />
      <TextField
        id="standard-basic" label="Instruktion" variant="standard"
        value={recept.instruktion}
        name="instruktion"
        multiline
        style={{ minWidth: "100%", }}
        InputProps={{ readOnly: true, }}
      />

      <div className="spacer-20" />
    </div>
  )
}