import React, { useEffect, useState } from 'react'
import { Recepts } from '../../api/routes';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const getTimestamp = () => {
  const date = new Date()
  const getZero = item => String(item).length < 2 ? `0${item}` : item
  const year = date.getFullYear() + '-' + getZero(date.getMonth() + 1) + '-' + getZero(date.getDate())
  const full = year
  return full
}

export default function ReceptCreateDialog(props) {
  const [open, setOpen] = useState(false);

  const genRecept = () => {
    const genArray = (len, cont) => Array.from({ length: len }, _ => cont)
    const item = {
      namn: "", author: "", instruktion: "",
      ingredienser: genArray(15, ""), measures: genArray(15, ""),
      reviderad: getTimestamp()
    }
    return item
  }
  const [recept, setRecept] = useState(genRecept())


  const handleClickOpen = async () => {
    setOpen(true);
    setRecept(genRecept())
  };
  const handleClose = async () => {
    setOpen(false);
    await props.getData()
  };

  const handleUpdate = (name, value) => {
    const _recept = { ...recept }
    _recept[name] = value
    setRecept(_recept)
  }
  const handleUpdateEvent = e => {
    const { name, value } = e.target
    const _recept = { ...recept }
    _recept[name] = value
    setRecept(_recept)
  }
  const handleUpdateArrayEvent = (e, i) => {
    const { name, value } = e.target
    const _recept = { ...recept }
    const arr = [..._recept[name]]
    arr[i] = value
    _recept[name] = arr
    setRecept(_recept)
  }


  const handleCreate = async () => {
    handleClose()
    await Recepts.Create(recept)
    await props.getData()
  }

  const renderPart = (item, i) => {
    return <div>
      <div className="flex">
        <TextField
          id="standard-basic" label="Mått" variant="standard"
          onChange={e => handleUpdateArrayEvent(e, i)}
          value={recept.measures[i]}
          name="measures"
          style={{ width: "100px" }}
          InputProps={{ readOnly: !props.isAdmin(), }}
        />
        <div className="spacer-5" />
        <TextField
          id="standard-basic" label="Ingrediens" variant="standard"
          onChange={e => handleUpdateArrayEvent(e, i)}
          value={recept.ingredienser[i]}
          name="ingredienser"
          InputProps={{ readOnly: !props.isAdmin(), }}
        />
      </div>
      <div className="spacer-5" />
    </div>
  }

  const ingredienser = () => recept.ingredienser.map((item, i) => renderPart(item, i))


  return (
    <div>
      <Button color="primary" variant="contained" onClick={handleClickOpen}>Lägg till +</Button>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Redigera recept</DialogTitle>
        <DialogContent className="CreateOrderDialog__form">
          <TextField
            id="standard-basic" label="Namn" variant="standard"
            onChange={handleUpdateEvent}
            value={recept.namn}
            name="namn"
          />
          <div className="spacer-5" />
          <TextField
            id="standard-basic" label="Skapare" variant="standard"
            onChange={handleUpdateEvent}
            value={recept.author}
            name="author"
          />
          <div className="spacer-5" />
          <TextField
            id="standard-basic" label="Instruktion" variant="standard"
            onChange={handleUpdateEvent}
            value={recept.instruktion}
            name="instruktion"
            multiline
            style={{ minWidth: "50vw", height: "500px" }}
          />
          <div className="spacer-10" />
          <div className="flex-col">
            {ingredienser()}
          </div>
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
          </div>
          <Button color="primary" onClick={handleCreate}>Spara</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
