import React from 'react';

import AbsenceList from '../absence/List';
import Note from './Note';


export default function Dashboard() {

    return (
        <div className="aui dashboard">
                <h2>{getTimestamp()}</h2>
                <h2></h2>
                
                <div className="content">
                    <Note />
                    <AbsenceList />
                </div>
        </div>
    )
}



const getTimestamp = () => {
    const date = new Date()
    const getZero = item => String(item).length < 2 ? `0${item}` : item
    const year = date.getFullYear() + '-' + getZero(date.getMonth() + 1) + '-' + getZero(date.getDate())
    return year
}


