import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

let defaultProps = {
    items: [],
    titles: ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'],
    activeTitle: '',
    styles: {
        list: {title: {}, content: {}},
        item: {content: {}}
    },
    classNames: {
        list: { title: 'Planner__h2', content: 'Planner__weekday__body', wrapper: 'Planner__weekday' },
        item: { content: ''}
    },
    handleUpdate: () => {},
    hideNotDone: false,
}

export default function DragAndDrop(props) {
    const { items, titles, activeTitle, styles, classNames, handleUpdate, hideNotDone } = {...defaultProps, ...props}

    const [lists, setLists] = React.useState([])

    const generateLists = () => {
        let newLists = []
        for (let i = 0; i < titles.length; i++) {
            newLists.push({ id: i, title: titles[i], items: []})
        }
        return newLists
    }
    const sortIntoLists = (_lists) => {
        let newLists = [..._lists]
        newLists.map(list => {
            list.items = items.filter(item => item.list === list.id)
            list.items = genNewIndex(list.items)
            // list.items = sortByPrio(list.items)
        })
        setLists(newLists)
    }
    
    React.useEffect(() => {
        let _lists = generateLists()
        sortIntoLists(_lists)
    }, [props.items])


    const genNewIndex = (arr) => {
        let index = 0
        let newArr = [...arr]
        newArr.forEach(item => {
            item.index = index
            index++
        })
        return newArr
    }
    const sortByPrio = (arr) => {
        let sorted = [...arr].sort((a, b) => {
            let p = a.prio - b.prio
            if (p === 0) return a.index - b.index
            return p
        }) 
        sorted = genNewIndex(sorted)
        return sorted
    }
    function onDragEnd(result) {
        const { source, destination } = result;
        
        if (!destination) return
        
        if (source.droppableId === destination.droppableId) {
            let newList = [...lists[source.droppableId].items]
            const [removed] = newList.splice(source.index, 1)
            newList.splice(destination.index, 0, removed)
            newList = genNewIndex(newList)
            // newList = sortByPrio(newList)

            setLists(prev => {
                let newLists = [...prev]
                newLists[source.droppableId].items = newList
                return newLists
            })
            handleUpdate(newList)
        } else {
            let newList = [...lists[source.droppableId].items]
            let newList2 = [...lists[destination.droppableId].items]
            const [removed] = newList.splice(source.index, 1)
            removed.list = parseInt(destination.droppableId)
            newList2.splice(destination.index, 0, removed)
            newList = genNewIndex(newList)
            newList2 = genNewIndex(newList2)
            // newList = sortByPrio(newList)
            // newList2 = sortByPrio(newList2) 

            setLists(prev => {
                let newLists = [...prev]
                newLists[source.droppableId].items = newList
                newLists[destination.droppableId].items = newList2
                return newLists
            })
            handleUpdate([...newList, ...newList2])
        }
    }

    const Item = ({ item, index }) => {
        let notDone = <Draggable key={item.id} draggableId={String(item.id)} index={index}>
            {(provided, snapshot) => (
                <div
                ref={provided.innerRef}
                style={{...styles.item.content, }}
                className={classNames.item.content}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                >
                    {/* <div>{item.smak} :{item.prio}</div> */}
                    {/* PUT CHILDREN HERE */}
                    <div style={{
                        border: snapshot.isDragging ? "2px solid rgba(0, 255, 17, 0.5)" : "",
                        borderRadius: '8px',
                        margin: activeTitle == '' ? '5px 0' : '5px',
                    }}>
                        {props.children[item.id]}
                    </div>
                </div>
            )}
        </Draggable>

        let done = <div style={styles.item.content} className={classNames.item.content}>
            {/* <div>{item.smak} - prio {item.prio}</div> */}
            {/* PUT CHILDREN HERE */}
            <div style={{ margin: activeTitle == '' ? '5px 0' : '5px',}}>
                {props.children[item.id]}
            </div>
        </div>
        
        return item.done ? done : notDone
    }   

    const List = ({list}) => {
        let notDone = list.items.filter(item => !item.done)
        let done = list.items.filter(item => item.done)

        return (
            <div className={classNames.list.wrapper}>
                <Droppable droppableId={String(list.id)}>
                {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    style={{...styles.list.content}}
                    className={classNames.list.content}
                    {...provided.droppableProps}
                    >
                        <div 
                        style={styles.list.title}
                        className={classNames.list.title}
                        >
                            {list.title}
                        </div>
                        <div 
                        className={activeTitle != '' ? "Planner__weekday__body__list--wide": ""}
                        style={{
                            backgroundColor: snapshot.isDraggingOver ? 'rgba(192, 235, 249, 0.3)' : '',
                            padding: '5px',
                            borderRadius: '8px',
                        }}
                        >
                            {notDone.map((item, index) => <Item key={item.id} item={item} index={index} /> )}
                        </div>
                        {provided.placeholder}
                    </div>
                )}
                
                </Droppable>
                { done.length > 0 && !hideNotDone && <div
                style={styles.list.content}
                className={classNames.list.content}
                >
                    <div 
                    style={styles.list.title}
                    className={classNames.list.title}
                    >
                        <div className='spacer-5' />
                        Färdiga
                    </div>
                    <div className={activeTitle != '' ? "Planner__weekday__body__list--wide": ""}>
                        {done.map((item, index) => <Item key={item.id} item={item} index={index} /> )}
                    </div>
                </div> }
            </div>
        )
    }


  return (
      <DragDropContext onDragEnd={onDragEnd}>
            {lists.map(list => ( activeTitle == list.title || activeTitle == '' ? <List key={list.id} list={list} /> : null ) )}
      </DragDropContext>
  )
  
}