import React, { useState, useEffect } from 'react'
import WithUseAuth from '../auth/WithUseAuth';
import './styles.css'

import TrackingFilter from './TrackingFilter';
import TrackingPack from './TrackingPack';

function Tracking(props) {
    const { isAdmin } = props.useAuth;

    const renderList = [<TrackingFilter />, <TrackingPack />];
    const [activeComponent, setActiveComponent] = useState(renderList[0]);

    
    const handle = {
        nav: async (index) => {
           setActiveComponent(renderList[index])
        },
    }

    useEffect(() => {
        
    }, [])

  



    return (
        <div className="flex">


            <div className="flex trackingNav">
                <div className="spacer-15" />
                <div className="spacer-10" />
                <div className="trackingNav__row Ordersedel__h2" onClick={() => handle.nav(0)}>
                    Sök efter dokument
                </div>
                <div className="trackingNav__row Ordersedel__h2" onClick={() => handle.nav(1)}>
                    Packning
                </div>
            </div>



            <div className="spacer-20" />

            <div>
                {activeComponent}
            </div>

        </div>
    )
}

export default WithUseAuth(Tracking);

