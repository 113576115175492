import React, { Component } from 'react'
import { Auth, Pralinrum } from '../../Api/routes'
import { Persons } from '../../api/routes'
import WithUseAuth from '../../pages/auth/WithUseAuth';

import Sort from './Sort'
import Checkout from './Checkout/Checkout'
import SortDialog from './Create/SortDialog'
import LoggDialog from './LoggDialog'
import SortDialogUpdate from './Update/SortDialog'

import Button from '@material-ui/core/Button';

class Sorter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sorter: [],
      persons: [],
      showCreate: false,
      disabled: true,
      activeUser: {},
      toUpdate: []
    }
  }

  componentDidMount = async () => {
    await this.refreshState()
  }

  refreshState = async () => {
    const sorter = await Pralinrum.Read()
    const persons = await Persons.Read()
    const logg = await Pralinrum.Logg.Read()

    const isadmin = this.props.useAuth.isAdmin() // Auth.level === 'admin'

    const filtered = persons.filter(p => p.pralinrum)

    await this.setState({
      Auth: Auth,
      sorter: sorter,
      persons: filtered,
      activeSort: sorter[0],
      isadmin: isadmin,
      logg: logg
    })
  }


  render() {
    const { sorter, isadmin } = this.state

    const listSorter = sorter.map((sort, index) => (
      <div key={sort._id} style={{ marginBottom: "20px"}}>
        <Sort indexSort={index} sort={sort} handleToUpdate={this.handleToUpdate} updateSort={this.handleUpdateSort} disabled={this.state.disabled} isadmin={isadmin} />
        
        <div style={{ marginTop: "-5px" }}  className="flex">
          {isadmin && <SortDialogUpdate sort={sort} index={index} updateSort={this.updateSort} />}
          {isadmin && <Button disableElevation style={{marginLeft:"5px"}} variant="contained" color="secondary" onClick={() => this.deleteSort(sort._id)}>radera</Button>}
        </div>
      </div>
    ));


    return (
      <div style={{paddingBottom: "100px"}}>
        <div className="flex-col flex-center">
          <div className="flex" style={{ alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
          
            <h3>Sorter i lager (st)</h3>
            &nbsp;&nbsp;&nbsp;
            {isadmin && <SortDialog createSort={this.createSort} />}
            {false && <LoggDialog logg={this.state.logg} />}
          </div>

          {listSorter}
        </div>

        {!this.state.showCreate && <Checkout handleCheckout={this.handleCheckout} handleCheckIn={this.handleCheckIn} persons={this.state.persons} />}
      </div>
    )
  }


  handleCheckIn = async person => {
    await this.setState({ disabled: false, activeUser: person })
  }
  handleCheckout = async () => {
    const sorter = [...this.state.sorter]
    const _toUpdate = [...this.state.toUpdate]

    // Update current state of sorter
    sorter.map(async sort => {
      await Pralinrum.Update(sort)
    })

    // Logg current state of sorter
    await Pralinrum.Logg.Create({ sorter: _toUpdate })

    await this.setState({ disabled: true, activeUser: {}, toUpdate: [] })
  }

  handleToUpdate = async update => {
    var _toUpdate = [...this.state.toUpdate]
    var _update = { user: this.state.activeUser.name, update: update, date: Date.now() }

    _toUpdate = [..._toUpdate, _update]
    await this.setState({toUpdate: _toUpdate})
    console.log(_toUpdate)
  }


  createSort = async newSort => {
    await Pralinrum.Create(newSort)
    await this.refreshState()
  }
  updateSort = async (newSort, index) => {
    const _sorter = [...this.state.sorter]
    _sorter[index] = newSort
    await this.setState({ sorter: _sorter })

    await Pralinrum.Update(newSort)
    await this.refreshState()
  }
  deleteSort = async _id => {
    if (window.confirm('Är du säker att du vill radera denna?')) {
      await Pralinrum.Delete(_id)
      await this.refreshState()
    };
  }

  handleUpdateSort = async (newSort, index) => {
    const _sorter = [...this.state.sorter]
    _sorter[index] = newSort
    await this.setState({ sorter: _sorter })
  }

  showCreate = async () => {
    await this.setState(prevState => ({ showCreate: !prevState.showCreate }))
  }
}

export default WithUseAuth(Sorter);