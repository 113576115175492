import { TextField, FormControl, InputLabel, NativeSelect } from '@material-ui/core';
import FormWrapper from './FormWrapper/FormWrapper';
import { handleDates } from '../../utils';


export const textField = (label, name, value, onChange, showError, fullWidth=false, style={}) => (
    <TextField
        type="text"
        key={name} 
        label={label}
        name={name}
        value={value}
        onChange={e => onChange(e)}
        fullWidth={fullWidth}
        style={style}
        error={showError}
    />
)
export const multiline_textField = (label, name, value, onChange, showError, fullWidth=true, style={}) => (
    <TextField
        type="text"
        key={name} 
        label={label}
        name={name}
        value={value}
        onChange={e => onChange(e)}
        fullWidth={fullWidth}
        style={style}
        error={showError}
        multiline
    />
)


export const dateField = (label, name, value, onChange, showError, fullWidth=false, style={}) => {
    let isDate = value && value.split('-').length == 3

    if (!isDate) {
        return (
            <TextField
                id="date"
                type="text"
                key={name} 
                label={label}
                name={name}
                value={value}
                onChange={e => onChange(e)}
                fullWidth={fullWidth}
                style={style}
                InputLabelProps={{ shrink: true }}
                error={showError}
            />
        )
    }

    return (
        value === "" ? 
            <TextField
                id="date"
                type="date"
                key={name} 
                label={label}
                name={name}
                value={handleDates.today.getDate()}
                onChange={e => onChange(e)}
                fullWidth={fullWidth}
                style={style}
                InputLabelProps={{ shrink: true }}
                error={showError}
            />
        :
            <TextField
                id="date"
                type="date"
                key={name} 
                label={label}
                name={name}
                value={value}
                onChange={e => onChange(e)}
                fullWidth={fullWidth}
                style={style}
                InputLabelProps={{ shrink: true }}
                error={showError}
            />
    )
}
export const nativeSelect = (label, name, value, options, onChange, fullWidth=false, style={}) => (
    <FormControl variant="standard">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <NativeSelect
            name={name}
            value={value}
            onChange={e => onChange(e)}
            style={{ width: "220px", ...style }}
            fullWidth={fullWidth}
        >
            <option value=""></option>
            {options.map(item => <option key={item} value={item}>{item}</option>)}
        </NativeSelect>
    </FormControl>
)