import React, { useState, useEffect } from 'react'
import { FixedSizeList as List } from 'react-window';
import './styles.css'
import { Recepts } from '../../api/routes';
import WithUseAuth from '../auth/WithUseAuth';
import ReceptDialog from './ReceptDialog';
import ReceptCreateDialog from './ReceptCreateDialog';

import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';

function ReceptList(props) {
  const { isAdmin } = props.useAuth;
  const [recepts, setRecepts] = useState(false);
  const [searchResults, setSearchResults] = useState(recepts)

  const getData = async () => {
    const _recepts = await Recepts.Read()
    _recepts.sort((a, b) => (a.namn > b.namn) ? 1 : ((b.namn > a.namn) ? -1 : 0))
    setRecepts(_recepts);
    setSearchResults(_recepts)
  }
  useEffect(() => {
    getData()
  }, [])



  const Row = ({ index, style }) => (
    <div style={style}>
      <ReceptDialog isAdmin={isAdmin} item={searchResults[index]} getData={getData} />
    </div>
  )

  const Example = () => (
    <List
      height={500}
      itemCount={searchResults.length}
      itemSize={80}
      width={650}
    >
      {Row}
    </List>
  );


  const handleCreate = async () => { }

  return (
    <div>
      <div className="flex-center ">
        {recepts && <SearchBox arr={recepts} setArray={setSearchResults} />}
        <div className="spacer-10" />
        {/* <Button color="primary" variant="contained" onClick={handleCreate}>Lägg till +</Button> */}
        <ReceptCreateDialog isAdmin={isAdmin} getData={getData} />
      </div>
      <div className="ReceptList__list">
        <Example />
      </div>
    </div>
  )
}

export default WithUseAuth(ReceptList);

function SearchBox({ arr, setArray }) {
  const [searchTerm, setSearchTerm] = useState("")

  const handleChange = e => {
    setSearchTerm(e.target.value)
    const results = arr.filter(item => item.namn.toLowerCase().includes(e.target.value.toLowerCase()))
    setArray(results)
  }

  return (
    <div>
      <TextField
        id="standard-basic" label="Sök Recept" variant="outlined" type="search"
        value={searchTerm}
        onChange={handleChange}
        style={{ margin: "10px 0" }}
        autoComplete="off"
      />
    </div>
  )
}