import React, { useState, useEffect } from 'react'
import './styles.css'
import { FixedSizeList as List } from 'react-window';
import { Frys, Persons } from '../../api/routes';
import FrysDialog from './FrysDialog'
import FrysDoneDialog from './FrysDoneDialog'
import FrysCreateDialog from './FrysCreateDialog'
import WithUseAuth from '../auth/WithUseAuth';

import TextField from '@mui/material/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@material-ui/core/Button';

function FrysList(props) {
  const { isAdmin } = props.useAuth;
  const [frys, setFrys] = useState(false);
  const [persons, setPersons] = useState(false)
  const [showDone, setShowDone] = useState(false)

  const [searchResults, setSearchResults] = useState(false)

  const getData = async (hard = false) => {
    const _frys = await Frys.Read()
    if (_frys.length > 0) {
      _frys.sort((a, b) => (new Date(a.fryst) > new Date(b.fryst)) ? 1 : ((new Date(b.fryst) > new Date(a.fryst)) ? -1 : 0))
      setFrys(_frys)

      if (!searchResults || hard) setSearchResults(_frys)
      else {
        const results = _frys.filter(item => searchResults.find(f => f.id === item.id))
        setSearchResults(results);
      }
    }

    const _persons = await Persons.Read()
    if (_persons.length > 0) {
      _persons.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      const filtered = _persons.filter(p => p.frysrum)
      setPersons(filtered)
    }
  }
  

  useEffect(() => {
    getData()
  }, [])


  const handleShowDone = () => {
    setShowDone(!showDone)
  }

  const filterSearch = searchResults ? searchResults.filter(item => {
    if (!item.done && !showDone) return true
    if (!item.done && showDone) return false
    if (item.done && !showDone) return false
    if (item.done && showDone) return true
  }) : false

  const Row = ({ index, style }) => {
    const item = filterSearch[index];
    const ret = <div style={style}><FrysDialog isAdmin={isAdmin} item={item} getData={getData} persons={persons} showDone={showDone} /></div>

    /* if (!item.done && !showDone) return ret
    if (!item.done && showDone) return (null)
    if (item.done && !showDone) return (null)
    if (item.done && showDone) return ret */
    
    return ret
  }

  const Example = () => (
    <List
      height={600}
      itemCount={filterSearch.length}
      itemSize={80}
      width={750}
    >
      {Row}
    </List>
  );

  async function handleX() {
    for (let i = 0; i < frys.length; i++) {
      const item = frys[i];
      const newLogTemplate = {
        id: item.id,
        created_at: item.created_at,
        name: item.name,
        done: item.done,
        doppad: item.doppad,
        fryst: item.fryst,
        frystSign: item.frystSign,
        unit: item.unit,
      }

      let original_amount = item.ammount
      item.history.map((h, i) => {
        original_amount += h.ammount
      })
      
      item.history.map((h, i) => {
        const newLog = {...newLogTemplate}
        newLog.ammount = h.ammount
        
      })
    }
    
  // {date: '2023-02-27', sign: 'André ', ammount: '1'}

  // {
  // "id": 274,
  // "created_at": "2022-10-25T14:43:39.818094+00:00",
  // "name": "test",
  // "done": false,
  // "doppad": "2022-10-25",
  // "fryst": "2022-10-25",
  // "frystSign": "Malin R",
  // "ammount": "11",
  // "unit": "st",
  // "history": []
  // }
  }

  return (
    <div className="flex-col-center">
      <div className="flex-center">
        <div className="Whiteboard__h1" onClick={handleX}>{`${showDone ? 'Tinade' : 'Frysta'} Objekt`}</div>
        <div className="spacer-10" />
        <Button onClick={handleShowDone} variant="outlined" color="secondary">{`Visa ${!showDone ? 'Tinade' : 'Frysta'}`}</Button>
      </div>

      <div className="ReceptList__list">
        <div className="flex-center">
          {persons && <FrysCreateDialog isAdmin={isAdmin} getData={getData} persons={persons} />}
          <div className="spacer-10" />
          {/* {frys && <FrysDoneDialog isAdmin={isAdmin} items={frys} getData={getData} />}
          <div className="spacer-10" /> */}
          <SearchBox arr={frys} setArray={setSearchResults} />
        </div>
        <div className="spacer-20" />

        {/* {frys && persons && searchResults.filter(item => !item.done).map((item, i) => <FrysDialog key={i} isAdmin={isAdmin} item={item} getData={getData} persons={persons} />)} */}
        {frys && persons && <Example />}
      </div>
    </div>
  )
}


function SearchBox({ arr, setArray }) {
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (arr !== false) {
      const results = arr.filter(item =>
        item.name.toLowerCase().includes(searchTerm)
      );
      setArray(results);
    }
  }, [searchTerm]);

  return (
    <div className="OrdersInactive">
      <TextField
        id="standard-basic" label="Sök Namn" variant="outlined" type="text"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value.toLowerCase())}
        style={{ margin: "10px 0" }}
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
      />
      {searchTerm !== "" && <IconButton onClick={() => setSearchTerm("")} style={{ marginLeft: "-45px" }}>
        <CloseIcon color="primary" />
      </IconButton>}
    </div>
  )
}


export default WithUseAuth(FrysList);
