import React from 'react'

import { Routes, getDate } from '../../../api/routes'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import InputLabel from '@mui/material/InputLabel'

export default function FrysCreateDialog(props) {
    const [open, setOpen] = React.useState(false)

    const generateEmptyObject = () => {
        const item = {
            name: '',
            done: false,
            dipped_date: getDate(),
            frozen_date: getDate(),
            frozen_by: '',
            original_amount: 0,
            current_amount: 0,
            unit: 'st',
            history: []
        }
        return item
    }

    const [object, setObject] = React.useState()

    const handleOpen = async () => {
        setOpen(true)

        const _object = generateEmptyObject()
        setObject(_object)
    }

    const handleClose = async () => {
        setOpen(false)
    }

    const handleUpdateEvent = e => {
        const { name, value } = e.target
        const _object = { ...object }
        _object[name] = value
        setObject(_object)
    }

    const handleCreate = async () => {
        const _object = { ...object }
        _object.current_amount = _object.original_amount

        if (_object.name === '') {
            alert('Du måste ange ett namn')
            return
        } else if (_object.original_amount === 0) {
            alert('Du måste ange ett antal')
            return
        } else if (_object.frozen_by === '') {
            alert('Du måste ange vem som fryst in')
            return
        }
        

        handleClose()
        await Routes.Create(_object, 'freezer')
        await props.getData(true)
    }


    return (
        <div>
            <Button color="primary" variant="contained" onClick={handleOpen}>Frys in +</Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Frys in</DialogTitle>
                {
                    object && 
                    <DialogContent className="FrysDialog__item">
                        <TextField
                            name="name"
                            id="standard-basic" label="Namn" variant="standard"
                            autoComplete="off"
                            onChange={handleUpdateEvent}
                            value={object.name}
                        />
                        <div className="spacer-5" />
                        <TextField
                            name="original_amount"
                            id="standard-basic" label="Antal" variant="standard" type="number" min="0"
                            onChange={handleUpdateEvent}
                            value={object.original_amount}
                            style={{
                                maxWidth: "80px"
                            }}
                        />
                        <div className="spacer-5" />
                        <TextField
                            id="standard-basic" label="Enhet" variant="standard"
                            onChange={handleUpdateEvent}
                            value={object.unit}
                            name="unit"
                            style={{
                                maxWidth: "80px"
                            }}
                            />
                        <div className="spacer-5" />

                        <TextField
                            name="dipped_date"
                            label="Tillverkad"
                            type="date"
                            variant="standard"
                            value={object.dipped_date}
                            sx={{ width: 220 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleUpdateEvent}
                        />
                        <div className="spacer-5" />
                        <TextField
                            name="frozen_date"
                            label="Fryst"
                            type="date"
                            variant="standard"
                            value={object.frozen_date}
                            sx={{ width: 220 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleUpdateEvent}
                        />
                        <div className="spacer-5" />
                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-label">Fryst Av</InputLabel>
                            <NativeSelect
                                value={object.frozen_by}
                                name="frozen_by"
                                onChange={handleUpdateEvent}
                                inputProps={{ name: 'frozen_by', id: 'uncontrolled-native', }}
                            >
                                <option value="Namn">Namn</option>
                                {[...props.persons].map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
                            </NativeSelect>
                        </FormControl>
                    </DialogContent>
                }

                <DialogActions className="CreateOrderDialog__buttons">
                    <Button onClick={handleClose}>Stäng</Button>
                    <Button color="primary" onClick={handleCreate}>Spara</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
