import React from 'react'

import { Routes, getDate } from '../../../api/routes'

import Reduce from './Reduce'
import History from './History'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import InputLabel from '@mui/material/InputLabel'
import FormWrapper from '../../../pages/components/FormWrapper/FormWrapper'

export default function View(props) {
    const [open, setOpen] = React.useState(false)
    const [object, setObject] = React.useState()

    const generateEmptyObject = () => {
        const item = {
            name: '',
            done: false,
            dipped_date: getDate(),
            frozen_date: getDate(),
            frozen_by: '',
            original_amount: 0,
            current_amount: 0,
            unit: 'st',
            history: []
        }
        return item
    }

    React.useEffect(() => {
        const emptyObject = generateEmptyObject()
        const _object = { ...emptyObject, ...props.item }

        setObject(_object)
    }, [props.item])

    const handleOpen = async () => {
        setOpen(true)
    }

    const handleClose = async () => {
        setOpen(false)
    }

    const handleDelete = async () => {
        if (window.confirm('Är du säker att du vill radera denna?')) {
            handleClose()
            await Routes.Delete(object, 'freezer')
            await props.getData()
        };
    }

    return (
        <div>
            {
                object && 
                <div onClick={handleOpen} style={{
                    backgroundColor: "#f7f6f2",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "1px solid #eee",
                    transition: "all 0.2s ease",
                    marginBottom: "5px",
                    cursor: "pointer"
                }}>
                    <div style={{
                        width: "230px",
                    }}>
                        <FormWrapper label="Namn">
                            {object.name}
                        </FormWrapper>
                    </div>

                    <div className="spacer-10" />
            
                    <div style={{
                        width: "50px",
                    }}>
                        <FormWrapper label="Antal">
                            {object.current_amount}
                        </FormWrapper>
                    </div>
                    
                    <div className="spacer-15" />
                   
                    <div style={{
                        width: "80px",
                    }}>
                        <FormWrapper label="Enhet">
                            {object.unit}
                        </FormWrapper>
                    </div>
            
                    <div className="spacer-15" />
                    
                    <FormWrapper label="Tillverkad">
                        {object.dipped_date}
                    </FormWrapper>

                    <div className="spacer-10" />
                    
                    <FormWrapper label="Fryst">
                        {object.frozen_date}
                    </FormWrapper>

                    <div className="spacer-10" />
                </div>
            }
            <Dialog open={open} onClose={handleClose}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}>
                    <DialogTitle>Infryst Objekt</DialogTitle>
                    { object && object.current_amount > 0 && <Reduce {...props} /> }
                    { object && object.history.length > 0 && <History {...props} /> }
                </div>

                {
                    object && 
                    <DialogContent className="FrysDialog__item">
                        <TextField
                            name="name"
                            id="standard-basic" label="Namn" variant="standard"
                            autoComplete="off"
                            value={object.name}
                        />
                        <div className="spacer-5" />
                        <TextField
                            name="current_amount"
                            id="standard-basic" label="Antal" variant="standard" type="number" min="0"
                            value={object.current_amount}
                            style={{
                                maxWidth: "80px"
                            }}
                        />
                        <div className="spacer-5" />
                        <TextField
                            id="standard-basic" label="Enhet" variant="standard"
                            value={object.unit}
                            name="unit"
                            style={{
                                maxWidth: "80px"
                            }}
                        />
                        <div className="spacer-5" />

                        <TextField
                            name="dipped_date"
                            label="Tillverkad"
                            type="date"
                            variant="standard"
                            value={object.dipped_date}
                            sx={{ width: 220 }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <div className="spacer-5" />
                        <TextField
                            name="frozen_date"
                            label="Fryst"
                            type="date"
                            variant="standard"
                            value={object.frozen_date}
                            sx={{ width: 220 }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <div className="spacer-5" />
                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-label">Fryst Av</InputLabel>
                            <NativeSelect
                                value={object.frozen_by}
                                name="frozen_by"
                                inputProps={{ name: 'frozen_by', id: 'uncontrolled-native', }}
                                // sx={{ width: 180 }}
                            >
                                <option value={object.frozen_by}> {object.frozen_by} </option>
                            </NativeSelect>
                        </FormControl>
                    </DialogContent>
                }

                <DialogActions className="CreateOrderDialog__buttons">
                    <div className="flex">
                        <Button onClick={handleClose}>Stäng</Button>
                        { props.isAdmin && <Button color="secondary" onClick={handleDelete}>Radera</Button> }
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}
