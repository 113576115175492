import React, {useReducer} from 'react'
import Smak from './Smak/Smak'

export default function Sort(props) {
  const sort = { ...props.sort }
  
  const listChildren = sort.children.map((child, i) => (
    <Smak key={i} index={i} child={child} updateChild={updateChild} disabled={props.disabled} {...props} />
  ));


  const style = {
    wrapper: {
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      background: "white",
      padding: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderRadius: "8px",
      minWidth: "80vw"
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    }
  }

  const header = (
    <div style={style.header}>
      <h4>{sort.name}</h4>
      
    </div>
  )

  return (
    <div style={style.wrapper} className="flex-col">
      {header}
      {listChildren}
    </div>
  )

  function updateChild(child, index) {
    const newSort = { ...sort }
    newSort.children[index] = child
    props.updateSort(newSort, props.index)
  }

  function useToggle(initialValue = false) {
    return useReducer((state) => !state, initialValue);
  }
}
