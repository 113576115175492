import React, { useEffect, useState } from 'react'
import { Frys, getDate } from '../../api/routes';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormWrapper from '../components/FormWrapper/FormWrapper';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect'
import InputLabel from '@mui/material/InputLabel';




export default function FrysTinaDialog(props) {
  const [open, setOpen] = useState(false);
  const [emptySign, setEmptySign] = useState(false)
  const [tining, setTining] = useState({
    date: getDate(), sign: "", ammount: 0
  })

  const getExtraName = () => {
    return [...props.persons]
  }

  const genFrys = () => {
    return props.item
  }
  const [fry, setFrys] = useState(genFrys())

  useEffect(() => {
    setFrys(genFrys())
  }, [props.item])

  const handleClickOpen = async () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
    props.handleClose()
  };

  const handleUpdateEvent = e => {
    const { name, value } = e.target
    const _tining = { ...tining }
    _tining[name] = value
    setTining(_tining)
  }

  const handleTining = () => {
    const _fry = { ...fry }
    _fry.ammount = _fry.ammount - tining.ammount
    _fry.history.push(tining)
    _fry.done = _fry.ammount < 1

    setFrys(_fry)
    return _fry
  }

  const handleCreate = async () => {
    if (tining.sign !== '') {
      setEmptySign(false)
      const _fry = handleTining()
      handleClose()
      await Frys.Update(_fry)
      await props.getData()
    } else {
      setEmptySign(true)
    }
    return
  }


  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>Tina objekt</Button>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Tina objekt</DialogTitle>
        <DialogContent>
          <DialogContentText>
            För att minska antalet i frysen fyller du i hur många enheter du vill tina samt datum och namn.
          </DialogContentText>

          <div className="spacer-20" />

          <div className="FrysDialog__DialogContent">
            <TextField
              type="date"
              id="standard-basic" label="Tinad" variant="standard"
              onChange={handleUpdateEvent}
              value={tining.date}
              name="date"
              sx={{ width: 220 }}
              InputLabelProps={{ shrink: true }}
            />
            <div className="spacer-15" />
            <TextField
              id="standard-basic" label="Antal" variant="standard" type="number"
              onChange={handleUpdateEvent}
              value={tining.ammount}
              name="ammount"
              style={{
                maxWidth: "80px"
              }}
            />

            <div className="spacer-15" />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Signatur</InputLabel>
              <NativeSelect
                value={tining.sign}
                name="sign"
                onChange={handleUpdateEvent}
                inputProps={{ name: 'sign', id: 'uncontrolled-native', }}
                style={emptySign ? { borderBottom: "1px solid red" } : {}}
              >
                <option value="Namn">Namn</option>
                {getExtraName().map((item, index) => <option key={index} value={item.name}>{item.name}</option>)}
              </NativeSelect>
            </FormControl>

          </div>
        </DialogContent>
        <DialogActions className="CreateOrderDialog__buttons">
          <div className="flex-center">
            <Button onClick={handleClose}>Stäng</Button>
          </div>
          <div className="flex-center">
            <Button color="primary" onClick={handleCreate}>Spara</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
